import React, { useState, useEffect } from "react";
import { Steps, Card, Spin, Form, Button, Row, Modal } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PlusOutlined } from "@ant-design/icons";
import HeadBreadCrumb from "../../../components/HeadBreadCrumb";
import emptyValues from "./emptyValues";
// import VisitInformations from "./VisitInformations";
// import Clinical from "../../components/medicalRecord/clinical";
// import BloodSample from "./BloodSample";
import {
  getAllVisitsByPatientAsync,
} from "../../../redux/visits/actions";
import {
  updateCallAsync,
  selectCall
} from "../../../redux/calls/actions";
import { parseVisitName, structureFields } from "../../../helpers/functions";
import QueryModal from "../../../components/QueryModal/index";
// import "./styles.css";
import Call from "./call";
import Questionnaire from "../../../components/medicalRecord/questionnaire";

const { Step } = Steps;

const SelectedCallComp = ({
  contentIsLoading,
  visit,
  selectedCall,
  handleGoBack,
  updateCallAsync,
  user,
  listVisitsByPatient,
  getAllVisitsByPatientAsync,
  selectCall
}) => {
  const [current, setCurrent] = useState(0);
  const [values, setValues] = useState(emptyValues);
  const [openQueryModal, setOpenQueryModal] = useState(false);
  const [selectedField, selectField] = useState();
  const [clinicalForm] = Form.useForm();
  const [visitInformationsForm] = Form.useForm();
  const [showAdd, setShowAdd] = useState(false);
  const [questionnaireForm] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const [callForm] = Form.useForm();


  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  const mapFiedFamilyToForm = {
    call: visitInformationsForm,
  };
  // debugger;
  // useEffect(() => {
  //   getAllVisitsByPatientAsync(selectedCall.patient._id);
  // }, [selectedCall.patient._id]);

  const changeProp = (fieldFamily, fieldName, prop, value) => {
    if (fieldFamily === "inclusion" || fieldFamily === "exclusion") {
      setValues({
        ...values,
        [fieldFamily]: {
          ...values[fieldFamily],
          [fieldName]: {
            ...values[fieldFamily][fieldName],
            [prop]: value,
          },
        },
      });
    } else {
      setValues({
        ...values,
        [fieldName]: {
          ...values[fieldName],
          [prop]: value,
        },
      });
    }
  };

  const handleUpdateField = (fieldFamily, fieldName, prop, value, field) => {
    console.log(
      "fieldFamily, fieldName, prop, value",
      fieldFamily,
      fieldName,
      prop,
      value,
      field,
      visit
    );
    let currentForm;
    if (visit === "j0") {
      switch (current) {
        case 0:
          currentForm = callForm;
          break;
        default:
          currentForm = "general";
          break;
      }
    }else{
      switch (current) {
        case 0:
          currentForm = callForm;
          break;
        case 1:
          currentForm= questionnaireForm;
          break;
        default:
          currentForm = "general";
          break;
      }
    }
  //debugger
    const form = mapFiedFamilyToForm[fieldFamily]
      ? mapFiedFamilyToForm[fieldFamily]
      : mapFiedFamilyToForm[currentForm];

    console.log("[debug] undefined form ? - ", {
      mapFiedFamilyToForm,
      fieldName,
      currentForm,
      form,
    });
    // debugger
    switch (prop) {
      case "frozen":
      case "sdv":
        const fieldToUpdate = {
          ...values[fieldName],
          [prop]: true,
        };
        const body = {
          field: fieldToUpdate,
          visitNumber: visit,
        };
        body.fieldFamily = fieldName;
        updateCallAsync(selectedCall._id, body);
        break;
      case "value":
        setIsModalVisible(false);
        const val = currentForm.getFieldValue(fieldName);
        //debugger
        console.log("[debug] disappearing field - ", { val, fieldName });
        let field = {};
        console.log("form", form);
        console.log("currentForm", currentForm);
        if (
           visit === "j0"        ) {
            const fieldToUpdate = {
              ...values[fieldName],
              value: {
                date: val.date
                  ? val.date
                  : null,
                time: val.time
                  ? moment(val.time)
                  : null,
              },
              readOnly: true,
            };
          
          const body = {
            field: fieldToUpdate,
            visitNumber: visit,
            fieldFamily :"call",
            call:true
          };
          updateCallAsync(selectedCall._id, body);
        } else if (fieldFamily === "biologicalExamination") {
          field = {
            ...values[currentForm][fieldName],
            value: val,
            readOnly: true,
          };
          const body = {
            field,
            visitNumber: visit,
          };
          body.fieldFamily = fieldName;
          body.fieldCategorie = currentForm;
          updateCallAsync(selectedCall._id, body);
        } else {
          let fieldToUpdate = {};
          console.log(values[fieldName]);
          if (values[fieldName] === null) {
            fieldToUpdate = {
              ...emptyValues[fieldName],
              value: val,
              readOnly: true,
            };
          } else if (values[fieldName]) {
            fieldToUpdate = {
              ...values[fieldName],
              value: val,
              readOnly: true,
            };
          } else {
            fieldToUpdate = {
              ...values[currentForm][fieldName],
              value: val,
              readOnly: true,
            };
          }
          const body = {
            field: fieldToUpdate,
            visitNumber: visit,
          };
          body.fieldFamily = fieldName;
          updateCallAsync(selectedCall._id, body);
          // if(fieldName ===  "MC" && val == "Oui"){
          //   debugger
          //   setValues({...values,[currentForm] : {
          //     ...values.antecedents,
          //     ["IDM"] : emptyValues[currentForm]["IDM"],
          //     [`${"IDM"}dateDebut`] : emptyValues[currentForm][`${"IDM"}dateDebut`],
          //     [`${"IDM"}dateFin`] : emptyValues[currentForm][`${"IDM"}dateFin`],
          //   }})
          //   console.log(values);
          // }
          //   debugger
        }
        break;
      case "cancel":
        // NOTE FIRST 2 TESTS ARE FOR TIMEPICKER && datetime picker (They accept moment DateTime as a value)
        switch (fieldName) {
          default:
            if (fieldFamily === "visitInformations") {
              if (fieldName === "transport") {
                changeProp(fieldFamily, fieldName, "readOnly", !value);
              } else {
                form.setFieldsValue({
                  [fieldName]: {
                    date: selectedCall[fieldName].value.date,
                    time: moment(selectedCall[fieldName].value.time),
                  },
                });
              }
            } else if (
              fieldFamily === "biologicalExamination" ||
              fieldFamily === "TraitementsExamination" ||
              fieldFamily === "SignesExamination" ||
              fieldFamily === "AntecedentExamination"
            ) {
              const newValsToArray = Object.keys(values[currentForm]).map(
                (key) => {
                  return values[currentForm][key];
                }
              );

              const newVals = newValsToArray.map((e) =>
                e.name === fieldName
                  ? { ...e, readOnly: true, value: "Non" }
                  : e
              );

              const newValusTwo = (array, key) => {
                return array.reduce((obj, item) => {
                  obj[item[key]] = item;
                  return obj;
                }, {});
              };
              // onUpdateValidation(fieldName, value === false ? "Non" : "Oui");
              setValues({
                ...values,
                [currentForm]: newValusTwo(newVals, "name"),
              });
            } else {
              changeProp(fieldFamily, fieldName, "readOnly", !value);
              // if (fieldFamily === "inclusion") {
              //   console.log(selectedCall[fieldFamily][fieldName]);
              //   debugger
              //   inclusionForm.setFieldsValue({
              //     [fieldName]: selectedCall[fieldFamily][fieldName],
              //   });
              // } else if (fieldFamily === "exclusion") {
              //   exclusionForm.setFieldsValue({
              //     [fieldName]: selectedCall[fieldFamily][fieldName],
              //   });
              // } else {
              //   debugger
              //   form.setFieldsValue({
              //     [fieldName]: selectedCall[fieldName].value,
              //   });
              // }
            }
        }
        // if (fieldFamily !== "bloodSample") {
        //   changeProp(fieldFamily, fieldName, "readOnly", !value);
        // }
        break;
      case "readOnly":
        if (
          fieldFamily === "biologicalExamination" ||
          fieldFamily === "TraitementsExamination" ||
          fieldFamily === "SignesExamination" ||
          fieldFamily === "AntecedentExamination"
        ) {
          const newValsToArray = Object.keys(values[currentForm]).map((key) => {
            return values[currentForm][key];
          });
          const newVals = newValsToArray.map((e) =>
            e.name === fieldName ? { ...e, readOnly: false } : e
          );

          const newValusTwo = (array, key) => {
            return array.reduce((obj, item) => {
              obj[item[key]] = item;
              return obj;
            }, {});
          };
          setValues({ ...values, [currentForm]: newValusTwo(newVals, "name") });
        } else {
          changeProp(fieldFamily, fieldName, prop, value);
        }
        break;
      case "query":
        let fieldToSelect = selectedCall[fieldName];
        if (
          fieldFamily === "biologicalExamination" ||
          fieldFamily === "TraitementsExamination" ||
          fieldFamily === "SignesExamination" ||
          fieldFamily === "AntecedentExamination"
        ) {
          fieldToSelect = selectedCall[currentForm][fieldName];
        } else if (fieldFamily === "inclusion" || fieldFamily === "exclusion") {
          fieldToSelect = selectedCall[fieldFamily][fieldName];
        }
        selectField({ ...fieldToSelect, fieldFamily });
        setOpenQueryModal(true);
        break;
      default:
        if (
          visit === "j0" && fieldFamily === "visitDate"
        ) {
          return
        } else {
          changeProp(fieldFamily, fieldName, prop, value);
          updateCallAsync(selectedCall._id, {
            field: {
              ...values[fieldName],
              [prop]: value,
            },
            // bloodSampleKey: values.bloodSamples[0]
            //   ? values.bloodSamples[0].key
            //   : "",
            fieldFamily,
            visitNumber: visit,
          });
        }
    }
  };

  const showStep = () => {
    // debugger
    if (visit === "j0") {
      switch (current) {
        case 0:
          // debugger
          return (
            <Call
              values={values}
              form={callForm}
              visit={visit}
              onUpdate={handleUpdateField}
              data={selectedCall.patient}
            />
          );
        default:
          return (
            <div>
              {" "}
              Une erreur inconnue est survenue !{" "}Merci de rafraichir la page. Si le problème persiste veuillez contacter le support.
            </div>
          );
      }
    } else if (visit === "j1") {
      switch (current) {
        case 0:
          return (
            <div style={{ maxWidth: 900 }}>
              <Call
                values={values}
                form={callForm}
                visit={visit}
                onUpdate={handleUpdateField}
                data={selectedCall.patient}
              />
            </div>
          );
        default:
          return <div />;
      }
    } else {
      switch (current) {
        case 0:
          return (
            <div style={{ maxWidth: 900 }}>
              <Call
                values={values}
                form={callForm}
                visit={visit}
                onUpdate={handleUpdateField}
                data={selectedCall.patient}
              />
            </div>
          );
        case 1:
          return (
            <Questionnaire
              values={values}
              questionnaireForm={questionnaireForm}
              visit={"j3"}
              onUpdate={handleUpdateField}
            />
          );
        default:
          return <div />;
      }
    }
  };


  useEffect(() => {
    if (selectedCall) {
      if (visit === "j0" || visit === "j7") {
        setValues({
          ...selectedCall,
        });
        const keys = Object.keys(selectedCall);
        // eslint-disable-next-line array-callback-return
        keys.map((key) => {
          const field = selectedCall[key];
          switch (key) {
            case "visitDate":
            case "callDate":
              callForm.setFieldsValue({
                [key]: {
                  date: selectedCall[key].value
                    ? selectedCall[key].value.date
                    : null,
                  time: selectedCall[key].value
                    ? moment(selectedCall[key].value.time)
                    : null,
                },
              });
              break;
            default:
              break;
          }
        });
      } else if (visit === "j2") {
        setValues({ ...selectedCall });
        const keys = Object.keys(selectedCall);
        // eslint-disable-next-line array-callback-return
        keys.map((key) => {
          switch (key) {
            case "dateVisite":
              clinicalForm.setFieldsValue({
                [key]: {
                  date: selectedCall[key].value
                    ? selectedCall[key].value.date
                    : null,
                  time: selectedCall[key].value
                    ? moment(selectedCall[key].value.time)
                    : null,
                },
              });
              break;
            default:
              break;
          }
        });
      } else {
        setValues({ ...selectedCall });
        const keys = Object.keys(selectedCall);
        // eslint-disable-next-line array-callback-return
        keys.map((key) => {
          switch (key) {
            case "dateInvisigateur":
            case "dateVisite":
              clinicalForm.setFieldsValue({
                [key]: {
                  date: selectedCall[key].value
                    ? selectedCall[key].value.date
                    : null,
                  time: selectedCall[key].value
                    ? moment(selectedCall[key].value.time)
                    : null,
                },
              });
              break;
            case "questionnaire":
              callForm.setFieldsValue({
                [key]: selectedCall[key].value,
              });
              break;
            case "visitDate":
            case "callDate":
              callForm.setFieldsValue({
                [key]: {
                  date: selectedCall[key].value
                    ? selectedCall[key].value.date
                    : null,
                  time: selectedCall[key].value
                    ? moment(selectedCall[key].value.time)
                    : null,
                },
              });
              break;
            default:
              break;
          }
        });
      }
      // setBloodSamples(selectedCall.bloodSamples);
    }
  }, [selectedCall]);



  console.log("selectedCall", selectedCall);

  if (!selectedCall) return <div />;
  return (
    <div className="section-content">
      <HeadBreadCrumb
        firstSectionName="Appel"
        secondSectionName={parseVisitName(selectedCall.patient.customId)}
        thirdSectionName={
          user && user.type === 1
            ? `${selectedCall.patient.lastName.value} ${selectedCall.patient.firstName.value}`
            : selectedCall.patient.customId
        }
        handleGoBack={handleGoBack}
      />
      <Spin spinning={contentIsLoading}>
        <Row>
          <Card className="formWidth">
            <Steps
              current={current}
              onChange={setCurrent}
              size="small"
              className="m-bottom-medium"
            >
              {visit === "j0" ? (
                <>
                  <Step title="Rendez vous visit V0" />
                </>
              ) : null}
              {visit === "j3" && (
                <>
                  <Step title="Appel téléphonique par l'infermier" />
                  <Step title="Questionnaire" />
                </>
              )}
            </Steps>
            <div className="m-top-medium" style={{ padding: 24 }}>
              {showStep()}
            </div>
          </Card>
        </Row>
      </Spin>
      <QueryModal
        call={selectedCall}
        open={openQueryModal}
        field={selectedField}
        handleClose={() => setOpenQueryModal(false)}
      />
    </div>
  );
};

SelectedCallComp.propTypes = {
  contentIsLoading: PropTypes.bool.isRequired,
  visit: PropTypes.string.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  selectedCall: PropTypes.object.isRequired,
  updateCallAsync: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getAllVisitsByPatientAsync: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  contentIsLoading: state.loading.contentIsLoading,
  selectedCall: state.calls.selectedCall,
  user: state.auth.user,
  listVisitsByPatient: state.visits.visitsPatient,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCallAsync,
      getAllVisitsByPatientAsync,
      selectCall
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(SelectedCallComp);
