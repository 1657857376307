
import { message } from 'antd';
import * as VisitsAPI from '../../../api/visit';
import { sessionExpired } from '../../auth/actions';
import { setContentIsLoading, setDoneAsync } from '../../loading/actions';

export const SET_VISITS_LIST = 'SET_VISITS_LIST';
export const ADD_VISIT = 'ADD_VISIT';
export const SELECT_VISIT = 'SELECT_VISIT';
export const UPDATE_VISIT = 'UPDATE_VISIT';
export const PATIENT_VISIT = 'PATIENT_VISIT';

export const setVisitsList = value => ({
    type: SET_VISITS_LIST,
    payload: value,
});

export const selectVisit = value => ({
    type: SELECT_VISIT,
    payload: value,
});

export const addVisit = value => ({
    type: ADD_VISIT,
    payload: value,
});

export const updateVisit = value => ({
    type: UPDATE_VISIT,
    payload: value,
});

export const visitsPatient = value => ({
    type: PATIENT_VISIT,    
    payload: value,
});

export const getAllVisitsAsync = visit => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await VisitsAPI.getAll(visit);
        dispatch(setVisitsList([]));
        dispatch(setVisitsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const getVisitsByPatientAsync = patient => async (dispatch) => {
    try {
        const res = await VisitsAPI.getByPatient(patient);
        dispatch(setVisitsList([]));
        dispatch(setVisitsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
};

export const createVisitAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await VisitsAPI.create(body);
        dispatch(addVisit(res.data));
        message.success('Visite ajoutée avec succès');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const selectVisitAsync = id => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await VisitsAPI.getById(id);
        dispatch(selectVisit(res.data));
        dispatch(setDoneAsync(true))
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const addBloodSampleAsync = (id, body) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await VisitsAPI.pushBloodSample(id, body);
        dispatch(selectVisit(res.data));
        dispatch(updateVisit(res.data));
        message.success('Prélèvement ajouté avec succès');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const updateVisitAsync = (id, body) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await VisitsAPI.update(id, body);
        dispatch(selectVisit(res.data));
        dispatch(updateVisit(res.data));
        message.success('Modification effectuée');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const getAllVisitsByPatientAsync = id => async (dispatch) => {
    console.log('here', id);
    dispatch(setContentIsLoading(true));
    try {
        const res = await VisitsAPI.getAllByPatientId(id);
        console.log('rest', res);
        dispatch(visitsPatient(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};
