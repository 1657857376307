import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import DynamicField from "../DynamicField";
import { connect } from "react-redux";

const Clinical = ({
  form,
  values,
  onUpdate,
  showHeightWeight,
  imc,
  visit,
  transport,
  isEdit,
  contentIsLoading,
  isDone,
  HIS,
  isAdd,
}) => {
  useEffect(() => {
    if (isDone && isEdit) {
      form.setFields(
        Object.entries(values).map(([key, field]) => {
          return {
            name: key,
            value: field.value,
          };
        })
      );
    }
  }, [values, isDone]);
  return (
    <Form style={{ marginTop: 30, minWidth: "100%" }} form={form}>
      {visit === "j1" && (
        <div>
          <strong>Information de l'infirmier</strong>
          <DynamicField
            key={"initial"}
            field={values["initial"]}
            onUpdate={onUpdate}
            fieldFamily="clinical"
          />
          <strong>Date visite V1</strong>
          <DynamicField
            field={values.dateVisiteV1}
            onUpdate={onUpdate}
            fieldFamily="clinical"
          />
          <div>
            <div className="ant-col ant-form-item-label m-bottom-small">
              <strong> 1. Pression Artérielle </strong>
            </div>
            <Input.Group className="flex-column" name="arterialPression">
              {/* {values.systoliqueRight ? (
                <DynamicField
                  field={values.systoliqueRight}
                  onUpdate={onUpdate}
                  fieldFamily="clinical"
                />
              ) : null} */}
              <DynamicField
                field={values.systolique}
                onUpdate={onUpdate}
                fieldFamily="clinical"
              />
              {/* {values.diastoliqueRight ? (
                <DynamicField
                  field={values.diastoliqueRight}
                  onUpdate={onUpdate}
                  fieldFamily="clinical"
                />
              ) : null} */}
              <DynamicField
                field={values.diastolique}
                onUpdate={onUpdate}
                fieldFamily="clinical"
              />
            </Input.Group>
          </div>
          <div>
            <div className="ant-col ant-form-item-label m-bottom-small">
              <strong> 2. Fréquence cardiaque </strong>
            </div>
            <DynamicField
              field={values.beats}
              onUpdate={onUpdate}
              fieldFamily="clinical"
            />
          </div>
          {["weight", "height"].map((k, index) => {
            return (
              <>
                <strong> {index === 0 ? "3.Poids" : "4.Taille"} </strong>
                <div>
                  <DynamicField
                    key={k}
                    field={values[k]}
                    onUpdate={onUpdate}
                    fieldFamily="clinical"
                  />
                </div>
              </>
            );
          })}
          <div>
            <div className="ant-col ant-form-item-label m-bottom-small">
              <strong> Echocardiographie</strong>
            </div>
            <DynamicField
              field={values.EchocardiographieDebut}
              onUpdate={onUpdate}
              fieldFamily="clinical"
            />
            <DynamicField
              field={values.EchocardiographieFin}
              onUpdate={onUpdate}
              fieldFamily="clinical"
            />
          </div>
          <div>
            <div className="ant-col ant-form-item-label m-bottom-small">
              <strong> Echantillon sanguin : Nexus-DX </strong>
            </div>
            <DynamicField
              field={values.nexus}
              onUpdate={onUpdate}
              fieldFamily="clinical"
            />
          </div>
          {/* <div>
            <div className="ant-col ant-form-item-label m-bottom-small">
              <strong> Questionnaire patient </strong>
            </div>
            {["remis", "finished", "recover"].map((k, index) => {
              return (
                <>
                  <div>
                    <DynamicField
                      key={k}
                      field={values[k]}
                      onUpdate={onUpdate}
                      fieldFamily="clinical"
                    />
                  </div>
                </>
              );
            })}
          </div> */}
          {/* <div>
            <strong>
              Date de la prochaine visite V2 au centre investigateur{" "}
            </strong>
            <DynamicField
              key={"dateInvisigateur"}
              field={values["dateInvisigateur"]}
              onUpdate={onUpdate}
              fieldFamily="clinical"
            />
          </div> */}
        </div>
      )}
      {visit === "j2" && (
        <div>
          <strong>Information du médecin</strong>
          <DynamicField
            key={"initial"}
            field={values["initial"]}
            onUpdate={onUpdate}
            fieldFamily="clinical"
          />
          <DynamicField
            field={values.dateVisite}
            onUpdate={onUpdate}
            fieldFamily="clinical"
          />
          <strong>Date visite V2</strong>
          <DynamicField
            field={values.dateVisite}
            onUpdate={onUpdate}
            fieldFamily="clinical"
          />
          <strong>Mode de transport aller-retour à l'hôpital :</strong>
          <DynamicField
            field={values.transport}
            onUpdate={onUpdate}
            fieldFamily="clinical"
            radio={true}
          />
          {transport === "Autre" && (
            <DynamicField
              field={values.transportAutre}
              onUpdate={onUpdate}
              fieldFamily="clinical"
            />
          )}
          <div>
            <div className="ant-col ant-form-item-label m-bottom-small">
              <strong> 1. Pression Artérielle </strong>
            </div>
            <Input.Group className="flex-column" name="arterialPression">
              {/* {values.systoliqueRight ? (
                <DynamicField
                  field={values.systoliqueRight}
                  onUpdate={onUpdate}
                  fieldFamily="clinical"
                />
              ) : null} */}
              <DynamicField
                field={values.systolique}
                onUpdate={onUpdate}
                fieldFamily="clinical"
              />
              {/* {values.diastoliqueRight ? (
                <DynamicField
                  field={values.diastoliqueRight}
                  onUpdate={onUpdate}
                  fieldFamily="clinical"
                />
              ) : null} */}
              <DynamicField
                field={values.diastolique}
                onUpdate={onUpdate}
                fieldFamily="clinical"
              />
            </Input.Group>
          </div>
          <div>
            <div className="ant-col ant-form-item-label m-bottom-small">
              <strong> 2. Fréquence cardiaque </strong>
            </div>
            <DynamicField
              field={values.beats}
              onUpdate={onUpdate}
              fieldFamily="clinical"
            />
          </div>
          {["weight", "height"].map((k, index) => {
            return (
              <>
                <strong> {index === 0 ? "3. Poids" : "4. Taille"} </strong>
                <div>
                  <DynamicField
                    key={k}
                    field={values[k]}
                    onUpdate={onUpdate}
                    fieldFamily="clinical"
                  />
                </div>
              </>
            );
          })}
          <div>
            <div className="ant-col ant-form-item-label m-bottom-small">
              <strong> Echocardiographie </strong>
            </div>
            <DynamicField
              field={values.EchocardiographieDebut}
              onUpdate={onUpdate}
              fieldFamily="clinical"
            />
            <DynamicField
              field={values.EchocardiographieFin}
              onUpdate={onUpdate}
              fieldFamily="clinical"
            />
          </div>
          {/* <div>
            <div className="ant-col ant-form-item-label m-bottom-small">
              <strong> Questionnaire patient </strong>
            </div>
            {["finishedDoctor", "recoverDoctor"].map((k, index) => {
              return (
                <>
                  <div>
                    <DynamicField
                      key={k}
                      field={values[k]}
                      onUpdate={onUpdate}
                      fieldFamily="clinical"
                    />
                  </div>
                </>
              );
            })}
          </div> */}
          <div>
            <div className="ant-col ant-form-item-label m-bottom-small">
              <strong> Type d’appareillage d’échocardiographie : </strong>
            </div>
            <>
              <div>
                <DynamicField
                  key={"TAEcho"}
                  field={values.TAEcho}
                  onUpdate={onUpdate}
                  fieldFamily="clinical"
                />
              </div>
            </>
            <strong>Insuffisance cardiaque</strong>
            {isAdd ? (
              <DynamicField
                field={values.HasInsuffisanceCardiaque}
                onUpdate={onUpdate}
                fieldFamily="clinical"
                radio={true}
              />
            ) : (
              <DynamicField
                field={values.HasInsuffisanceCardiaque}
                onUpdate={onUpdate}
                fieldFamily="clinical"
              />
            )}
            {(HIS === "Oui" ||
              values.HasInsuffisanceCardiaque.value === "Oui") && (
              <DynamicField
                field={values.InsuffisanceCardiaque}
                onUpdate={onUpdate}
                fieldFamily="clinical"
              />
            )}
          </div>
          <div>
            <div className="ant-col ant-form-item-label m-bottom-small">
              <strong> Heure de fin de la visite V2 : </strong>
            </div>
            <>
              <div>
                <DynamicField
                  key={"finHeureVdeux"}
                  field={values.finHeureVdeux}
                  onUpdate={onUpdate}
                  fieldFamily="clinical"
                />
              </div>
            </>
          </div>
        </div>
      )}
    </Form>
  );
};

Clinical.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  showHeightWeight: PropTypes.bool,
};

Clinical.defaultProps = {
  onUpdate: () => {},
  showHeightWeight: true,
};

const stateToProps = (state) => ({
  contentIsLoading: state.loading.contentIsLoading,
  isDone: state.loading.isDone,
});
export default connect(stateToProps)(Clinical);
