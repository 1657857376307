import React from "react";
import PropTypes from "prop-types";
import { TimePicker } from "antd";
import moment from "moment";

const MyPicker = (props) => {
  const [touched, setTouched] = React.useState(false);
  
  const onBlur = (elem) => {
    const value = moment(elem.target.value, props.format);
    // const value = moment(elem.target.value).local();
    if (value && value.isValid() && props.onChange) {
      props.onChange(value, elem.target.value);
    }
  };

  console.log("[debug] picker", { props });
  console.log("props",props)
  return (
    <TimePicker
      {...props}
      value={
        // props.id ===  "sampleTime"  ? props.defaultValue :
        (props.disabled && props.defaultValue) || !touched ? props.defaultValue : props.value
      }
      onFocus={() => setTouched(true)}
      onBlur={onBlur}
    />
  );
};

MyPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string.isRequired,
};

export default MyPicker;
