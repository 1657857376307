import React from "react";

const Details = ({
    patientName,
    // renderTime,
    zoom,
    // wwwl 
}) => {
  return (
    <>
      <div
        className="overlay"
        style={{ position: "absolute", top: "0px", left: "0px" }}
      >
        {patientName}
      </div>
      {/* <div
        className="overlay"
        style={{ position: "absolute", top: "0px", right: "0px" }}
      >
        {renderTime}
      </div> */}
      <div
        className="overlay"
        style={{ position: "absolute", bottom: "0px", right: "0px" }}
      >
        {zoom}
      </div>
      {/* <div
        className="overlay"
        style={{ position: "absolute", bottom: "0px", left: "0px" }}
      >
        {wwwl}
      </div> */}
    </>
  );
};

export default Details;
