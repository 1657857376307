import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Typography, Divider } from "antd";
import DynamicField from "../DynamicField";
import patientAdditionEmptyVals from "../../sections/Patients/add/emptyValues";

const { Title } = Typography;

const title = [
  "1. Rales pulmonaires",
  "2. Essoufflement au repos",
  "3. Essoufflement à l’effort",
  "4. Toux nocturne",
  "5. Réduction de la tolérance à l’exercice",
  "6. Angine",
  "7. JPV (jugular venous pulse) augmenté",
  "8. Troisième bruit cardiaque",
  "9. Respiration sifflante",
  "10. œdème périphérique",
  "11. dyspnée paroxystique nocturne",
  "12. orthopnée",
  "13. Autres explorations cardiaques : Radiographie pulmonaire (CXR), échocardiographie d'effort",
];

const SignesExams = (props) => {
  return (
    <Form
      style={{ marginTop: 30 }}
      form={props.form}
      className="biological-examination-form"
    >
      <Card title="Signes et Symptômes" className="add-antecedent-card">
        <div>
          {[
            "RP",
            "ER",
            "EE",
            "TN",
            "RTE",
            "ANG",
            "JPV",
            "TBC",
            "RS",
            "OP",
            "DPN",
            "ORT",
            "CXR",
          ].map((k, index) => (
            <>
              <Title style={{ marginBottom: 14 }} level={5}>
                {title[index]}
              </Title>
              <DynamicField
                key={k}
                field={props.values.signes[k]}
                onUpdate={props.onUpdate}
                fieldFamily="SignesExamination"
                radio={true}
              />
              {props[k] === "Oui" ? (
                <>
                  <DynamicField
                    key={props.values.signes[k]}
                    field={props.values.signes[`${k}dateDebut`]}
                    onUpdate={props.onUpdate}
                    fieldFamily="SignesExamination"
                  />
                  <DynamicField
                    key={props.values.signes[k]}
                    field={props.values.signes[`${k}dateFin`]}
                    onUpdate={props.onUpdate}
                    fieldFamily="SignesExamination"
                  />
                </>
              ) : null}
              <Divider />
            </>
          ))}
        </div>
      </Card>
    </Form>
  );
};
SignesExams.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

SignesExams.defaultProps = {
  onUpdate: () => {},
};

export default SignesExams;
