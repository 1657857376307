import axios from 'axios';
import { API_QUERY, API_REQ_HEADER } from './config';

const create = async (data) => {
    const res = await axios.post(`${API_QUERY}/`, data, API_REQ_HEADER);
    return res.data;
};

const getAll = async (query) => {
    const res = await axios.get(`${API_QUERY}/${query}`, API_REQ_HEADER);
    return res.data;
};

const getByPatient = async (patientId) => {
    const res = await axios.get(`${API_QUERY}/patient/${patientId}`, API_REQ_HEADER);
    return res.data;
};

const update = async (id, data) => {
    const res = await axios.put(`${API_QUERY}/${id}`, data, API_REQ_HEADER);
    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_QUERY}/${id}`, API_REQ_HEADER);
    return res.data;
};


const pushMessage = async (id, data) => {
    const res = await axios.post(`${API_QUERY}/messages/${id}`, data, API_REQ_HEADER);
    return res.data;
};

export {
    create, getAll, getByPatient, update, getById, pushMessage,
};
