import React from 'react';
import {
    Router,
    Switch,
    Route,
} from 'react-router-dom';
import Visit from './sections/Visit';
import history from './history';
import Login from './sections/Login';
import Patients from './sections/Patients';
import OpenQueries from './sections/Queries/open';
import AnsweredQueries from './sections/Queries/answered';
import ClosedQueries from './sections/Queries/closed';
import AdverseEvent from './sections/AdverseEvents/index';
import CsvExtraction from './components/CsvExtraction';
import Dicoms from './sections/DicomViewer';
import AppelV0 from './sections/Visit/nurseAdd';

export default function Routes() {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/patients">
                    <Patients />
                </Route>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/j0">
                    <Visit visit="j0" />
                </Route>

                <Route path="/j1">
                    <Visit visit="j1" />
                </Route>

                <Route path="/j2">
                    <Visit visit="j2" />
                </Route>

                <Route path="/j7">
                    <Visit visit="j7" />
                </Route>

                <Route path="/j8">
                    <Visit visit="j8" />
                </Route>

                <Route path="/j9">
                    <Visit visit="j9" />
                </Route>

                <Route exact path="/adverse">
                    <AdverseEvent />
                </Route>
                <Route exact path="/dicom">
                    <Dicoms />
                </Route>
                <Route exact path="/open">
                    <OpenQueries />
                </Route>
                <Route exact path="/answered">
                    <AnsweredQueries />
                </Route>
                <Route exact path="/closed">
                    <ClosedQueries />
                </Route>
                <Route path="/extract-patients">
                    <CsvExtraction extract="patients" />
                </Route>
                <Route path="/extract-visits">
                    <CsvExtraction extract="visits" />
                </Route>
                <Route exact path="/ListV3">
                    <AppelV0 visit="j3" />
                </Route>
                <Route exact path="/ListV0">
                    <AppelV0 visit="j0" />
                </Route>
            </Switch>
        </Router>
    );
}
