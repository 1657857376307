import React, { useContext } from "react";
import { Form, Input, InputNumber, Radio, Descriptions, Checkbox } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import FORM_VALIDATORS from "../../helpers/formRules";
import FieldActions from "../FieldActions";
import TimePicker from "../TimePicker";
import { EditContext } from "../../sections/Patients/";

import "moment/locale/fr-ca";
import locale from "antd/lib/locale/fr_FR";

const format = "HH:mm";

const mapRules = (rules) => {
  const res = [];
  rules.map((rule) =>
    FORM_VALIDATORS[rule] ? res.push(FORM_VALIDATORS[rule]) : null
  );
  return res;
};

const DynamicField = ({ field, onUpdate, fieldFamily, nameProps, radio }) => {
  // const label = field.strongLabel ? (
  //     <strong>{field.label}</strong>
  // ) : (
  //     field.label
  // );
  //console.log("[debug] ", {field})

  const { unity } = field;
  const isEdit = useContext(EditContext);

  const onBlurInput = (e) => {
    if (radio) onUpdate(field.name, e.target.value);
    if (onUpdate && isEdit) onUpdate(field.name, e.target.value);
  };

  // console.log("*///////*/**/******")
  // console.log(field, fieldFamily)
  // console.log("*///////*/**/******")
  const renderField = () => {
    switch (field.type) {
      case "checkbox":
          
        return (
          <Form.Item
            style={{ ...field.style }}
            name={field.name}
            rules={mapRules(field.rules)}
            //initialValue={field.value}
            // className="m-auto"
          >
            <Checkbox.Group
              options={field.radioFields}
              defaultValue={field.value}
              value={field.value}
              onChange={field.onChange}
              style={field.inputStyle}
              readOnly={field.readOnly}
              disabled={field.frozen}
            />
          </Form.Item>
        );
      case "input":
        console.log("input field", field);
        console.log("first,input field", typeof field.value);
        return (
          <Form.Item
            style={{ ...field.style }}
            name={field.name}
            rules={mapRules(field.rules)}
            //initialValue={field.value}
            // className="m-auto"
          >
            <Input
              placeholder={field.placeholder}
              value={field.value}
              onChange={field.onChange}
              style={field.inputStyle}
              readOnly={field.readOnly}
              disabled={field.frozen}
              onBlur={onBlurInput}
              defaultValue={field.value}
            />
          </Form.Item>
        );
      case "inputNumber":
        console.log("=> field", field);
        return (
          <Form.Item
            style={{ ...field.style }}
            name={field.name}
            rules={mapRules(field.rules)}
            // className="m-auto"
          >
            <InputNumber
              placeholder={field.placeholder}
              value={field.value}
              defaultValue={field.value}
              onChange={field.onChange}
              style={field.inputNumberStyle}
              min={field.minNumber}
              max={field.maxNumber}
              readOnly={field.readOnly}
              disabled={field.frozen}
              onBlur={onBlurInput}
            />
          </Form.Item>
        );
      case "inputTextArea":
        return (
          <Form.Item
            style={{ ...field.style }}
            name={field.name}
            rules={mapRules(field.rules)}
          >
            <Input.TextArea
              placeholder={field.placeholder}
              value={field.value}
              onChange={field.onChange}
              style={field.inputTextAreaStyle}
              readOnly={field.readOnly}
              disabled={field.frozen}
            />
          </Form.Item>
        );
      case "radio":
        console.log("fioed", field);
        return (
          <Form.Item
            style={{ ...field.style }}
            name={field.name}
            rules={mapRules(field.rules)}
            // className="m-auto"
          >
            <Radio.Group
              style={{ ...field.radioStyle }}
              disabled={field.readOnly || field.frozen}
              onChange={field.onChange}
              value={field.value}
              defaultValue={field.value}
            >
              {field.radioFields.map((rf, key) => (
                <Radio
                  value={rf.value}
                  key={key.toString()}
                  onClick={onBlurInput}
                >
                  {rf.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      case "datetime":
        return (
          <Form.Item style={{ ...field.style }}>
            <Input.Group style={{ display: "flex" }}>
              <Form.Item
                className="m-right-small"
                noStyle
                name={[field.name, "date"]}
                rules={[FORM_VALIDATORS.validDate, ...mapRules(field.rules)]}
              >
                <Input
                  locale={locale}
                  placeholder="Date (jj/mm/aaa)"
                  value={field.value ? field.value.date : null}
                  onChange={field.onDateChange}
                  style={field.dateStyle}
                  readOnly={field.readOnly}
                  disabled={field.frozen}
                  onBlur={onBlurInput}
                />
              </Form.Item>
              <Form.Item
                className="m-left-small"
                noStyle
                name={[field.name, "time"]}
                rules={mapRules(field.rules)}
              >
                <TimePicker
                  placeholder="Heure (HH:MM)"
                  style={field.timeStyle}
                  // readOnly={field.readOnly}
                  disabled={field.frozen || field.readOnly}
                  format={format}
                  defaultValue={
                    field.value || field.value !== undefined
                      ? moment(field.value.time).local()
                      : undefined
                  }
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        );
      case "timepicker":
        console.log("[debug] ", {
          defVal: field.value,
          mom: moment(field.value).local(),
          ["field.name"]: field.name,
        });
        return (
          <Form.Item
            style={{ ...field.style }}
            className="m-left-small"
            noStyle
            name={field.name}
            rules={mapRules(field.rules)}
          >
            <TimePicker
              // className="m-auto"
              locale={locale}
              placeholder={field.placeholder}
              style={field.timeStyle}
              disabled={field.frozen || field.readOnly}
              format={format}
              //defaultValue={field.value ? moment(field.value).utc() : undefined}
              defaultValue={
                field.value ? moment(field.value).local() : undefined
              }
            />
          </Form.Item>
        );
      default:
        return <div />;
    }
  };

  return (
    <div className="flex" style={{ alignItems: "baseline" }}>
      <Descriptions>
        <Descriptions.Item>{field.label}</Descriptions.Item>
        <Descriptions.Item>
          {renderField()}
          <strong style={{ marginLeft: "10px" }}>{unity}</strong>
        </Descriptions.Item>
        {fieldFamily !== "adverse" ? (
          <Descriptions.Item>
            <FieldActions
              onUpdate={onUpdate}
              field={field}
              fieldFamily={fieldFamily}
              nameProps={nameProps}
            />
          </Descriptions.Item>
        ) : null}
      </Descriptions>
    </div>
  );
};

DynamicField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    rules: PropTypes.array,
    strongLabel: PropTypes.bool,
    label: PropTypes.string,
    type: PropTypes.oneOf(["radio", "input", "inputNumber", "datetime"]),
    radioStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    inputNumberStyle: PropTypes.object,
    style: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    radioFields: PropTypes.array,
    minNumber: PropTypes.number,
    maxNumber: PropTypes.number,
    readOnly: PropTypes.bool,
    frozen: PropTypes.bool,
    onDateChange: PropTypes.func,
    dateStyle: PropTypes.object,
    onTimeChange: PropTypes.func,
    timeStyle: PropTypes.object,
    inputTextAreaStyle: PropTypes.object,
    unity: PropTypes.string,
  }),
  onUpdate: PropTypes.func.isRequired,
  fieldFamily: PropTypes.string.isRequired,
};

DynamicField.defaultProps = {
  field: {},
};

export default DynamicField;
