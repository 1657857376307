import {
    SET_DOC_LIST
} from '../actions';

const initialState = {
    docsList: [],
    selectedPatient: null,
};

const documents = (state = initialState, action) => {
    switch (action.type) {
        case SET_DOC_LIST:
            return { ...state, docsList: action.payload };
        default:
            return state;
    }
};

export default documents;
