import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeadBreadCrumb from '../../components/HeadBreadCrumb';
import emptyValues from './emptyValues';
import { setContentIsLoading } from '../../redux/loading/actions';
import {
    updateAdverseAsync,
    selectAdverse,
} from '../../redux/adverse/actions/index';
import AdverseForm from './add';

const SelectedEvent = ({
    selectedEvent,
    setContentIsLoading,
    updateAdverseAsync,
    selectAdverse,
}) => {
    const [values, setValues] = useState(emptyValues);
    const [adverseForm] = Form.useForm();

    useEffect(
        () => () => {
            selectAdverse(null);
        },
        [],
    );

    const changeProp = (fieldFamily, fieldName, prop, value) => {
        setValues({
            ...values,
            [fieldName]: {
                ...values[fieldName],
                [prop]: value,
            },
        });
    };

    const handleUpdateField = (fieldFamily, fieldName, prop, value) => {
        changeProp(fieldFamily, fieldName, prop, value);
        updateAdverseAsync(selectedEvent._id, {
            field: {
                ...values[fieldName],
                [prop]: value,
            },
            fieldFamily,
        });
    };

    useEffect(() => {
        if (selectedEvent) {
            setContentIsLoading(true);
            setValues(selectedEvent);
            const keys = Object.keys(selectedEvent);
            keys.forEach((key) => {
                const field = selectedEvent[key];
                adverseForm.setFieldsValue({ [key]: field.value });
            });

            setContentIsLoading(false);
        }
    }, [selectedEvent]);

    if (!selectedEvent) return <div />;

    return (
        <div className="section-content">
            <HeadBreadCrumb
                firstSectionName="Evénements indésirables"
                secondSectionName={selectedEvent.patient.customId}
                handleGoBack={() => selectAdverse(null)}
            />
            <AdverseForm
                onUpdate={handleUpdateField}
                form={adverseForm}
                values={values}
                adverseEvent={selectedEvent}
            />
        </div>
    );
};

SelectedEvent.propTypes = {
    selectedEvent: PropTypes.object.isRequired,
    setContentIsLoading: PropTypes.func.isRequired,
    updateAdverseAsync: PropTypes.func.isRequired,
    selectAdverse: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    contentIsLoading: state.loading.contentIsLoading,
    selectedEvent: state.adverse.selectedEvent,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateAdverseAsync,
        setContentIsLoading,
        selectAdverse,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(SelectedEvent);
