import cornerstone from "cornerstone-core";
import React, { useRef, useState } from "react";
import dicomParser from "dicom-parser";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import "./style.css";
import cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import cornerstoneMath from "cornerstone-math";
import Buttons from "./buttons";
import Details from "./details";

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

cornerstoneTools.init();

cornerstoneTools.init({
  mouseEnabled: true,
  touchEnabled: true,
  globalToolSyncEnabled: false,
  showSVGCursors: false,
});

const DicomViewer = ({ imageUrl }) => {
  const [coordsMouse, setCoordsMouse] = useState("");
  const [patientName, setPatientName] = useState("");
  const [zoom, setZoom] = useState("");
  // const [renderTime, setRenderTime] = useState();
  const [wwwl, setWwwl] = useState();
  const [reset, setReset] = useState(false);
  const [probe, setProbe] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState("");

  const element = useRef("dicomImage");

  React.useEffect(() => {
    element.current.addEventListener(
      "cornerstoneimagerendered",
      onImageRendered
    );
    cornerstone.enable(element.current);
    // load and display the image
    updateTheImage();

    // cleanup this component
    return () => {
      element.current.removeEventListener(
        "cornerstoneimagerendered",
        onImageRendered
      );
    };
  }, [reset]);

  const updateTheImage = () => {
    const imageId = `wadouri:${imageUrl}`;
    return cornerstone.loadAndCacheImage(imageId).then(function(image) {
      const viewport = cornerstone.getDefaultViewportForImage(
        element.current,
        image
      );
      cornerstone.displayImage(element.current, image, viewport);
    });
  };

  // setup handlers before we display the image
  const onImageRendered = (e) => {
    const eventData = e.detail;
    // set the canvas context to the image coordinate system
    cornerstone.setToPixelCoordinateSystem(
      eventData.enabledElement,
      eventData.canvasContext
    );
    // NOTE: The coordinate system of the canvas is in image pixel space.  Drawing
    // to location 0,0 will be the top left of the image and rows,columns is the bottom
    // right.
    const context = eventData.canvasContext;
    // setRenderTime(`Render Time: ${eventData.renderTimeInMs || 0} ms`);
    setWwwl(
      `WW/WC: ${Math.round(eventData.viewport.voi.windowWidth)}/${Math.round(
        eventData.viewport.voi.windowCenter
      )}`
    );
    setZoom(`Zoom: ${eventData.viewport.scale.toFixed(2)}`);
  };

  //   // add handlers for mouse events once the image is loaded.
  const mouseMoveHandlerr = (e, lastX, lastY, mouseButton = 1) => {
    e.stopPropagation();
    e.preventDefault();
    if (!probe) {
      function mouseMoveHandler(e) {
        const deltaX = e.pageX - lastX;
        const deltaY = e.pageY - lastY;
        lastX = e.pageX;
        lastY = e.pageY;
        if (mouseButton === 0) {
          let viewport = cornerstone.getViewport(element.current);
          viewport.voi.windowWidth += deltaX / viewport.scale;
          viewport.voi.windowCenter += deltaY / viewport.scale;
          cornerstone.setViewport(element.current, viewport);
        } else if (mouseButton === 1) {
          let viewport = cornerstone.getViewport(element.current);
          viewport.translation.x += deltaX / viewport.scale;
          viewport.translation.y += deltaY / viewport.scale;
          cornerstone.setViewport(element.current, viewport);
        } else if (mouseButton === 2) {
          let viewport = cornerstone.getViewport(element.current);
          viewport.scale += deltaY / 100;
          cornerstone.setViewport(element.current, viewport);
        }
      }

      function mouseUpHandler() {
        document.removeEventListener("mouseup", mouseUpHandler);
        document.removeEventListener("mousemove", mouseMoveHandler);
      }
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    }
  };
  return (
    <div className="App">
      <div className="container">
        <h1>Lecteur DICOM:</h1>
        <Buttons
          element={element}
          selectedBtn={selectedBtn}
          setProbe={setProbe}
          setSelectedBtn={setSelectedBtn}
          probe={probe}
          setReset={setReset}
        />
        <div
          className="dicomImageWrapper"
          contextMenu={() => false}
          onMouseDown={() => false}
        >
          <div
            ref={element}
            className="dicomViewer"
            cornerstoneimagerendered={(e) => onImageRendered(e)}
            onMouseMove={(e) => {
              try {
                const pixelCoords = cornerstone.pageToPixel(
                  element.current,
                  e.pageX,
                  e.pageY
                );
                setCoordsMouse(`pageX=
              ${e.pageX}
              pageY= 
              ${e.pageY} 
              pixelX=
              ${pixelCoords.x} 
              pixelY=
              ${pixelCoords.y}`);
              } catch (err) {
                console.error(err);
              }
            }}
            onMouseDown={(e) => {
              let lastX = e.pageX;
              let lastY = e.pageY;
              const mouseButton = e.button;
              mouseMoveHandlerr(e, lastX, lastY, mouseButton);
            }}
          ></div>

          <Details
            patientName={patientName}
            // renderTime={renderTime}
            zoom={zoom}
            // wwwl={renderTime}
          />
        </div>
        <div>
          {/* <span id="coords">{coordsMouse}</span> */}
          <span id="coord">
            *Maintenez le clic gauche de la souris afin d'altérer la luminosité
            de l'image
            <br />
            *Maintenez le clic droit de la souris afin de zoomer
          </span>
        </div>
      </div>
    </div>
  );
};
export default DicomViewer;
