import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Form, Checkbox, Button, Radio, InputNumber, Input,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import FORM_RULES from '../../helpers/formRules';

const DisplayFilter = ({
    field, isArray, addFilter,
}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    });

    return (
        <Form onFinish={addFilter} className="flex" form={form}>
            {isArray && (
                <Form.Item name="value" rules={[FORM_RULES.required]}>
                    <Radio.Group>
                        <Radio value="Vide">Vide</Radio>
                        <Radio value="Non Vide">Non Vide</Radio>
                    </Radio.Group>
                </Form.Item>
            )}
            {field && field.type === 'inputNumber' && (
            <>
                <Form.Item name="operation" rules={[FORM_RULES.required]}>
                    <Radio.Group>
                        <Radio value="$gt"> &gt; </Radio>
                        <Radio value="$gte"> &gt;= </Radio>
                        <Radio value="="> = </Radio>
                        <Radio value="$lt"> &lt; </Radio>
                        <Radio value="$lte"> &lt;= </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="value" rules={[FORM_RULES.required]}>
                    <InputNumber />
                </Form.Item>

            </>
            )}

            {field && field.type === 'radio' && (
            <>
                <Form.Item name="value" rules={[FORM_RULES.required]}>
                    <Checkbox.Group options={field.radioFields} />
                </Form.Item>

            </>
            )}

            {field && field.type === 'input' && field.rules.includes('validDate') && (
            <>
                <Form.Item name="operation" rules={[FORM_RULES.required]}>
                    <Radio.Group>
                        <Radio value="$gt"> &gt; </Radio>
                        <Radio value="$gte"> &gt;= </Radio>
                        <Radio value="="> = </Radio>
                        <Radio value="$lt"> &lt; </Radio>
                        <Radio value="$lte"> &lt;= </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="value" rules={[FORM_RULES.required, FORM_RULES.validDate]}>
                    <Input placeholder="Format: jj/mm/aaaa" />
                </Form.Item>

            </>
            )}

            {field && field.type === 'datetime' && (
            <>
                <Form.Item name="operation" rules={[FORM_RULES.required]}>
                    <Radio.Group>
                        <Radio value="$gt"> &gt; </Radio>
                        <Radio value="$gte"> &gt;= </Radio>
                        <Radio value="="> = </Radio>
                        <Radio value="$lt"> &lt; </Radio>
                        <Radio value="$lte"> &lt;= </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="value" rules={[FORM_RULES.required, FORM_RULES.validDate]}>
                    <Input placeholder="Format: jj/mm/aaaa" />
                </Form.Item>

            </>
            )}

            <Form.Item className="m-left-medium">
                <Button htmlType="submit" icon={<PlusOutlined />} type="primary">
                    Ajouter
                </Button>
            </Form.Item>

            <Form />
        </Form>
    );
};

DisplayFilter.propTypes = {
    field: PropTypes.object,
    isArray: PropTypes.bool.isRequired,
    addFilter: PropTypes.func.isRequired,

};

DisplayFilter.defaultProps = {
    field: null,
};

export default DisplayFilter;
