import React from 'react';
import { Layout, Spin } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkAuthAsync } from '../redux/auth/actions';
import SideBar from '../components/Sidebar';
import history from '../history';
import Routes from '../routes';
import AppHeader from '../components/AppHeader';
import './styles.css';
import QueryModal from '../components/QueryModal';
import { selectQuery } from '../redux/query/actions';
import AuditTrial from '../components/AuditTrial';
import notificationsListner from '../api/notification';

const { Footer, Content } = Layout;
const resolveCurrentUrl = () => history.location.pathname.split('/')[1] || '/patients';
const isLoginRoute = () => resolveCurrentUrl() === 'login';

class App extends React.Component {
  state = {
      selectedItem: resolveCurrentUrl(),
      isListeningToNotifs: false,
  };

  componentDidMount() {
      const { checkAuthAsync, user } = this.props;
      checkAuthAsync();
      if (!user) {
          history.replace('/login');
      }

      history.listen(() => {
          if (!isLoginRoute()) {
              this.setState({
                  selectedItem: `/${resolveCurrentUrl()}`,
              });
          }
      });
  }

  componentDidUpdate(props) {
      const { user } = this.props;

      if (props.user !== user) {
          this.authCheck();
      }
  }

  authCheck = async () => {
      const { user } = this.props;
      const current = resolveCurrentUrl();
      const { isListeningToNotifs, unsubscriber } = this.state;
      if (user && !isListeningToNotifs) {
          const unsubscriber = await notificationsListner(user.type === 1 ? user._id : 'investigator');
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ isListeningToNotifs: true, unsubscriber });
      }
      if (user && current === 'login') {
          history.replace('/patients');
      }
      if (!user && unsubscriber) {
          unsubscriber();
          this.setState({ isListeningToNotifs: false });
      }
      if (!user && current !== 'login') {
          history.replace('/login');
      }
  };

  handleClickItem = (route) => {
      history.push(route);
      this.forceUpdate();
  };

  render() {
      const { selectedItem } = this.state;
      const {
          appIsLoading, user, selectedQuery, selectQuery,
      } = this.props;

      if (appIsLoading) {
          return (
              <div style={{ width: '100vw', height: '100vh' }}>
                  <Spin
                      spinning
                      size="large"
                      style={{ margin: '20% auto', display: 'block' }}
                  />
              </div>
          );
      }

      return (
          <Layout>
              {user ? (
                  <>
                      <SideBar
                          selectedItem={selectedItem}
                          onClick={this.handleClickItem}
                      />

                      <Layout>
                          <AppHeader />
                          <Content className="app-content">
                              <Routes />
                              <AuditTrial />
                              <QueryModal
                                  open={!!selectedQuery}
                                  handleClose={() => selectQuery(null)}
                              />
                          </Content>
                          <Footer style={{ textAlign: 'center' }}>
                              All rights reserved to Katomi ©2021
                          </Footer>
                      </Layout>
                  </>
              ) : (
                  <>
                      <Content className="app-content">
                          <img
                              src="/assets/banner-four-shape9.png"
                              style={{ position: 'absolute', right: 15, top: '30%' }}
                              alt="wtvr"
                          />
                          <Routes />
                          <img
                              src="/assets/banner-four-shape7.png"
                              style={{ position: 'absolute', left: '10%', top: '15%' }}
                              alt="wtvr2"
                          />
                          <img
                              src="/assets/banner-four-shape4.png"
                              style={{ position: 'absolute', bottom: '5%', left: '29%' }}
                              alt="wtvr3"
                          />
                      </Content>
                      <Footer style={{ textAlign: 'center' }}>
                          All rights reserved to Katomi ©2021
                      </Footer>
                  </>
              )}
          </Layout>
      );
  }
}

App.propTypes = {
    user: PropTypes.object,
    checkAuthAsync: PropTypes.func.isRequired,
    appIsLoading: PropTypes.bool.isRequired,
    selectedQuery: PropTypes.object,
    selectQuery: PropTypes.func.isRequired,
};

App.defaultProps = {
    user: null,
    selectedQuery: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    appIsLoading: state.loading.appIsLoading,
    selectedQuery: state.queries.selectedQuery,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        checkAuthAsync,
        selectQuery,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(App);
