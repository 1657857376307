/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { Steps, Card, Spin, Form, Button, Typography, Popconfirm } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import moment from "moment";
import {
  setPatientsList,
  setPendingListAsync,
} from "../../../redux/patients/actions";
import HeadBreadCrumb from "../../../components/HeadBreadCrumb";
import PatientsList from "./listsPatients";
import emptyValues from "./emptyValues";
// import VisitInformations from "../VisitInformations";
//import FluidsNumber from "./FluidsNumber";
import {
  parseVisitName,
  structureFields,
  structureFieldsNested,
} from "../../../helpers/functions";
import Call from "./call";
// import DrugForm from "./drugForm";
// import BloodSample from "../BloodSample";
import { createCallAsync } from "../../../redux/calls/actions";
import { selectCall } from "../../../redux/calls/actions";
// import "./stylesIndex.css";
// import Inclusion from "../../../components/medicalRecord/inclusion";
// import Examinations from "../../../components/medicalRecord/examinations";
// import AntecedentsExams from "../../../components/medicalRecord/antecedents";
// import SignesExams from "../../../components/medicalRecord/signes";
// import TraitementExams from "../../../components/medicalRecord/traitements";
import { getVisitsByPatientAsync } from "../../../redux/visits/actions/index";
import Questionnaire from "../../../components/medicalRecord/questionnaire";

const { Step } = Steps;
const { Title } = Typography;

const AddCall = ({
  contentIsLoading,
  setPendingListAsync,
  visit,
  patientsList,
  createCallAsync,
  setPatientsList,
  closeAdd,
  getVisitsByPatientAsync,
  visitsList,
}) => {
  const [current, setCurrent] = useState(0);
  const [showNextButton, setShowNextButton] = useState(false);
  const [patient, selectPatient] = useState(null);
  const [values, setValues] = useState(emptyValues);
  const [callForm] = Form.useForm();
  const [drugForm] = Form.useForm();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [questionnaireForm] = Form.useForm();

  const [formComplete, setFormComplete] = useState(false);

  const [dateVisitV0, setDateVisitV0] = useState();
  const [dateVisitV3, setDateVisitV3] = useState();
  const onUpdateValidation = (name, value) => {
    switch (name) {
      case "visitDate":
        setDateVisitV0(value);
        break;
      case "callDate":
        setDateVisitV3(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setPendingListAsync(visit, closeAdd);
    return () => {
      setPatientsList([]);
    };
  }, []);

  useEffect(
    () => () => {
      setPatientsList([]);
      closeAdd();
    },
    [visit]
  );

  useEffect(() => {
    if (patient) {
      getVisitsByPatientAsync(patient._id);
    }
  }, [patient]);
  console.log("patient0", patient);
  useEffect(() => {
    if (formSubmitting) {
      createCallAsync({
        ...values,
        type: visit,
        patient: patient._id,
        customer: patient.customId,
      });
      setFormSubmitting(false);
      setValues(null);
      setValues(emptyValues);
      closeAdd();
    }
    if (current === 0 && patient) {
      selectPatient(null);
    }
    if (visit === "j0" || visit === "j7") {
      switch (current) {
        case 0:
          selectPatient(null);
          setShowNextButton(false);
          break;
        default:
          if (!showNextButton) {
            setShowNextButton(true);
          }
          break;
      }
    } else {
      switch (current) {
        case 0:
          selectPatient(null);
          setShowNextButton(false);
          break;
        case 4:
          setShowNextButton(false);
          break;
        default:
          if (!showNextButton) {
            setShowNextButton(true);
          }
          break;
      }
    }
  }, [current, formSubmitting]);

  const handleNext = async (data) => {
    try {
      let vals;
      if (visit === "j0") {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            // visitInformationsForm.setFieldsValue({
            //   admissionDate: {
            //     date: data.decision.nextVisitDate.value,
            //     time: moment(),
            //   },
            // });
            break;
          case 1:
            console.log(values);
            const vals = await callForm.validateFields();
            // debugger
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setFormSubmitting(true);
            break;
          default:
            break;
        }
      } else if (visit === "j1") {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            break;
          case 1:
            const vals = await callForm.validateFields();
            // debugger
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setFormSubmitting(true);
            break;
          default:
            break;
        }
      } else {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            break;
          case 1:
            vals = await callForm.validateFields();
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            console.log(vals);
            console.log(values);
            setCurrent(current + 1);
            //debugger
            break;
          case 2:
            vals = await questionnaireForm.validateFields();
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            console.log(vals);
            console.log(values);

            setFormSubmitting(true);
            break;
          default:
            break;
        }
      }
    } catch (err) {
      // Nvm
    }
  };
  const showButton = () => {
    if (visit === "j0" && current !== 0) {
      if (current < 1) return "Suivant";
      return "Terminer";
    }
    if (visit === "j3" && current !== 0) {
      if (current < 2) return "Suivant";
      return "Terminer";
    }
  };

  const showStep = () => {
    if (visit === "j0") {
      switch (current) {
        case 0:
          return (
            <Card bordered={false}>
              <PatientsList
                data={patientsList}
                title="Liste des participants qui n'ont pas eu encore cette visite"
                onSelect={handleNext}
              />
            </Card>
          );
        case 1:
          return (
            <div style={{ maxWidth: 900 }}>
              <Call
                values={values}
                form={callForm}
                visit={visit}
                data={patient}
                visitsList={visitsList}
                onUpdate={onUpdateValidation}
                dateVisitV0={dateVisitV0}
              />
            </div>
          );
        default:
          return <div />;
      }
    } else {
      switch (current) {
        case 0:
          return (
            <Card bordered={false}>
              <PatientsList
                data={patientsList}
                title="Liste des participants qui n'ont pas eu encore cette visite"
                onSelect={handleNext}
              />
            </Card>
          );
        case 1:
          return (
            <div style={{ maxWidth: 900 }}>
              <Call
                values={values}
                form={callForm}
                visit={visit}
                data={patient}
                onUpdate={onUpdateValidation}
                dateVisitV3={dateVisitV3}
              />
            </div>
          );
        case 2:
          return (
            <div style={{ maxWidth: 900 }}>
              <Questionnaire
                values={values}
                questionnaireForm={questionnaireForm}
                visit={visit}
              />
            </div>
          );
        default:
          return <div />;
      }
    }
  };

  return (
    <div className="section-content">
      <HeadBreadCrumb
        firstSectionName="Appels"
        secondSectionName={parseVisitName(visit)}
        thirdSectionName={
          patient !== null
            ? visit === "j0"
              ? `Ajout d'un appel téléphonique /  ${patient.customId}`
              : visit === "j3"
              ? `${patient.customId}`
              : null
            : null
        }
        handleGoBack={closeAdd}
      />
      <Spin spinning={contentIsLoading}>
        <Card>
          <Steps
            current={current}
            onChange={setCurrent}
            size="small"
            className="m-bottom-medium stepsHeader"
          >
            <Step title="Identification" />

            {/* <Step disabled={current < 2} title="Nombre de fluides" /> */}

            {/* {(visit !== "j0" && visit !== "j7") ||
              visit === "j1" ||
              (visit === "j8" && (
                <Step disabled={current < 2} title="Prélèvement" />
              ))} */}

            {visit === "j0" && (
              <>
                <Step disabled={current < 2} title="Fixer rendez-vous" />
              </>
            )}
            {visit === "j3" && (
              <>
                <Step
                  disabled={current < 2}
                  title="Appel téléphonique par l'infermier"
                />
                <Step disabled={current < 3} title="Questionnaire" />
              </>
            )}
          </Steps>

          <div className="m-top-medium" style={{ padding: 24 }}>
            {showStep()}
          </div>
          <Button
            onClick={handleNext}
            type="primary"
            className="m-top-medium m-bottom-medium center-button"
          >
            {showButton()}
          </Button>
        </Card>
      </Spin>
    </div>
  );
};

AddCall.propTypes = {
  contentIsLoading: PropTypes.bool.isRequired,
  setPendingListAsync: PropTypes.func.isRequired,
  visit: PropTypes.string.isRequired,
  patientsList: PropTypes.array.isRequired,
  createCallAsync: PropTypes.func.isRequired,
  setPatientsList: PropTypes.func.isRequired,
  closeAdd: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  contentIsLoading: state.loading.contentIsLoading,
  patientsList: state.patients.patientsList,
  visitsList: state.visits.visitsList,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPendingListAsync,
      createCallAsync,
      setPatientsList,
      getVisitsByPatientAsync,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(AddCall);
