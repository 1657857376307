export default {
  initial: {
    name: "initial",
    rules: ["required"],
    type: "input",
    label: "Initiales de l'infirmier",
    strongLabel: true,
  },
  callDate: {
    name: "callDate",
    label: "date d'appel",
    type: "datetime",
    rules: ["required"],
    strongLabel: true,
  },
  visitDate: {
    name: "visitDate",
    label: "Date visite",
    type: "datetime",
    rules: ["required"],
    strongLabel: true,
  },
  questionnaire: {
    name: "questionnaire",
    label: "Demandé au patient ?",
    strongLabel: true,
    //style: { maxWidth: "80%", margin: "auto" },
    rules: ["required"],
    type: "radio",
    radioFields: [
      { label: "Oui", value: "Oui" },
      { label: "Non", value: "Non" },
    ],
  },
  plannedVisitV1: {
    name: "plannedVisitV1",
    label: "Date prévue de la visite V1 à domicile",
    type: "datetime",
    rules: ["required"],
    strongLabel: true,
  },
  v31: {
    name: "v31",
    label: "Lors de la visite de l’infirmière à la maison, comment fut la pénibilité de cet examen ?",
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "pas pénible", value: "pas pénible" },
      { label: "peu pénible", value: "peu pénible" },
      { label: "assez pénible", value: "assez pénible" },
      { label: "très pénible", value: "très pénible" },
    ],
  },
  // v31: {
  //   name: "v31",
  //   label: `Lors de la visite de l’infirmière à la maison, comment furent les explications données par
  //   l’infirmière avant le début de l’examen ?`,
  //   strongLabel: true,
  //   style: { maxWidth: "80%", margin: "auto" },
  //   rules: [""],
  //   type: "radio",
  //   radioFields: [
  //     { label: "très compréhensible", value: "très compréhensible" },
  //     { label: "assez compréhensible", value: "assez compréhensible" },
  //     { label: "peu pénible", value: "peu pénible" },
  //     { label: "incompréhensible", value: "incompréhensible" },
  //   ],
  // },
  v32: {
    name: "v32",
    label: `Lors de la visite de l’infirmière à la maison, comment furent les explications données par
    l’infirmière avant le début de l’examen ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "très compréhensible", value: "très compréhensible" },
      { label: "assez compréhensible", value: "assez compréhensible" },
      { label: "peu pénible", value: "peu pénible" },
      { label: "incompréhensible", value: "incompréhensible" },
    ],
  },
  v33: {
    name: "v33",
    label: `Lors de la visite de l’infirmière à la maison, avez-vous eu mal pendant l’examen ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "pas mal", value: "pas mal" },
      { label: "peu mal", value: "peu mal" },
      { label: "assez mal", value: "assez mal" },
      { label: "très mal", value: "très mal" },
    ],
  },
  v34: {
    name: "v34",
    label: `Lors de la visite de l’infirmière à la maison, comment furent les explications données par
    l’infirmière à la fin de l’examen ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "très compréhensible", value: "très compréhensible" },
      { label: "assez compréhensible", value: "assez compréhensible" },
      { label: "peu pénible", value: "peu pénible" },
      { label: "incompréhensible", value: "incompréhensible" },
    ],
  },
  v35: {
    name: "v35",
    label: `Faites-vous confiance à cet examen fait à domicile ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "complétement", value: "complétement" },
      { label: "assez", value: "assez" },
      { label: "peu", value: "peu" },
      { label: "pas du tout", value: "pas du tout" },
    ],
  },
  v36: {
    name: "v36",
    label: `Lors de la visite de l’infirmière à la maison, considérez-vous que l’infirmière est à l’aise
    avec l’échocardiographe ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "complétement", value: "complétement" },
      { label: "assez", value: "assez" },
      { label: "peu", value: "peu" },
      { label: "pas du tout", value: "pas du tout" },
    ],
  },
  v37: {
    name: "v37",
    label: `Lors de la visite de l’infirmière à la maison, considérez-vous que l’infirmière est à l’aise
    avec l’échocardiographe ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "complétement", value: "complétement" },
      { label: "plutôt oui", value: "plutôt oui" },
      { label: "plutôt non", value: "plutôt non" },
      { label: "pas du tout", value: "pas du tout" },
    ],
  },
  v38: {
    name: "v38",
    label: "Lors de l’examen chez le/la cardiologue, comment fut la pénibilité de cet examen ?",
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "pas pénible", value: "pas pénible" },
      { label: "peu pénible", value: "peu pénible" },
      { label: "assez pénible", value: "assez pénible" },
      { label: "très pénible", value: "très pénible" },
    ],
  },
  v39: {
    name: "v39",
    label: `Lors de la visite de l’infirmière à la maison, comment furent les explications données par
    l’infirmière à la fin de l’examen ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "très compréhensible", value: "très compréhensible" },
      { label: "assez compréhensible", value: "assez compréhensible" },
      { label: "peu pénible", value: "peu pénible" },
      { label: "incompréhensible", value: "incompréhensible" },
    ],
  },
  v40: {
    name: "v40",
    label: `Lors de l’examen chez le/la cardiologue, avez-vous eu mal pendant l’examen ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "pas mal", value: "pas mal" },
      { label: "peu mal", value: "peu mal" },
      { label: "très mal", value: "très mal" },
      { label: "assez mal", value: "assez mal" },
    ],
  },
  v41: {
    name: "v41",
    label: `Lors de la visite de l’infirmière à la maison, comment furent les explications données par
    l’infirmière à la fin de l’examen ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "très compréhensible", value: "très compréhensible" },
      { label: "assez compréhensible", value: "assez compréhensible" },
      { label: "peu pénible", value: "peu pénible" },
      { label: "incompréhensible", value: "incompréhensible" },
    ],
  },
  v42: {
    name: "v42",
    label: `Faites-vous confiance à cet examen fait par le/la cardiologue ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "complétement", value: "complétement" },
      { label: "assez", value: "assez" },
      { label: "peu", value: "peu" },
      { label: "pas du tout", value: "pas du tout" },
    ],
  },
  v43: {
    name: "v43",
    label: `Faites-vous confiance à cet examen fait par le/la cardiologue ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "complétement", value: "complétement" },
      { label: "assez", value: "assez" },
      { label: "peu", value: "peu" },
      { label: "pas du tout", value: "pas du tout" },
    ],
  },
  v44: {
    name: "v44",
    label: `Si vous avez besoin de refaire cet examen et qu’on vous donne le choix, choisirez-vous
    de le refaire à la maison ou chez le cardiologue ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "maison", value: "maison" },
      { label: "cabinet/ hôpital", value: "cabinet/ hôpital" },
      { label: "les 2", value: "les 2" },
      { label: "aucun", value: "aucun" },
    ],
  },
  v45: {
    name: "v45",
    label: "Est-ce que la pénibilité du trajet entre chez vous et le cabinet/hôpital était :",
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "pas pénible", value: "pas pénible" },
      { label: "peu pénible", value: "peu pénible" },
      { label: "assez pénible", value: "assez pénible" },
      { label: "très pénible", value: "très pénible" },
    ],
  },
  v46: {
    name: "v46",
    label: "Est-ce que l’attente dans la salle d’attente du cardiologue était :",
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "pas pénible", value: "pas pénible" },
      { label: "peu pénible", value: "peu pénible" },
      { label: "assez pénible", value: "assez pénible" },
      { label: "très pénible", value: "très pénible" },
    ],
  },
  v47: {
    name: "v47",
    label: `Pour vos proches (posez la question à un proche si possible sinon au patient ; noter sur le
      CRF qui répond), si vous avez besoin de refaire cet examen dans le futur, choisirez-vous de le
      faire à la maison ou en cabinet/hôpital ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "maison", value: "maison" },
      { label: "cabinet/ hôpital", value: "cabinet/ hôpital" },
      { label: "les 2", value: "les 2" },
      { label: "aucun", value: "aucun" },
    ],
  },
  v47H: {
    name: "v47H",
    label: `Réponse donnée par`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "Patient", value: "Patient" },
      { label: "Proche", value: "Proche" },
    ],
  },
  v48: {
    name: "v48",
    label: `Pour vos proches (posez la question à un proche si possible sinon au patient ; noter sur le
      CRF qui répond), si vous avez besoin de refaire cet examen dans le futur, sans tenir compte
      de l’endroit, faites-vous plus confiance à un examen fait par un infirmier ou par un
      cardiologue ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "infirmier", value: "infirmier" },
      { label: "cardiologue", value: "cardiologue" },
      { label: "les 2", value: "les 2" },
      { label: "aucun des 2", value: "aucun des 2" },
    ],
  },
  v49: {
    name: "v49",
    rules: [""],
    type: "inputNumber",
    label: `Si les résultats de l'écho à domicile et à l'hôpital donnent des résultats similaires, sur une
    échelle de 0 (pas du tout) à 10 (oui avec enthousiasme), quelle est la probabilité que vous
    recommandiez l'échocardiographie à domicile à un ami ou à un membre de votre famille ?`,
    strongLabel: true,
  },
};
