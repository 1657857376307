/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Select, Checkbox, Typography, Card, Button, message,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectAudit } from '../../redux/patients/actions';
import FilterTable from './FiltersTable';
import DisplayFilter from './DisplayFilter';
import exportCsv from '../../api/csv';
import emptyValuesVisits from '../../sections/Visit/add/emptyValues';
import emptyValuesPatients from '../../sections/Patients/add/emptyValues';


const { Option, OptGroup } = Select;
const { Title } = Typography;

const mapToCheckBoxesLabelValue = (itemToMap, itemGroup) => Object.keys(itemToMap).flatMap(objectName => ({
    value: `${itemGroup}-${objectName}`,
    label: itemToMap[objectName],
}));

const prepareCheckboxItems = (filtrableObjects, arrays, unfiltrableObjects) => {
    const res = Object.keys(filtrableObjects || {}).flatMap(objectName => ({
        label: filtrableObjects[objectName].label,
        value: `filtrableObjects-${objectName}`,
    }));
    res.push(
        ...mapToCheckBoxesLabelValue(arrays || {}, 'arrays'),
        ...mapToCheckBoxesLabelValue(unfiltrableObjects || {}, 'unfiltrableObjects'),
    );
    return res;
};

const CsvExtraction = ({ extract }) => {
    const prepareFieldsForExtratction = () => {
        if (extract === 'visits') {
            const filtrableObjects = {
                generalVisit: {
                    label: 'Informations Générales',
                },
                clinicalVisit: {
                    label: 'Examen Clinique',
                },
                administration: {
                    label: 'Administration',
                },
            };
            Object.keys(emptyValuesVisits).forEach((fieldKey) => {
                switch (fieldKey) {
                    case 'consultationDate':
                    case 'admissionDate':
                    case 'lastMeal':
                    case 'lastDrink':
                        filtrableObjects.generalVisit = {
                            ...filtrableObjects.generalVisit,
                            [fieldKey]: emptyValuesVisits[fieldKey],
                        };
                        break;
                    case 'systolique':
                    case 'diastolique':
                    case 'bodyTemperature':
                        filtrableObjects.clinicalVisit = {
                            ...filtrableObjects.clinicalVisit,
                            [fieldKey]: emptyValuesVisits[fieldKey],
                        };
                        break;
                    case 'administratedDrug':
                        filtrableObjects.administration = {
                            ...filtrableObjects.administration,
                            [fieldKey]: emptyValuesVisits[fieldKey],
                        };
                        break;
                    default:
                        break;
                }
            });

            return {
                filtrableObjects,
                arrays: {
                    bloodSamples: 'Prélèvements',
                },
                unfiltrableObjects: {
                },
            };
        } if (extract === 'patients') {
            const filtrableObjects = {
                general: {
                    label: 'Informations Générales',
                },
                clinical: {
                    label: 'Examen Clinique',
                },
                biologicalExamination: {
                    label: 'Examen Biologique',
                },
                radiologicalExamination: {
                    label: 'Examen Radiologique',
                },
            };
            Object.keys(emptyValuesPatients).forEach((fieldKey) => {
                switch (fieldKey) {
                    case 'gender':
                    case 'birthDate':
                    case 'consentSign':
                    case 'consentDate':
                        filtrableObjects.general = {
                            ...filtrableObjects.general,
                            [fieldKey]: emptyValuesPatients[fieldKey],
                        };
                        break;
                    case 'weight':
                    case 'height':
                    case 'systolique':
                    case 'diastolique':
                    case 'bodyTemperature':
                        filtrableObjects.clinical = {
                            ...filtrableObjects.clinical,
                            [fieldKey]: emptyValuesPatients[fieldKey],
                        };
                        break;
                    case 'biologicalExamination':
                    case 'radiologicalExamination':
                        filtrableObjects[fieldKey] = {
                            ...filtrableObjects[fieldKey],
                            ...emptyValuesPatients[fieldKey],
                        };
                        break;
                    default:
                        break;
                }
            });
            return {
                filtrableObjects,
                arrays: {
                    medicalAntecedents: 'Antécédents',
                    treatments: 'Traitements',
                },
                unfiltrableObjects: {
                    inclusion: "Critéres d'inclusion",
                    exclusion: 'Critères de non inclusion',
                },
            };
        }
        return 'No props passed';
    };
    const { filtrableObjects, arrays, unfiltrableObjects } = prepareFieldsForExtratction();
    const [selectFiltrableOptions, setSelectFiltrableOptions] = useState(
        filtrableObjects,
    );
    const [selectArrayOptions, setSelectArrayOptions] = useState(arrays);
    const [checkBoxValues, setCheckBoxValues] = useState(prepareCheckboxItems(filtrableObjects, arrays, unfiltrableObjects).flatMap(item => item.value));
    const [selectValue, setSelectValue] = useState(null);
    const [showFilter, setShowFilter] = useState(null);
    const [filtersTableData, setFiltersTableData] = useState([]);
    const [isExporting, setIsExporting] = useState(false);

    const handleCheckboxChange = (selectedValues) => {
        let newSelectFiltrableOptions = {};
        let newSelectArrayOptions = {};
        selectedValues.forEach((value) => {
            const splittedValue = value.split('-');

            if (splittedValue[0] === 'filtrableObjects') {
                setSelectFiltrableOptions(newSelectFiltrableOptions);
                newSelectFiltrableOptions = {
                    ...newSelectFiltrableOptions,
                    [splittedValue[1]]: prepareFieldsForExtratction()[splittedValue[0]][splittedValue[1]],
                };
            } else {
                newSelectArrayOptions = {
                    ...newSelectArrayOptions,
                    [splittedValue[1]]: prepareFieldsForExtratction()[splittedValue[0]][splittedValue[1]],
                };
            }
        });
        setSelectFiltrableOptions(newSelectFiltrableOptions);
        setSelectArrayOptions(newSelectArrayOptions);
        setCheckBoxValues(selectedValues);
        setSelectValue(null);
    };

    useEffect(() => {
        if (!selectValue) {
            setShowFilter(null);
        }
    }, [selectValue]);

    useEffect(() => {
        setFiltersTableData([]);
        setSelectValue(null);
    }, [extract]);

    const handleSelectChange = (selectedValue) => {
        const splittedValue = selectedValue.split('-');
        if (splittedValue[0] === 'arrays') {
            setShowFilter({
                isArray: true,
                fieldFamily: splittedValue[1],

            });
        } else {
            setShowFilter({
                isArray: false,
                fieldFamily: splittedValue[0],
                field: selectFiltrableOptions[splittedValue[0]][splittedValue[1]],
            });
        }
        setSelectValue(selectedValue);
    };

    const handleAddFilter = (values) => {
        const filter = {
            ...values,
            fieldFamily: showFilter.fieldFamily,
            isArray: showFilter.isArray,
            category: showFilter.isArray ? arrays[showFilter.fieldFamily] : selectFiltrableOptions[showFilter.fieldFamily].label,
            fieldLabel: !showFilter.isArray ? showFilter.field.label : '',
            fieldName: !showFilter.isArray ? showFilter.field.name : '',
            key: Math.random(),
        };
        setFiltersTableData([...filtersTableData, filter]);
        setSelectValue(null);
    };

    const handleRemoveFilter = (key) => {
        setFiltersTableData(filtersTableData.filter(item => item.key !== key));
    };


    const handleExport = async () => {
        setIsExporting(true);
        let res = null;
        try {
            if (extract === 'patients') {
                res = await exportCsv({ filters: filtersTableData, collection: 'patient', selectableItems: checkBoxValues.flatMap(item => item.split('-')[1]) });
            } else {
                res = await exportCsv({ filters: filtersTableData, collection: 'visit', selectableItems: checkBoxValues.flatMap(item => item.split('-')[1]) });
            }
            // console.log(res);
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data_extract.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            message.error("Une erreur s'est produite, Veuillez reessayer");
        }
        setIsExporting(false);
    };

    return (
        <div className="section-content">
            <Card
                title={extract === 'patients' ? 'Export données patients' : 'Export données visites'}
                extra={<Button type="primary" onClick={handleExport} loading={isExporting}> Exporter en xlsx</Button>}
            >
                <Title level={5}>Catégories à selectionner</Title>
                <Checkbox.Group
                    // TODO disable this when there's a filter added to the table
                    disabled={filtersTableData.length}
                    className="m-bottom-large"
                    options={prepareCheckboxItems(
                        filtrableObjects,
                        arrays,
                        unfiltrableObjects,
                    )}
                    onChange={handleCheckboxChange}
                    defaultValue={[
                        ...Object.keys(filtrableObjects).flatMap(
                            item => `filtrableObjects-${item}`,
                        ),
                        ...Object.keys(arrays).flatMap(item => `arrays-${item}`),
                        ...Object.keys(unfiltrableObjects).flatMap(
                            item => `unfiltrableObjects-${item}`,
                        ),
                    ]}
                />


                <Title level={5} className="m-top-medium">
                    Champs
                </Title>
                <div className="flex m-top-small">
                    <Select
                        showSearch
                        style={{ width: 250 }}
                        className="m-bottom-medium m-right-medium"
                        onSelect={handleSelectChange}
                        value={selectValue}
                    >
                        {selectFiltrableOptions && Object.keys(selectFiltrableOptions).map(fieldFamily => (
                            <OptGroup
                                label={selectFiltrableOptions[fieldFamily].label}
                                key={fieldFamily}
                            >
                                {Object.keys(selectFiltrableOptions[fieldFamily]).map(
                                    option => option !== 'label' && (
                                        <Option value={`${fieldFamily}-${option}`}>
                                            {selectFiltrableOptions[fieldFamily][option].label}
                                        </Option>
                                    ),
                                )}
                            </OptGroup>
                        ))}
                        {selectArrayOptions && (
                            <OptGroup label="Tableaux">
                                {Object.keys(selectArrayOptions).map(fieldName => (
                                    <Option value={`arrays-${fieldName}`}>
                                        {arrays[fieldName]}
                                    </Option>
                                ))}
                            </OptGroup>
                        )}
                    </Select>
                    {showFilter && <DisplayFilter {...showFilter} addFilter={handleAddFilter} />}
                </div>


                <FilterTable data={filtersTableData} onRemoveRow={handleRemoveFilter} />
            </Card>
        </div>
    );
};

CsvExtraction.propTypes = {
    extract: PropTypes.string.isRequired,
};

CsvExtraction.defaultProps = {};

const stateToProps = state => ({
    selectedAudit: state.patients.selectedAudit,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        selectAudit,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(CsvExtraction);
