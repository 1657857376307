import axios from 'axios';
import { API_PATIENTS, API_REQ_HEADER } from './config';

const create = async (data) => {
    const res = await axios.post(`${API_PATIENTS}/`, data, API_REQ_HEADER);
    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_PATIENTS}/${id}`, API_REQ_HEADER);
    return res.data;
};

const getAll = async () => {
    const res = await axios.get(API_PATIENTS, API_REQ_HEADER);
    return res.data;
};


const getAllPendingByVisit = async (visit) => {
    const res = await axios.get(`${API_PATIENTS}/pending?visit=${visit}`, API_REQ_HEADER);
    return res.data;
};

const update = async (id, data) => {
    const res = await axios.put(`${API_PATIENTS}/${id}`, data, API_REQ_HEADER);
    return res.data;
};

const getAuditTrial = async (id, query) => {
    const res = await axios.get(
        `${API_PATIENTS}/audit/${id}?${query}`,
        API_REQ_HEADER,
    );
    return res.data;
};

export {
    create, getById, getAll, update, getAuditTrial, getAllPendingByVisit,
};
