import React from 'react';
import { Table, Button, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Title } = Typography;

const mapOperationValue = {
    $gte: '>=',
    $gt: '>',
    $lte: '<=',
    $lt: '<',
};

const FilterTable = ({ data, onRemoveRow }) => {
    const columns = [
        {
            title: <b>Catégorie</b>,
            key: 'category',
            render: (_, record) => record.category,
        },

        {
            title: <b> Champ </b>,
            key: 'field',
            render: (_, record) => <p>{record.fieldLabel}</p>,
        },
        {
            title: <b> Opération et/ou valeur </b>,
            key: 'operation',
            render: (_, record) => <p>{mapOperationValue[record.operation] ? `${mapOperationValue[record.operation]} à ${record.value}` : record.value}</p>,
        },
        {
            title: <b> Actions </b>,
            key: 'operation',
            render: (_, record) => (
                <div>
                    <Button shape="round" danger onClick={() => onRemoveRow(record.key)}>
                        {' '}
                        Supprimer
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            bordered
            locale={{ emptyText: "Aucun filtre n'est ajouté" }}
            title={() => <Title level={5} className="center-text">Liste des filtres à appliquer</Title>}
        />
    );
};

FilterTable.propTypes = {
    data: PropTypes.array,
    onRemoveRow: PropTypes.func.isRequired,
};

FilterTable.defaultProps = {
    data: [],
};

export default FilterTable;
