import React, { useState } from 'react';
import {
    Form, Radio, Input, InputNumber, Button, DatePicker,
} from 'antd';
import PropTypes from 'prop-types';
import FORM_VALIDATORS from '../../helpers/formRules';

const RadioGroup = Radio.Group;

const showInput = (input, disabled) => {
    switch (input.type) {
        case 'input':
            return <Input disabled={disabled} placeholder={input.placeholder} />;

        case 'inputNumber':
            return (
                <InputNumber disabled={disabled} placeholder={input.placeholder} />
            );
        case 'date':
            return (
                <DatePicker
                    disabled={disabled}
                    placeholder={input.placeholder}
                    format="DD/MM/YYYY"
                    style={{ width: '50%' }}
                />
            );
        default:
            return <div />;
    }
};

const AddAntecedent = ({
    radioLabel,
    radioSelection,
    inputs,
    onAdd,
    buttonLabel,
    extraRadioInputs,
    disabled,
    radioTitle,
    lengthArrayData,
    showForm,
    setShowForm,
}) => {
    const [radioValue, setRadioValue] = useState();
    const [form] = Form.useForm();
    const mapRadioSection = () => radioSelection.map(radioItem => (
        <Radio
            key={radioItem.label}
            value={radioItem.value}
            style={{ margin: 10 }}
        >
            {radioItem.label}
        </Radio>
    ));
    console.log('inputs !!!', inputs);
    const onFinish = (values) => {
        const antecedent = {
            ...values,
            type: values.type ? values.type : radioValue,
        };

        onAdd(antecedent);
        setRadioValue(null);
        form.resetFields();
    };

    const disabledInputs = radioSelection.length && !radioValue;

    // useEffect(() => {
    //   if(lengthArrayData.length !== 0){
    //     setShowForm(!showForm)
    //   }
    //   return () => {
    //     setShowForm(false)
    //   }
    // }, [showForm])

    return (
        <>
            <div style={{ marginBottom: '10px' }}>
                <p>{radioTitle}</p>
                <RadioGroup
                    onChange={e => setShowForm(e.target.value)}
                    value={showForm}
                    label={radioTitle}
                >
                    <Radio value>Oui</Radio>
                    <Radio value={false}>Non</Radio>
                </RadioGroup>
            </div>
            {showForm ? (
                <Form onFinish={onFinish} layout="vertical" form={form}>
                    {radioSelection.length ? (
                        <Form.Item label={radioLabel}>
                            <Radio.Group
                                value={radioValue}
                                onChange={e => setRadioValue(e.target.value)}
                            >
                                {mapRadioSection()}
                            </Radio.Group>
                        </Form.Item>
                    ) : null}

                    {radioValue && extraRadioInputs && extraRadioInputs[radioValue] ? (
                        <Form.Item
                            label={extraRadioInputs[radioValue].label}
                            name={extraRadioInputs[radioValue].name}
                            rules={[FORM_VALIDATORS.required]}
                        >
                            {showInput(extraRadioInputs[radioValue], false)}
                        </Form.Item>
                    ) : null}

                    {inputs.map(input => (
                        <Form.Item
                            key={input.label}
                            name={input.name}
                            label={input.label}
                            rules={input.required ? [FORM_VALIDATORS.required] : []}
                        >
                            {showInput(input, disabledInputs)}
                        </Form.Item>
                    ))}
                    <Form.Item>
                        <Button
                            disabled={disabled}
                            block
                            className="center-button"
                            style={{ maxWidth: '40%' }}
                            shape="round"
                            type="primary"
                            htmlType="submit"
                        >
                            {buttonLabel}
                        </Button>
                    </Form.Item>
                </Form>
            ) : null}
        </>
    );
};

AddAntecedent.propTypes = {
    radioSelection: PropTypes.array.isRequired,
    inputs: PropTypes.array.isRequired,
    radioLabel: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    extraRadioInputs: PropTypes.object,
    disabled: PropTypes.bool,
};

AddAntecedent.defaultProps = {
    extraRadioInputs: {},
    disabled: false,
};

export default AddAntecedent;
