import React, { useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Spin, Table } from "antd";
import Title from "antd/lib/typography/Title";
import { PlusOutlined } from "@ant-design/icons";
import AddCall from "./addCall";
import "../styles.css";
// import {
//   getAllVisitsAsync,
//   selectVisitAsync,
//   setVisitsList,
//   selectVisit,
// } from "../../../redux/visits/actions";
import {
  getAllCallsAsync,
  setCallsList,
  selectCall,
  selectCallAsync,
} from "../../../redux/calls/actions";
import { formatDateTimeFromIso } from "../../../helpers/functions";
import SelectedCallComp from "./selectedCall";

export const EditContext = createContext(false);
const Visit = ({
  contentIsLoading,
  visit,
  user,
  getAllCallsAsync,
  callsList,
  selectCallAsync,
  selectedCall
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showConsult, setShowConsult] = useState(false);
  useEffect(()=>{
    console.log("mounted nurseAdd component")
    return ()=>{
    console.log("unmounted nurseAdd component")
      setShowConsult(false)
    }
  },[visit])


  useEffect(() => {
    getAllCallsAsync({ visit, user });
    return () => {
      setCallsList([]);
    };
  }, [visit, showAdd]);

  const closeSection = () => {
    setShowAdd(false);
    setShowConsult(false);
    selectCall(null);
  };
  const isEdit = true;
  console.log('[debug 0]',{showAdd, showConsult})

  // if (selectedCall) {
  if (showConsult) {
    return (
      <EditContext.Provider value={!isEdit}>
        <SelectedCallComp visit={visit} handleGoBack={closeSection} />
      </EditContext.Provider>
    );
  }
  // }

  if (showAdd) {
    return (
      <div className="section-content">
        <AddCall visit={visit} closeAdd={() => setShowAdd(false)} />
      </div>
    );
  }

  
  const columns = [
    {
      title: <b> Identifiant </b>,
      key: "customId",
      render: (_, record) => <p>{record.patient.customId}</p>,
    },
    {
      title: <b> Age </b>,
      key: "birthDate",
      render: (_, record) => {
        return <p>{record.callDate.value.date}</p>;
      },
    },
    // {
    //     title: <b>Traitement </b>,
    //     key: 'administratedDrug',
    //     render: (_, record) => <p>{record.administratedDrug.value ? record.administratedDrug.value : ''}</p>,
    // },
    {
      title: <b> Date de création </b>,
      key: "date",
      render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
    },
    {
      title: <b> Actions </b>,
      key: "_id",
      render: (_, record) => (
        <div className="flex">
          <Button
            type="primary"
            ghost
            onClick={() => {
              selectCallAsync(record._id);
              setShowConsult(true);
            }}
          >
            Consulter
          </Button>
        </div>
      ),
    },
  ];

  if (visit !== "j0" && visit !== "j7") {
    columns.splice(2, 1);
  }

  console.log("deug", {contentIsLoading,
    user,
    columns,
    callsList})
  return (
    <Spin spinning={contentIsLoading}>
      <div className="section-content">
        <Title style={{ marginBottom: -50 }} level={5}>
          Liste des appels
        </Title>
        {(user && user.type === 3) && (
          <Button
            onClick={() => setShowAdd(true)}
            shape="round"
            className="m-top-medium m-bottom-large"
            type="primary"
            size="medium"
            style={{ float: "right", marginTop: "-2px" }}
            icon={<PlusOutlined />}
          >
            Ajouter un appel
          </Button>
        )}
        <Table
          locale={{ emptyText: "Aucun appel" }}
          columns={columns}
          dataSource={callsList}
        />
      </div>
    </Spin>
  );
};

Visit.propTypes = {
  contentIsLoading: PropTypes.bool.isRequired,
  getAllCallsAsync: PropTypes.func.isRequired,
  setCallsList: PropTypes.func.isRequired,
  visitsList: PropTypes.array.isRequired,
  visit: PropTypes.string.isRequired,
  selectVisitAsync: PropTypes.func.isRequired,
  selectCall: PropTypes.func.isRequired,
  selectedCall: PropTypes.object,
  user: PropTypes.object.isRequired,
  callsList: PropTypes.object.isRequired,
  selectCallAsync: PropTypes.object.isRequired,
};

Visit.defaultProps = {
  selectedCall: null,
};

const stateToProps = (state) => ({
  contentIsLoading: state.loading.contentIsLoading,
  callsList: state.calls.callsList,
  selectedCall: state.visits.selectedCall,
  user: state.auth.user,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getAllVisitsAsync,
      // setVisitsList,
      // selectVisitAsync,
      // selectVisit,
      getAllCallsAsync,
      setCallsList,
      selectCall,
      selectCallAsync,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(Visit);
