import { DateTime } from 'luxon';
import moment from "moment";

export default {
  required: {
    required: true,
    message: "Ce champ est requis.",
  },
  number: {
    validator: (_, value) =>
      !isNaN(value)
        ? Promise.resolve()
        : // eslint-disable-next-line prefer-promise-reject-errors
          Promise.reject("Please provide a valid phone number."),
  },
  email: {
    type: "email",
    message: "Veuillez entrer une adresse e-mail valide.",
  },
  time: {
    validator: (_, value) =>
      (value && !DateTime.fromFormat(value, "HH:mm").invalid) ||
      !value ||
      value.trim().length === 0
        ? Promise.resolve()
        : // eslint-disable-next-line prefer-promise-reject-errors
          Promise.reject(new Error("Temps invalide")),
  },
  validDate: {
    validator: (_, value) =>
      value && !DateTime.fromFormat(value, "dd/MM/yyyy").invalid
        ? Promise.resolve()
        : Promise.reject(new Error("Date invalide")),
  },
  futurDate: {
    validator: (_, value) =>
      value === undefined ||
      moment().diff(
        moment(
          value
            .split("/")
            .reverse()
            .join("-")
        ),
        "days"
      ) >= 0
        ? Promise.resolve()
        : // eslint-disable-next-line prefer-promise-reject-errors
          Promise.reject(
            `Veuillez entrez une date inférieur à celle du aujourd'hui.`
          ),
  },
};
