
import * as DocsAPI from '../../../api/document';
import { sessionExpired } from '../../auth/actions';
import { setContentIsLoading } from '../../loading/actions';

export const SET_DOC_LIST = 'SET_DOC_LIST';

export const setDocsList = value => ({
    type: SET_DOC_LIST,
    payload: value,
});



export const getAllDocumentsAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await DocsAPI.getAll();
        dispatch(setDocsList(res.response));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};













