import React, { useEffect, useState } from "react";
import { Spin, Button, Table, Input, Tooltip } from "antd";
import { MessageOutlined, SearchOutlined, CheckOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Fuse from "fuse.js";
import Title from "antd/lib/typography/Title";
import {
  getAllQueriesAsync,
  setQueryList,
  selectQueryAsync,
  updateQueryAsync,
} from "../../../redux/query/actions";
import { formatDateTimeFromIso } from "../../../helpers/functions";

const QueryList = ({
  user,
  contentIsLoading,
  queryList,
  getAllQueriesAsync,
  setQueryList,
  selectQueryAsync,
  updateQueryAsync,
}) => {
  const reinitFuse = () => {
    const fuseOptions = {
      includeScore: true,
      keys: ["field.label", "messages.content", "patient.customId"],
    };

    return new Fuse(queryList, fuseOptions);
  };

  const handleListFiltering = () => {
    const strFilteredResults =
      strFilter.trim().length === 0
        ? queryList
        : fuse
            .search(strFilter)
            .filter((result) => result.score < 0.2)
            .map((result) => result.item);

    setFilteredQueryList(strFilteredResults);
  };

  const [fuse, setFuse] = useState(reinitFuse());
  const [strFilter, setStrFilter] = useState("");
  const [filteredQueryList, setFilteredQueryList] = useState(queryList);

  useEffect(() => {
    if (user) {
      const query =
        user.type === 1
          ? "?status=pending&isDoctorAgent=true"
          : "?status=pending";
      getAllQueriesAsync(query);
    }
    return () => {
      setQueryList([]);
    };
  }, [user]);

  useEffect(() => {
    setFuse(reinitFuse());
  }, [queryList]);

  useEffect(() => {
    handleListFiltering();
  }, [strFilter, queryList]);

  const columns = [
    {
      title: <b>Nature</b>,
      key: "queryName",
      render: (_, record) => <p>{record.field.label}</p>,
    },
    {
      title: <b>Commentaire</b>,
      key: "queryName",
      render: (_, record) => {
        const lastMessage =
          record.messages && record.messages.length > 0
            ? record.messages[record.messages.length - 1].content || ""
            : "";
        return <p>{lastMessage}</p>;
      },
    },
    {
      title: <b>Patient</b>,
      key: "name",
      render: (_, record) => (
        /*
          <p>
            {`${
              record.patient.firstName ? record.patient.firstName.value : ""
            } ${record.patient.lastName ? record.patient.lastName.value : ""}`}
          </p>
          */
        <p>{`${record.patient ? record.patient.customId : ""}`}</p>
      ),
    },
    {
      title: <b> Date d&apos;envoi</b>,
      key: "date",
      render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
    },
  ];

  if (user && user.type === 1) {
    columns.push({
      title: <b> Actions </b>,
      key: "_id",
      render: (_, record) => (
        <div className="flex">
          <Button
            type="primary"
            ghost
            onClick={() => {
              selectQueryAsync(record._id);
            }}
            className="m-right-medium"
            icon={<MessageOutlined />}
          >
            Répondre à la Query
          </Button>
        </div>
      ),
    });
  } else if (user && user.type === 2) {
    columns.push({
      title: <b> Actions </b>,
      key: "_id",
      render: (_, record) => (
        <div className="flex">
            <Tooltip className="m-left-medium" title="valider la Query">
              <Button
                type="text"
                shape="circle"
                onClick={() => {
                  updateQueryAsync(record._id, { status: "closed" });
                }}
                className="field-action-icon color-green"
                icon={<CheckOutlined />}
              />
            </Tooltip>
        </div>
      ),
    },);
  }

  return (
    <Spin spinning={contentIsLoading}>
      <div className="section-content">
        <Title level={5}>Queries en attente</Title>
        <Input
          size="large"
          placeholder="Filtrer..."
          value={strFilter}
          onChange={(e) => setStrFilter(e.target.value)}
          prefix={<SearchOutlined />}
        />
        <Table
          className="m-top-medium"
          columns={columns}
          dataSource={filteredQueryList}
          locale={{ emptyText: "Aucun événement" }}
        />
      </div>
    </Spin>
  );
};

QueryList.propTypes = {
  queryList: PropTypes.array.isRequired,
  getAllQueriesAsync: PropTypes.func.isRequired,
  contentIsLoading: PropTypes.bool.isRequired,
  setQueryList: PropTypes.array.isRequired,
  user: PropTypes.object,
  selectQueryAsync: PropTypes.func.isRequired,
  updateQueryAsync: PropTypes.func.isRequired,
};

QueryList.defaultProps = {
  user: null,
};

const stateToProps = (state) => ({
  user: state.auth.user,
  queryList: state.queries.queryList,
  contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllQueriesAsync,
      selectQueryAsync,
      setQueryList,
      updateQueryAsync,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(QueryList);
