import axios from 'axios';
import { API_ADVERSE, API_REQ_HEADER } from './config';

const create = async (data) => {
    const res = await axios.post(`${API_ADVERSE}/`, data, API_REQ_HEADER);
    return res.data;
};


const getAll = async () => {
    const res = await axios.get(API_ADVERSE, API_REQ_HEADER);
    return res.data;
};


const update = async (id, data) => {
    const res = await axios.put(`${API_ADVERSE}/${id}`, data, API_REQ_HEADER);
    return res.data;
};


const getById = async (id) => {
    const res = await axios.get(`${API_ADVERSE}/${id}`, API_REQ_HEADER);
    return res.data;
};

const getAuditTrial = async (id, query) => {
    const res = await axios.get(
        `${API_ADVERSE}/audit/${id}?${query}`,
        API_REQ_HEADER,
    );
    return res.data;
};

export {
    create, getAll, update, getById, getAuditTrial,
};
