import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Typography, Divider } from "antd";
import DynamicField from "../DynamicField";
import patientAdditionEmptyVals from "../../sections/Patients/add/emptyValues";

const { Title } = Typography;

const title = [
  "2. Taux de filtration",
  "3. Taux de cholestérol",
  "4. Glycémie",
  "5. Hémoglobine Hb",
  "6. Hémoglobine",
  "7. NT-ProBNP",
  "8. Troponine (TnT)",
];

const BiologicalExamination = ({
  form,
  values,
  onUpdate,
  listMessage,
  isAdd,
}) => {
  return (
    <Form
      style={{ marginTop: 30 }}
      form={form}
      className="biological-examination-form"
    >
      <Card title="Examen Biologique" className="add-antecedent-card">
        <Title style={{ marginBottom: 14 }} level={5}>
          1. Électrolytes
        </Title>
        <div>
          {["NA", "K", "Ca", "Mg", "Cl", "CO3"].map((k) => (
            <>
              {isAdd ? (
                <DynamicField
                  key={k}
                  field={values.biologicalExamination[k]}
                  onUpdate={onUpdate}
                  fieldFamily="biologicalExamination"
                  radio={true}
                />
              ) : (
                <DynamicField
                  key={k}
                  field={values.biologicalExamination[k]}
                  onUpdate={onUpdate}
                  fieldFamily="biologicalExamination"
                  //radio={true}
                />
              )}
              <DynamicField
                key={values.biologicalExamination[k]}
                field={values.biologicalExamination[`${k}date`]}
                onUpdate={onUpdate}
                fieldFamily="biologicalExamination"
              />
              <Divider />
            </>
          ))}
        </div>
        <div>
          {[
            "eGFR",
            "cholestérol",
            "Glycémie",
            "hemoglobineHB",
            "hemoglobine",
            "ProBNP",
            "Troponine",
          ].map((k, index) => (
            <>
              <Title style={{ marginBottom: 14 }} level={5}>
                {title[index]}
              </Title>
              {isAdd ? (
                <DynamicField
                  key={k}
                  field={values.biologicalExamination[k]}
                  onUpdate={onUpdate}
                  fieldFamily="biologicalExamination"
                  radio={true}
                />
              ) : (
                <DynamicField
                  key={k}
                  field={values.biologicalExamination[k]}
                  onUpdate={onUpdate}
                  fieldFamily="biologicalExamination"
                />
              )}
              <DynamicField
                key={values.biologicalExamination[`${k}date`]}
                field={values.biologicalExamination[`${k}date`]}
                onUpdate={onUpdate}
                fieldFamily="biologicalExamination"
              />
              <Divider />
            </>
          ))}
        </div>
        {listMessage &&
          listMessage.length > 0 &&
          listMessage.map((e) => (
            <>
              <strong style={{ color: "red" }}>{e.message}</strong>
              <br />
            </>
          ))}
        {/* <div style={{ marginLeft: "4%" }}>
        <Title style={{ marginBottom: 14 }} level={5}>
          Bilan hématologique
        </Title>
        <div>
          {["hemoglobine", "leucocytes", "Plaquettes"].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>
        <Title style={{ marginBottom: 14 }} level={5}>
          Bilan inflammatoire
        </Title>

        <div>
          {["VS", "CRP"].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>
        <Title style={{ marginBottom: 14 }} level={5}>
          Bilan hépatique
        </Title>
        <div>
          {[
            "ASAT",
            "ALAT",
            "Bilirubine totale",
            "Bilirubine conjuguée",
            "Phosphatase alcaline",
            "Réserves alcalines",
          ].map((k) => (
            <DynamicField
              key={k}
              field={
                values.biologicalExamination[k] ||
                patientAdditionEmptyVals.biologicalExamination[k]
              }
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>
        <Title style={{ marginBottom: 14 }} level={5}>
          Bilan Rénal
        </Title>

        <div>
          {["Urée", "Créatinine"].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>

        <div>
          {["Glycémie", "Protides"].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>
        {values.gender.value === "Femme" ? (
          <DynamicField
            key="pregnancyTest"
            field={values.biologicalExamination.pregnancyTest}
            fieldFamily="biologicalExamination"
          />
        ) : null}
      </div> 
    </Card>
    <Card title="Examen Radiologique" className="add-antecedent-card">
      <div>
        {["thorax", "ECG"].map((k) => (
          <DynamicField
            key={k}
            field={values.radiologicalExamination[k]}
            onUpdate={onUpdate}
            fieldFamily="radiologicalExamination"
          />
        ))}
      </div>*/}
      </Card>
    </Form>
  );
};
BiologicalExamination.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

BiologicalExamination.defaultProps = {
  onUpdate: () => {},
};

export default BiologicalExamination;
