import {
    SET_ADVERSE_LIST,
    ADD_ADVERSE,
    UPDATE_ADVERSE,
    SELECT_ADVERSE,
    SELECT_AUDIT,
} from '../actions';

const initialState = {
    events: [],
    selectedEvent: null,
    selectedAudit: null,

};

const adverse = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADVERSE_LIST:
            return { ...state, events: action.payload };
        case SELECT_ADVERSE:
            return { ...state, selectedEvent: action.payload };
        case SELECT_AUDIT:
            return { ...state, selectedAudit: action.payload };
        case ADD_ADVERSE:
            return {
                ...state,
                events: [action.payload, ...state.events],
            };

        case UPDATE_ADVERSE:
            const events = [];
            state.events.forEach((event) => {
                if (event._id === action.payload._id) {
                    events.push(action.payload);
                } else events.push(event);
            });
            return { ...state, events };

        default:
            return state;
    }
};

export default adverse;
