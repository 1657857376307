import React from "react";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import cornerstoneMath from "cornerstone-math";
import dicomParser from "dicom-parser";


cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

const Buttons = ({
  element,
  selectedBtn,
  setProbe,
  setSelectedBtn,
  probe,
  setReset,
}) => {
  return (
    <>
      {/* <button
        onClick={() => {
          element.current.style.width = "256px";
          element.current.style.height = "256px";
          cornerstone.resize(element.current);
        }}
      >
        256x256
      </button>
      <button
        onClick={() => {
          element.current.style.width = "512px";
          element.current.style.height = "512px";
          cornerstone.resize(element.current);
        }}
      >
        512x512
      </button>
      <button
        onClick={() => {
          element.current.style.width = "1024px";
          element.current.style.height = "1024px";
          cornerstone.resize(element.current);
        }}
      >
        1024x1024
      </button> */}
      <button
        onClick={(e) => {
          const viewport = cornerstone.getViewport(element.current);
          viewport.invert = !viewport.invert;
          cornerstone.setViewport(element.current, viewport);
        }}
      >
        Inverser
      </button>
      {/* <button
        onClick={(e) => {
          const viewport = cornerstone.getViewport(element.current);
          viewport.pixelReplication = !viewport.pixelReplication;
          cornerstone.setViewport(element.current, viewport);
        }}
      >
        Toggle Interpolation
      </button> */}
      <button
        onClick={(e) => {
          const viewport = cornerstone.getViewport(element.current);
          viewport.hflip = !viewport.hflip;
          cornerstone.setViewport(element.current, viewport);
        }}
      >
        Mirroir Horizontal
      </button>
      <button
        onClick={(e) => {
          const viewport = cornerstone.getViewport(element.current);
          viewport.vflip = !viewport.vflip;
          cornerstone.setViewport(element.current, viewport);
        }}
      >
        Mirroir Vertical
      </button>
      <button
        onClick={(e) => {
          const viewport = cornerstone.getViewport(element.current);
          viewport.rotation += 90;
          cornerstone.setViewport(element.current, viewport);
        }}
      >
        Rotation
      </button>
      <button
        className={`${selectedBtn === "length" && "btn-Clicked"}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          !probe ? setSelectedBtn("length") : setSelectedBtn("");
          setProbe(!probe);
          if (!probe) {
            const LengthTool = cornerstoneTools.LengthTool;
            cornerstoneTools.addTool(LengthTool);
            cornerstoneTools.setToolActive("Length", { mouseButtonMask: 1 });
          } else {
            cornerstoneTools.setToolDisabled("Length");
          }
        }}
      >
        Calcul de distance
      </button>
      <button
        onClick={(e) => {
          setReset(true);
        }}
      >
        Réinitialiser
      </button>
    </>
  );
};

export default Buttons;
