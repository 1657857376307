/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
    Card,
    Steps,
    Form,
    Spin,
    Button,
    Table,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { SettingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    getAllPatientsAsync,
    setPatientsList,
} from '../../redux/patients/actions';
import PatientsList from './patientsList';
import './styles.css';
import {
    createAdverseAsync,
    getAllEventsAsync,
    selectEventAsync,
} from '../../redux/adverse/actions';
import {
    formatDateTimeFromIso, structureFields,
} from '../../helpers/functions';
import AdverseForm from './add';
import emptyValues from './emptyValues';
import SelectedEvent from './selectedEvent';


const { Step } = Steps;


const AdverseEvent = ({
    user,
    contentIsLoading,
    getAllPatientsAsync,
    setPatientsList,
    patientsList,
    createAdverseAsync,
    eventsList,
    getAllEventsAsync,
    selectEventAsync,
    selectedEvent,
}) => {
    const [current, setCurrent] = useState(0);
    const [patient, selectPatient] = useState();
    const [adverseForm] = Form.useForm();
    const [values, setValues] = useState(emptyValues);
    const [formSubmitting, setFormSubmitting] = useState(false);


    useEffect(() => {
        if (user) {
            if (user.type === 1) {
                getAllPatientsAsync('');
            } else {
                getAllEventsAsync();
            }
        }
        return () => {
            setPatientsList([]);
        };
    }, [user]);

    useEffect(() => {
        if (formSubmitting) {
            createAdverseAsync({

                ...values,
                patient: patient._id,
                inclusionNumber: '',
            }).then(() => {
                setFormSubmitting(false);
                setValues(null);
                setValues(emptyValues);
            });
        }
    }, [formSubmitting]);


    const onFinish = async () => {
        const vals = await adverseForm.validateFields();
        setValues({
            ...values,
            ...structureFields(values, vals),
        });
        setFormSubmitting(true);
        adverseForm.resetFields();
        selectPatient(null);
        setCurrent(0);
    };

    const handleSelect = (p) => {
        selectPatient(p);
        setCurrent(1);
    };

    const showStep = () => {
        if (current === 0) {
            return <PatientsList data={patientsList} onSelect={handleSelect} />;
        }
        return <AdverseForm form={adverseForm} values={values} patient={patient} onFinish={onFinish} />;
    };

    const columns = [
        {
            title: <b>Identifiant</b>,
            key: 'name',
            render: (_, record) => <p>{record.patient.customId}</p>,
        },
        {
            title: <b> Date de déclaration</b>,
            key: 'date',
            render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
        },
        {
            title: <b> Actions </b>,
            key: '_id',
            render: (_, record) => (
                <div className="flex">
                    <Button
                        type="primary"
                        ghost
                        className="m-left-medium"
                        onClick={() => selectEventAsync(record._id)}
                        icon={<SettingOutlined />}
                    >
                        Consulter
                    </Button>
                </div>
            ),
        },
    ];

    if (selectedEvent) {
        return <SelectedEvent />;
    }

    return (
        <Spin spinning={contentIsLoading}>
            <div className="section-content">
                {user && user.type === 1 ? (
                    <>
                        <Title level={5} style={{ marginBottom: '20px' }}>Ajout d&apos;un événement indésirable</Title>

                        <Card>
                            <Steps size="small" current={current} onChange={setCurrent}>
                                <Step title="Choisir un patient" />
                                <Step disabled={!patient} title="Description" />
                            </Steps>
                            <div style={{ margin: 'auto', marginTop: 30, padding: 24 }}>
                                {showStep()}
                            </div>
                        </Card>
                    </>
                )
                    : (
                        <>
                            <Title level={5} style={{ marginBottom: '20px' }}>Liste des événements indésirables</Title>
                            <Table
                                className="m-top-medium"
                                columns={columns}
                                dataSource={eventsList}
                                locale={{ emptyText: 'Aucun événement' }}
                            />
                        </>
                    )
                }
            </div>
        </Spin>
    );
};

AdverseEvent.propTypes = {
    contentIsLoading: PropTypes.bool.isRequired,
    user: PropTypes.object,
    getAllPatientsAsync: PropTypes.func.isRequired,
    setPatientsList: PropTypes.func.isRequired,
    patientsList: PropTypes.array.isRequired,
    eventsList: PropTypes.array.isRequired,
    createAdverseAsync: PropTypes.func.isRequired,
    getAllEventsAsync: PropTypes.func.isRequired,
    selectEventAsync: PropTypes.func.isRequired,
    selectedEvent: PropTypes.object,
};

AdverseEvent.defaultProps = {
    user: null,
    selectedEvent: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    patientsList: state.patients.patientsList,
    eventsList: state.adverse.events,
    selectedEvent: state.adverse.selectedEvent,
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        getAllPatientsAsync,
        setPatientsList,
        createAdverseAsync,
        getAllEventsAsync,
        selectEventAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AdverseEvent);
