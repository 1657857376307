import React from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import DynamicField from "../../components/DynamicField";

const VisitInformations = ({
  form,
  values,
  onUpdate,
  visit,
  transport,
  isAdd,
}) => {
  return (
    <Form style={{ marginTop: 30, minWidth: "900px" }} form={form}>
      <div>
        {/* {visit === 'j0' || visit === 'j7' 
                ? */}
        <DynamicField
          key={"initial"}
          field={values["initial"]}
          onUpdate={onUpdate}
          fieldFamily="visitInformations"
        />
        {["consentDate", "inclusionDate"].map((k) => {
          return (
            values[k] && (
              <DynamicField
                key={k}
                field={values[k]}
                onUpdate={onUpdate}
                fieldFamily="visitInformations"
                //radio={true}
              />
            )
          );
        })}
        {isAdd ? (
          <DynamicField
            key={"transport"}
            field={values["transport"]}
            onUpdate={onUpdate}
            fieldFamily="visitInformations"
            radio={true}
          />
        ) : (
          <DynamicField
            key={"transport"}
            field={values["transport"]}
            onUpdate={onUpdate}
            fieldFamily="visitInformations"
            //radio={true}
          />
        )}
        {(transport === "Autre" || values.transport.value === "Autre") && (
          <DynamicField
            key={"transportAutre"}
            field={values["transportAutre"]}
            onUpdate={onUpdate}
            fieldFamily="visitInformations"
          />
        )}
      </div>
    </Form>
  );
};

VisitInformations.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  visit: PropTypes.func.isRequired,
};

VisitInformations.defaultProps = {
  onUpdate: () => {},
};

export default VisitInformations;
