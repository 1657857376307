import {
   ADD_CALL,
   SELECT_CALL,
   UPDATE_CALL,
   SET_CALLS_LIST,
} from '../actions';

const initialState = {
    callsList: [],
    selectedCall: null,
    // visitsPatient: [],
};

const calls = (state = initialState, action) => {
    switch (action.type) {
        case SET_CALLS_LIST:
            return { ...state, callsList: action.payload };
        case ADD_CALL:
            return { ...state, callsList: [action.payload, ...state.callsList] };
        case SELECT_CALL:
            return { ...state, selectedCall: action.payload };
        case UPDATE_CALL:
            const callsList = [];
            state.callsList.forEach((patient) => {
                if (patient._id === action.payload._id) {
                    callsList.push(action.payload);
                } else callsList.push(patient);
            });
            return { ...state, callsList };
        // case PATIENT_VISIT:
        //     return { ...state, visitsPatient: action.payload };
        default:
            return state;
    }
};

export default calls;
