import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Typography, Divider } from "antd";
import DynamicField from "../DynamicField";
import patientAdditionEmptyVals from "../../sections/Patients/add/emptyValues";

const { Title } = Typography;

const title = [
  "1. Pace maker ou autre implant (Implantable Cardioverter Defibrillator)",
  "2. Bêta-bloquant",
  "3. inhibiteur de l'enzyme de conversion de l'angiotensine (ACEi)",
  "4. bloqueur des récepteurs de l'angiotensine (ARB)",
  "5. inhibiteur de la néprilysine des récepteurs de l'angiotensine (ARNi)",
  "6. Antagoniste des récepteurs minéralocorticoïdes (ARM)",
  "7. Ivabradine",
  "8. inhibiteur du co-transporteur sodium-glucose-2 (SGLT2i)",
  "9. Diurétiques de l'anse",
  "10. Diurétiques thiazidiques)",
  "11. Statines",
  "12. Traitement antiplaquettaire",
];

const TraitementExams = (props) => {
  
  return (
    <Form
      style={{ marginTop: 30 }}
      form={props.form}
      className="biological-examination-form"
    >
      <Card title="Traitements" className="add-antecedent-card">
        <div>
          {[
            "ICD",
            "BB",
            "ACE",
            "ARB",
            "ARN",
            "ARM",
            "IV",
            "SGL",
            "DA",
            "DTH",
            "ST",
            "TA",
          ].map((k, index) => (
            <>
              <Title style={{ marginBottom: 14 }} level={5}>
                {title[index]}
              </Title>
              <DynamicField
                key={k}
                field={props.values.traitements[k]}
                onUpdate={props.onUpdate}
                fieldFamily="TraitementsExamination"
                radio={true}
              />
              {props[k] === "Oui" ? (
                <>
                  <DynamicField
                    key={props.values.traitements[k]}
                    field={props.values.traitements[`${k}dateDebut`]}
                    onUpdate={props.onUpdate}
                    fieldFamily="TraitementsExamination"
                  />
                  <DynamicField
                    key={props.values.traitements[k]}
                    field={props.values.traitements[`${k}dateFin`]}
                    onUpdate={props.onUpdate}
                    fieldFamily="TraitementsExamination"
                  />
                </>
              ) : null}
              <Divider />
            </>
          ))}
        </div>
      </Card>
    </Form>
  );
};
TraitementExams.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

TraitementExams.defaultProps = {
  onUpdate: () => {},
};

export default TraitementExams;
