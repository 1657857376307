import React from "react";
import { Card, Form, Input, Button, Collapse, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import Messages from "./messages";
import { pushQueryMessageAsync, selectQuery } from "../../redux/query/actions";
import {
  getPatientFullName,
  getTimeFromISODate,
} from "../../helpers/functions";
import history from "../../history";

const { Panel } = Collapse;
const { Text } = Typography;

const QueryMessages = ({ query, pushQueryMessageAsync, user, selectQuery }) => {
  const [form] = Form.useForm();

  const onFinish = ({ messageToSend }) => {
    pushQueryMessageAsync(query._id, {
      message: {
        content: messageToSend,
        time: Date.now(),
        sender: user._id,
      },
    });
    form.resetFields();
  };

  const CardTitle = (
    <Collapse defaultActiveKey={["1"]} collapsible="disabled">
      <Panel
        style={{ background: "#fff" }}
        header={
          <div className="flex" style={{ alignItems: "center" }}>
            <InfoCircleOutlined className="m-right-small" />
            Informations sur la query
          </div>
        }
        key="1"
      >
        <div className="flex m-bottom-medium">
          <Text strong>Participant : </Text>
          &nbsp;
          {/* {getPatientFullName(query.patient)} */}
          {query.patient.customId}
        </div>
        <div className="flex m-bottom-medium">
          <Text strong>Champ : </Text>
          &nbsp;
          {query.visit !== undefined ? (
            <p
              onClick={(e) => {
                selectQuery(null);
                history.push({
                  pathname: query.visit != -1 ? `/${query.visit}` : `/patients`,
                  search: `${query.patient._id}`,
                  state: { withQueryPatientId: true },
                });
              }}
              style={{ color: "blue", cursor: "pointer" }}
            >
              {query.field.label}
            </p>
          ) : (
            <p>{query.field.label}</p>
          )}
        </div>
        <div className="flex m-bottom-medium">
          <Text strong>Valeur initiale : </Text>
          &nbsp;
          {Object.keys(query.field.value).length === 2
            ? `${query.field.value.date} à ${getTimeFromISODate(
                query.field.value.time
              )}`
            : query.field.value}
        </div>
      </Panel>
    </Collapse>
  );

  return (
    <Card
      className="query-messages-card"
      title={CardTitle}
      bordered={false}
      actions={[
        <div className="flex-column" style={{ marginRight: 20 }}>
          <Form onFinish={onFinish} form={form}>
            <Form.Item name="messageToSend" style={{ marginBottom: 12 }}>
              <Input
                autoFocus
                style={{ marginBottom: 10 }}
                placeholder="Tapez votre message ici"
              />
            </Form.Item>
            <Form.Item>
              <Button block type="primary" htmlType="submit">
                Envoyer
              </Button>
            </Form.Item>
          </Form>
        </div>,
      ]}
    >
      <div style={{ height: "40vh", overflow: "auto" }} id="messages-container">
        <Messages messages={query ? query.messages : []} />
      </div>
    </Card>
  );
};

QueryMessages.propTypes = {
  query: PropTypes.object,
  pushQueryMessageAsync: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

QueryMessages.defaultProps = {
  query: null,
};

const stateToProps = (state) => ({
  user: state.auth.user,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushQueryMessageAsync,
      selectQuery,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(QueryMessages);
