export default {
    firstName: {
        name: 'firstName',
        label: 'Prénom',
        rules: ['required'],
        inputStyle: { width: 'min-content' },
        type: 'input',
        strongLabel: true,
    },
    lastName: {
        name: 'lastName',
        label: 'Nom',
        rules: ['required'],
        inputStyle: { width: 'min-content' },
        type: 'input',
        strongLabel: true,

    },
    gender: {
        name: 'gender',
        label: 'Sexe',
        rules: ['required'],
        type: 'radio',
        radioFields: [
            { label: 'Homme', value: 'Homme' },
            { label: 'Femme', value: 'Femme' },
        ],
        strongLabel: true,

    },
    pregnant: {
        name: 'pregnant',
        label: 'Grossesse',
        rules: ['required'],
        type: 'radio',
        radioFields: [
            { label: 'Oui', value: true },
            { label: 'Non', value: false },
        ],
        strongLabel: true,
    },
    phoneFirst: {
        name: 'phoneFirst',
        label: 'Télephone 1',
        rules: ['required'],
        type: 'input',
        placeholder: 'e.g: 20 111 111',
        strongLabel: true,
    },
    phoneSecond: {
        name: 'phoneSecond',
        label: 'Télephone 2',
        rules: [''],
        type: 'input',
        placeholder: 'e.g: 20 111 111',
        strongLabel: true,
    },
    phoneThird : {
        name: 'phoneThird',
        label: 'Télephone 3',
        rules: [''],
        type: 'input',
        placeholder: 'e.g: 20 111 111',
        strongLabel: true,
    },
    address: {
        name: 'address',
        label: 'Adresse',
        rules: ['required'],
        type: 'input',
        placeholder: 'addresse participant',
        strongLabel: true,
    },
    distance: {
        name: 'distance',
        label: 'Distance par rapport au centre investigateur',
        rules: ['required'],
        type: 'radio',
        radioFields: [
            { label: '>30 km', value: ">30" },
            { label: '<30 km', value: "<30" },
        ],
        strongLabel: true,
    },
    education:{
        name: 'education',
        label:"Niveau d'education",
        rules: ['required'],
        type: 'radio',
        radioFields: [
            { label: 'aucun', value: "aucun" },
            { label: 'Primaire', value: "Primaire" },
            { label: 'bac', value: "bac" },
            { label: 'Secondaire', value: "Secondaire" },
            { label: 'Diplôme universitaire', value: "Diplôme universitaire" },
        ],
        strongLabel: true,
    },
    tabac: {
        name: 'tabac',
        label: 'Tabagisme',
        rules: ['required'],
        type: 'radio',
        radioFields: [
            { label: 'Oui', value: true },
            { label: 'Non', value: false },
        ],
        strongLabel: true,
    },
    nbCigarettes: {
        name: 'nbCigarettes',
        label: 'Si oui, quel est le nombre de cigaretts par jour',
        rules: ['required'],
        type: 'radio',
        radioFields: [
            { label: '< 10 cigarettes /Jour', value: "< 10 cigarettes /Jour" },
            { label: '10<= cigarettes <=20 /Jour', value: "10<= cigarettes <=20 /Jour" },            
            { label: "> 20 cigarettes /Jour (plus qu'un paquet / jour)", value: "> 20 cigarettes /Jour (plus qu'un paquet / jour)" },
        ],
        strongLabel: true,
    },
    alcohol: {
        name: 'alcohol',
        label: "Consomation d'alcool",
        rules: ['required'],
        type: 'radio',
        radioFields: [
            { label: 'Oui', value: true },
            { label: 'Non', value: false },
        ],
        strongLabel: true,
    },
    alcoholConsommation: {
        name: 'alcoholConsommation',
        label: "Si oui, à que'elle fréquence",
        rules: ['required'],
        type: 'radio',
        radioFields: [
            { label: 'quotidienne', value: "quotidienne" },
            { label: 'hebdomadaire', value: "hebdomadaire" },
            { label: 'occasionnelle', value: "occasionnelle" },
            { label: 'Rare', value: "Rare" },
        ],
        strongLabel: true,
    },
    birthDate: {
        name: 'birthDate',
        label: 'Date de naissance',
        rules: ['required', 'validDate'],
        inputStyle: { width: 'min-content' },
        type: 'input',
        placeholder: 'e.g: 01/01/2000',
        strongLabel: true,
    },
    weight: {
        name: 'weight',
        label: 'Poids',
        rules: ['required'],
        type: 'inputNumber',
        minNumber: 1,
        unity: 'Kg',
        strongLabel: true,
    },
    height: {
        name: 'height',
        label: 'Taille',
        rules: ['required'],
        type: 'inputNumber',
        minNumber: 10,
        unity: 'Cm',
        strongLabel: true,

    },
    systolique: {
        name: 'systolique',
        rules: ['required'],
        type: 'inputNumber',
        label: 'Systolique',
        // placeholder: 'TA systolique',
        unity: 'mmHg',
        strongLabel: true,
    },
    // systoliqueRight: {
    //     name: 'systoliqueRight',
    //     rules: [''],
    //     type: 'inputNumber',
    //     label: 'PAS membre droit',
    //     // placeholder: 'TA systolique',
    //     unity: 'mmHg',
    //     strongLabel: true,

    // },
    diastolique: {
        name: 'diastolique',
        rules: ['required'],
        type: 'inputNumber',
        label: 'Diastolique',
        unity: 'mmHg',
        // placeholder: 'TA diastolique',
        strongLabel: true,
    },
    // diastoliqueRight: {
    //     name: 'diastoliqueRight',
    //     rules: [''],
    //     type: 'inputNumber',
    //     label: 'PAD membre droit',
    //     unity: 'mmHg',
    //     // placeholder: 'TA diastolique',
    //     strongLabel: true,
    // },
    bodyTemperature: {
        name: 'bodyTemperature',
        rules: ['required'],
        type: 'inputNumber',
        label: 'Température',
        unity: '°C',
        strongLabel: true,
    },
    // consentSign: {
    //     name: 'consentSign',
    //     label: 'Signature du consentement',
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    //     strongLabel: true,

    // },
    // consentDate: {
    //     name: 'consentDate',
    //     label: 'Date de signature du consentement (jj/mm/aaaa)',
    //     rules: ['validDate'],
    //     type: 'input',
    //     inputStyle: { width: 'min-content' },
    //     placeholder: 'e.g: 01/01/2000',
    //     strongLabel: true,

    // },
    biologicalExamination: {
        // NFS: {
        //     name: 'NFS',
        //     label: 'NFS',
        //     rules: [''],
        //     type: 'inputNumber',
        //     strongLabel: true,

        // },
        hemoglobine: {
            name: 'hemoglobine',
            label: 'Hemoglobine',
            rules: [''],
            type: 'inputNumber',
            unity: 'g/dl',
            warning: false,
            // placeholder: '[150-400]',
            strongLabel: true,

        },
        leucocytes: {
            name: 'leucocytes',
            label: 'Leucocytes',
            rules: [''],
            type: 'inputNumber',
            unity: '10^9/L',
            warning: false,
            // placeholder: '[150-400]',
            strongLabel: true,
        },
        Plaquettes: {
            name: 'Plaquettes',
            label: 'Plaquettes',
            rules: [''],
            type: 'inputNumber',
            unity: '10e3/µL',
            warning: false,
            placeholder: '[150-400]',
            strongLabel: true,

        },
        VS: {
            name: 'VS',
            label: 'VS',
            rules: [''],
            type: 'inputNumber',
            unity: 'mm/h',
            strongLabel: true,

        },
        CRP: {
            name: 'CRP',
            label: 'CRP',
            rules: [''],
            type: 'inputNumber',
            unity: 'mg/L',
            strongLabel: true,

        },
        ASAT: {
            name: 'ASAT',
            label: 'ASAT',
            rules: [''],
            type: 'inputNumber',
            unity: 'UI/L',
            warning: false,
            placeholder: '[0-32]',
            strongLabel: true,

        },
        ALAT: {
            name: 'ALAT',
            label: 'ALAT',
            rules: [''],
            type: 'inputNumber',
            unity: 'UI/L',
            warning: false,
            placeholder: '[10-33]',
            strongLabel: true,

        },
        'Bilirubine totale': {
            name: 'Bilirubine totale',
            label: 'Bilirubine totale',
            rules: [''],
            type: 'inputNumber',
            placeholder: '[0-10]',
            strongLabel: true,


        },
        'Bilirubine conjuguée': {
            name: 'Bilirubine conjuguée',
            label: 'Bilirubine conjuguée',
            rules: [''],
            type: 'inputNumber',
            placeholder: '[0-2]',
            strongLabel: true,


        },
        // 'Phosphatase alcaline': {
        //     name: 'Phosphatase alcaline',
        //     label: 'Réserves alcalines',
        //     rules: [''],
        //     type: 'inputNumber',
        //     palceholder: '[35-104]',
        //     strongLabel: true,
        //     unity: 'mmol/L',
        // },        
        
        'Phosphatase alcaline': {
            name: 'Phosphatase alcaline',
            label: 'Phosphatase alcaline',
            rules: [''],
            type: 'inputNumber',
            palceholder: '[35-104]',
            strongLabel: true,
            unity: 'U/L',
        },
        'Réserves alcalines': {
            name: 'Réserves alcalines',
            label: 'Réserves alcalines',
            rules: [''],
            type: 'inputNumber',
            palceholder: '',
            strongLabel: true,
            unity: 'mmol/L',
        },        
        'K+': {
            name: 'K+',
            label: 'K+',
            rules: [''],
            type: 'inputNumber',
            palceholder: '[3.50-5.10]',
            strongLabel: true,

        },
        'NA+': {
            name: 'NA+',
            label: 'NA+',
            rules: [''],
            type: 'inputNumber',
            palceholder: '[135-145]',
            strongLabel: true,

        },
        'Ca++': {
            name: 'Ca++',
            label: 'Ca++',
            rules: [''],
            type: 'inputNumber',
            placeholder: '[86-102]',
            strongLabel: true,

        },
        'Mg+': {
            name: 'Mg+',
            label: 'Mg+',
            rules: [''],
            type: 'inputNumber',
            strongLabel: true,

        },
        Urée: {
            name: 'Urée',
            label: 'Urée',
            rules: [''],
            type: 'inputNumber',
            unity: 'g/L',
            warning: false,
            palceholder: '[0.16-0.48]',
            strongLabel: true,

        },
        Créatinine: {
            name: 'Créatinine',
            label: 'Créatinine',
            rules: [''],
            type: 'inputNumber',
            unity: 'mg/L',
            warning: false,
            placeholder: '[5-9]',
            strongLabel: true,

        },
        Glycémie: {
            name: 'Glycémie',
            label: 'Glycémie',
            rules: [''],
            type: 'inputNumber',
            unity: 'g/L',
            warning: false,
            palceholder: '[0.70-1.10]',
            strongLabel: true,

        },
        Protides: {
            name: 'Protides',
            label: 'Protides totaux',
            rules: [''],
            type: 'inputNumber',
            unity: 'g/L',
            warning: false,
            palceholder: '[65-80]',
            strongLabel: true,

        },
        pregnancyTest: {
            name: ['pregnancyTest'],
            label: 'Test de grossesse',
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Négatif', value: 'Négatif' },
                { label: 'Positif', value: 'Positif' },
            ],
            strongLabel: true,

        },
    },
    radiologicalExamination: {
        thorax: {
            name: 'thorax',
            label: 'Radio thorax : normale',
            strongLabel: true,
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],

        },
        ECG: {
            name: 'ECG',
            label: 'ECG : normal',
            strongLabel: true,
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],

        },
    },
    decision: {
        status: {
            name: 'status',
            label: 'Participant éligible à l’étude',
            rules: ['required'],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Participe' },
                { label: 'Non', value: 'Ne participe pas' },
            ],
            strongLabel: true,
        },
        centre :{
            name: 'centre',
            label: 'Numéro du Centre (FB/HT/HM/MM/CH)',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: ['required'],
            type: 'radio',
            radioFields: [
                { label: 'FB', value: 'FB' },
                { label: 'HT', value: 'HT' },
                { label: 'HM', value: 'HM' },
                { label: 'MM', value: 'MM' },
                { label: 'CH', value: 'CH' },
            ],
        },
        nextVisitDate: {
            name: 'nextVisitDate',
            label: 'Prochaine date pour la visite V2 : CONSULTATION / SELECTION',
            rules: ["required"],
            type: 'input',
            inputStyle: { width: 'min-content' },
            placeholder: 'e.g: 01/01/2000',
            strongLabel: true,
        },
        // descirptionNonEligibile: {
        //     name: 'descirptionNonEligibile',
        //     label: 'Description de non élegibilté du participant',
        //     rules: [''],
        //     inputStyle: { width: 'min-content' },
        //     type: 'inputTextArea',
        //     strongLabel: true,
        // },
    },
    medicalAntecedents: [],
    treatments: [],
    medicalAntecedentsProps: {
        name: 'medicalAntecedentsProps',
        sdv: false,
        query: false,
        frozen: false,
    },
    treatmentsProps: {
        name: 'treatmentsProps',
        sdv: false,
        query: false,
        frozen: false,
    },
};
