import {
    SET_VISITS_LIST,
    ADD_VISIT,
    SELECT_VISIT,
    UPDATE_VISIT,
    PATIENT_VISIT,
} from '../actions';

const initialState = {
    visitsList: [],
    selectedVisit: null,
    visitsPatient: [],
};

const patients = (state = initialState, action) => {
    switch (action.type) {
        case SET_VISITS_LIST:
            return { ...state, visitsList: action.payload };
        case ADD_VISIT:
            return { ...state, visitsList: [action.payload, ...state.visitsList] };
        case SELECT_VISIT:
            return { ...state, selectedVisit: action.payload };
        case UPDATE_VISIT:
            const visitsList = [];
            state.visitsList.forEach((patient) => {
                if (patient._id === action.payload._id) {
                    visitsList.push(action.payload);
                } else visitsList.push(patient);
            });
            return { ...state, visitsList };
        case PATIENT_VISIT:
            return { ...state, visitsPatient: action.payload };
        default:
            return state;
    }
};

export default patients;
