import axios from 'axios';
import { API_VISITS, API_REQ_HEADER } from './config';

const create = async (data) => {
    const res = await axios.post(API_VISITS, data, API_REQ_HEADER);
    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_VISITS}/${id}`, API_REQ_HEADER);
    return res.data;
};

const getAllByPatientId = async (id) => {
    const res = await axios.get(`${API_VISITS}/all/${id}`, API_REQ_HEADER);
    return res.data;
};

const getByPatient = async (patient) => {
    const res = await axios.get(`${API_VISITS}/patient?patient=${patient}`, API_REQ_HEADER);
    return res.data;
};

const getAll = async (visit) => {
    const res = await axios.get(`${API_VISITS}?visit=${visit}`, API_REQ_HEADER);
    return res.data;
};

const pushBloodSample = async (id, data) => {
    const res = await axios.post(`${API_VISITS}/samples/${id}`, data, API_REQ_HEADER);
    return res.data;
};


const update = async (id, data) => {
    const res = await axios.put(`${API_VISITS}/${id}`, data, API_REQ_HEADER);
    return res.data;
};

export {
    create, getById, getByPatient, getAll, pushBloodSample, update, getAllByPatientId,
};
