import axios from 'axios';
import { API_CALLS, API_REQ_HEADER } from './config';

const create = async (data) => {
    const res = await axios.post(`${API_CALLS}/`, data, API_REQ_HEADER);
    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_CALLS}/${id}`, API_REQ_HEADER);
    return res.data;
};

const getAll = async (visit) => {
    const res = await axios.get( visit ? `${API_CALLS}?visit=${visit}` : API_CALLS , API_REQ_HEADER);
    return res.data;
};

const update = async (id, data) => {
    const res = await axios.put(`${API_CALLS}/${id}`, data, API_REQ_HEADER);
    return res.data;
};
export {
    create, getById, getAll,update
};
