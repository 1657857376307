import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Divider, Typography } from "antd";
import DynamicField from "../DynamicField";
import { calculateBMI } from "../../helpers/functions";
import "./styles.css";

const { Text } = Typography;

const Inclusion = ({
  inclusionForm,
  exclusionForm,
  dateVisiteForm,
  values,
  onUpdate,
}) => {
  return (
    <div className="inclusionContainer">
      <Form
        style={{ marginTop: 30 }}
        form={dateVisiteForm}
        className="inclusion-form"
      >
        <DynamicField
          key={"dateVisite"}
          field={values["dateVisite"]}
          onUpdate={onUpdate}
          fieldFamily="general"
        />
        <Divider style={{ margin: "12px 0" }} />
      </Form>
      <Card title="Critéres d'inclusion" className="add-antecedent-card">
        <Form
          style={{ marginTop: 30 }}
          form={inclusionForm}
          className="inclusion-form"
        >
          {["age", "ic", "pavd"].map((k) => (
            <>
              <DynamicField
                key={k}
                field={values.inclusion[k]}
                onUpdate={onUpdate}
                fieldFamily="inclusion"
                radio={true}
              />
              {/* {k === 'BMI' ? (
                            <Text type="warning">
                                IMC de ce volontaire :&nbsp;
                                {values.BMI
                  || calculateBMI(values.weight.value, values.height.value)}
                            </Text>
                        ) : null} */}
              <Divider style={{ margin: "12px 0" }} />
            </>
          ))}
        </Form>
      </Card>
      <Card title="Critères de non inclusion" className="add-antecedent-card">
        <Form
          style={{ marginTop: 30 }}
          form={exclusionForm}
          className="inclusion-form"
        >
          {[
            "anotherTrial",
            "age",
            "dic",
            "distance",
            "ipe",
            "ilFormulaireConsentement",
          ].map((k) => (
            <>
              <DynamicField
                key={k}
                field={values.exclusion[k] ? values.exclusion[k] : null}
                onUpdate={onUpdate}
                fieldFamily="exclusion"
                radio={true}
              />
              <Divider style={{ margin: "12px 0" }} />
            </>
          ))}
          {/* {values.gender.value === 'Femme'

                    ? (
                        <DynamicField
                            key="pregnancyTest"
                            field={values.exclusion.pregnant}
                            onUpdate={onUpdate}
                            fieldFamily="exclusion"
                        />
                    )

                    : null} */}
        </Form>
      </Card>
    </div>
  );
};

Inclusion.propTypes = {
  exclusionForm: PropTypes.object.isRequired,
  inclusionForm: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

Inclusion.defaultProps = {
  onUpdate: () => {},
};

export default Inclusion;
