import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Form } from 'antd';
import emptyValues from '../add/emptyValues';
import BloodSample from '../BloodSample';
import { addBloodSampleAsync } from '../../../redux/visits/actions';
import { structureFields } from '../../../helpers/functions';

const AddBloodSample = ({
    open,
    handleClose,
    addBloodSampleAsync,
    selectedVisit,
}) => {
    const [form] = Form.useForm();
    const [values] = useState(emptyValues);

    useEffect(() => {
        if (open) form.resetFields();
    }, [open]);

    const handleCreate = async () => {
        try {
            const vals = await form.validateFields();
            const body = structureFields(values, vals);
            body.key = Math.random();
            addBloodSampleAsync(selectedVisit._id, body);
            handleClose();
        } catch (err) {
            // nada
        }
    };

    return (
        <Modal
            okText="Ajouter"
            cancelText="Annuler"
            visible={open}
            onCancel={handleClose}
            onOk={handleCreate}
        >
            <BloodSample form={form} values={values} />
        </Modal>
    );
};

AddBloodSample.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    addBloodSampleAsync: PropTypes.func.isRequired,
    selectedVisit: PropTypes.object.isRequired,
};

const stateToProps = state => ({
    selectedVisit: state.visits.selectedVisit,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        addBloodSampleAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AddBloodSample);
