import React, { useState, useEffect } from "react";
import { Steps, Card, Spin, Form, Button, Row, Modal } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PlusOutlined } from "@ant-design/icons";
import HeadBreadCrumb from "../../components/HeadBreadCrumb";
import emptyValues from "./add/emptyValues";
import VisitInformations from "./VisitInformations";
import Clinical from "../../components/medicalRecord/clinical";
import BloodSample from "./BloodSample";
import {
  updateVisitAsync,
  getAllVisitsByPatientAsync,
} from "../../redux/visits/actions";
import { parseVisitName, structureFields } from "../../helpers/functions";
import AddBloodSample from "./SamplesSection/add";
import QueryModal from "../../components/QueryModal/index";
import BloodSamples from "./SamplesSection/index";
//import FluidsNumber from "./add/FluidsNumber";
import "./styles.css";
import DrugForm from "./add/drugForm";
import Inclusion from "../../components/medicalRecord/inclusion";
import Examinations from "../../components/medicalRecord/examinations";
import AntecedentsExams from "../../components/medicalRecord/antecedents";
import SignesExams from "../../components/medicalRecord/signes";
import TraitementExams from "../../components/medicalRecord/traitements";
import { DateTime } from "luxon";
import Questionnaire from "../../components/medicalRecord/questionnaire";
import Measure from "../../components/medicalRecord/measures";

const { Step } = Steps;


const SelectedVisit = ({
  contentIsLoading,
  visit,
  selectedVisit,
  handleGoBack,
  updateVisitAsync,
  user,
  listVisitsByPatient,
  getAllVisitsByPatientAsync,
}) => {
  const [current, setCurrent] = useState(0);
  const [values, setValues] = useState(emptyValues);
  const [openQueryModal, setOpenQueryModal] = useState(false);
  const [selectedField, selectField] = useState();
  const [clinicalForm] = Form.useForm();
  const [visitInformationsForm] = Form.useForm();
  const [bloodSampleForm] = Form.useForm();
  const [bloodSamples, setBloodSamples] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddSample, setShowAddSample] = useState(false);
  const [selectedBloodSample, setSelectedBloodSample] = useState("");
  const [questionnaireForm] = Form.useForm();
  const [measureForm] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [examinationsForm] = Form.useForm();
  const [antecedentsForm] = Form.useForm();
  const [signesForm] = Form.useForm();
  const [traitementsForm] = Form.useForm();
  const [dateVisiteForm] = Form.useForm();
  const [inclusionForm] = Form.useForm();
  const [drugForm] = Form.useForm();
  const [exclusionForm] = Form.useForm();

  const [transport, setTransport] = useState(null);
  const [MC, setMC] = useState(null);
  const [PCI, setPCI] = useState(null);
  const [IDM, setIDM] = useState(null);
  const [CABG, setCABG] = useState(null);
  const [PC, setPC] = useState(null);
  const [ICP, setICP] = useState(null);
  const [AFA, setAFA] = useState(null);
  const [AVC, setAVC] = useState(null);
  const [MVAP, setMVAP] = useState(null);
  const [DIABETE, setDIABETE] = useState(null);
  const [HA, setHA] = useState(null);
  const [CANCER, setCANCER] = useState(null);
  const [BPCO, setBPCO] = useState(null);
  const [MRC, setMRC] = useState(null);
  const [MDF, setMDF] = useState(null);
  const [DEPRESSION, setDEPRESSION] = useState(null);
  const [DT, setDT] = useState(null);
  /** SIGNES */
  const [RP, setRP] = useState(null);
  const [ER, setER] = useState(null);
  const [EE, setEE] = useState(null);
  const [TN, setTN] = useState(null);
  const [RTE, setRTE] = useState(null);
  const [ANG, setANG] = useState(null);
  const [JPV, setJPV] = useState(null);
  const [TBC, setTBC] = useState(null);
  const [RS, setRS] = useState(null);
  const [OP, setOP] = useState(null);
  const [DPN, setDPN] = useState(null);
  const [ORT, setORT] = useState(null);
  const [CXR, setCXR] = useState(null);
  /** END SIGNES */
  /**TREATEMENTS */
  const [ICD, setICD] = useState(null);
  const [BB, setBB] = useState(null);
  const [ACE, setACE] = useState(null);
  const [ARB, setARB] = useState(null);
  const [ARN, setARN] = useState(null);
  const [ARM, setARM] = useState(null);
  const [IV, setIV] = useState(null);
  const [SGL, setSGL] = useState(null);
  const [DA, setDA] = useState(null);
  const [DTH, setDTH] = useState(null);
  const [ST, setST] = useState(null);
  const [TA, setTA] = useState(null);

  const [isAdd, setIsAdd] = useState(false);



  /** END TREATEMENTS */
  const onUpdateValidation = (name, value) => {
    switch (name) {
      case "ICD":
        setICD(value);
        break;
      case "BB":
        setBB(value);
        break;
      case "ACE":
        setACE(value);
        break;
      case "ARB":
        setARB(value);
        break;
      case "ARN":
        setARN(value);
        break;
      case "ARM":
        setARM(value);
        break;
      case "IV":
        setIV(value);
        break;
      case "SGL":
        setSGL(value);
        break;
      case "TA":
        setTA(value);
        break;
      case "DA":
        setDA(value);
        break;
      case "DTH":
        setDTH(value);
        break;
      case "ST":
        setST(value);
        break;
      case "ORT":
        setORT(value);
        break;
      case "CXR":
        setCXR(value);
        break;
      case "JPV":
        setJPV(value);
        break;
      case "TBC":
        setTBC(value);
        break;
      case "RS":
        setRS(value);
        break;
      case "OP":
        setOP(value);
        break;
      case "DPN":
        setDPN(value);
        break;
      case "TN":
        setTN(value);
        break;
      case "RTE":
        setRTE(value);
        break;
      case "ANG":
        setANG(value);
        break;
      case "RP":
        setRP(value);
        break;
      case "ER":
        setER(value);
        break;
      case "EE":
        setEE(value);
        break;
      case "PCI":
        setPCI(value);
        break;
      case "IDM":
        setIDM(value);
        break;
      case "CABG":
        setCABG(value);
        break;
      case "transport":
        setTransport(value);
        break;
      case "MC":
        if(value ==="Oui"){
          setIsAdd(true)
        }
        setMC(value);
        break;
      case "PC":
        setPC(value);
        break;
      case "ICP":
        setICP(value);
        break;
      case "AFA":
        setAFA(value);
        break;
      case "AVC":
        setAVC(value);
        break;
      case "MVAP":
        setMVAP(value);
        break;
      case "DIABETE":
        setDIABETE(value);
        break;
      case "HA":
        setHA(value);
        break;
      case "CANCER":
        setCANCER(value);
        break;
      case "BPCO":
        setBPCO(value);
        break;
      case "MRC":
        setMRC(value);
        break;
      case "MDF":
        setMDF(value);
        break;
      case "DEPRESSION":
        setDEPRESSION(value);
        break;
      case "DT":
        setDT(value);
        break;
      // case "goodHealth":
      // case "contraception":
      // case "signedConsent":
      // case "smoker":
      // case "alcohol":
      // case "biology":
      // case "followProcedure":
      //   setInclusion({ ...inclusion, [name]: value });
      //   break;
      // case "anotherTrial":
      // case "pharmaceuticals":
      // case "chronicalDisease":
      // case "allergy":
      // case "anomalyECG":
      // case "HTA":
      // case "antecedents":
      // case "drugs":
      // case "usageDuringTrial":
      // case "VIH":
      //   setExclusion({ ...exclusion, [name]: value });
      //   break;
      default:
        break;
    }
  };

  useEffect(()=>{

  },[values])

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setIsModalVisible(true);
  }, [selectedBloodSample]);

  const mapFiedFamilyToForm = {
    visitInformations: visitInformationsForm,
    dateVisite: dateVisiteForm,
    biologicalExamination: examinationsForm,
    antecedents: antecedentsForm,
    signes: signesForm,
    traitements: traitementsForm,
    clinical: clinicalForm,
    questionnaire: questionnaireForm,
    measures: measureForm,
  };

  useEffect(() => {
    getAllVisitsByPatientAsync(selectedVisit.patient._id);
  }, [selectedVisit.patient._id]);

  useEffect(() => {
    if (current === 2) {
      setSelectedBloodSample("firstTube");
    }
    return () => {
      setSelectedBloodSample("");
    };
  }, [current]);

  useEffect(()=>{
    if(MC === "Oui"){
      if(values.antecedents.hasOwnProperty("CABG") === false && 
      values.antecedents.hasOwnProperty("PCI") === false&&
      values.antecedents.hasOwnProperty("IDM") === false ){
        
        setValues({
          ...values,
          antecedents:{
            ...values.antecedents,
            CABG: {
              ...emptyValues.antecedents["CABG"],
              readOnly:true,
            },
            PCI: {
              ...emptyValues.antecedents["PCI"],
              readOnly:true,
            },
            IDM: {
              ...emptyValues.antecedents["IDM"],
              readOnly:true
            },
          }
        })
      }
    }
  },[MC])

  useEffect(()=>{
    console.log(values)
  },[values])

  const changeProp = (fieldFamily, fieldName, prop, value) => {
    if (fieldFamily === "inclusion" || fieldFamily === "exclusion") {
      setValues({
        ...values,
        [fieldFamily]: {
          ...values[fieldFamily],
          [fieldName]: {
            ...values[fieldFamily][fieldName],
            [prop]: value,
          },
        },
      });
    } else {
      setValues({
        ...values,
        [fieldName]: {
          ...values[fieldName],
          [prop]: value,
        },
      });
    }
  };

  const handleUpdateField = (fieldFamily, fieldName, prop, value, field) => {
    console.log(
      "fieldFamily, fieldName, prop, value",
      fieldFamily,
      fieldName,
      prop,
      value,
      field,
      visit
    );
    let currentForm;
    if (visit === "j0") {
      switch (current) {
        case 0:
          currentForm = "visitInformations";
          break;
        case 1:
          currentForm = "dateVisite";
          break;
        case 2:
          currentForm = "biologicalExamination";
          break;
        case 3:
          currentForm = "antecedents";
          break;
        case 4:
          currentForm = "signes";
          break;
        case 5:
          currentForm = "traitements";
          break;
        default:
          currentForm = "general";
          break;
      }
    } else if (visit === "j1") {
      switch (current) {
        case 0:
          currentForm = "clinical";
          break;
        case 1:
          currentForm = "questionnaire";
          break;
        default:
          currentForm = "general";
          break;
      }
    } else {
      switch (current) {
        case 0:
          currentForm = "clinical";
          break;
        case 1:
          currentForm = "measures";
          break;
        default:
          currentForm = "general";
          break;
      }
    }

    const form = mapFiedFamilyToForm[fieldFamily]
      ? mapFiedFamilyToForm[fieldFamily]
      : mapFiedFamilyToForm[currentForm];

    console.log("[debug] undefined form ? - ", {
      mapFiedFamilyToForm,
      fieldName,
      currentForm,
      form,
    });
    switch (prop) {
      case "frozen":
      case "sdv":
        if (
          fieldFamily === "inclusion" ||
          fieldFamily === "exclusion" ||
          fieldFamily === "biologicalExamination" ||
          fieldFamily === "AntecedentExamination" ||
          fieldFamily === "SignesExamination" ||
          fieldFamily === "TraitementsExamination"
        ) {
          let fieldToUpdate = {};
          if (values[fieldFamily] !== undefined) {
            fieldToUpdate = {
              ...values[fieldFamily][fieldName],
              [prop]: true,
            };
          } else {
            fieldToUpdate = {
              ...values[currentForm][fieldName],
              [prop]: true,
            };
          }
          const body = {
            field: fieldToUpdate,
            visitNumber: visit,
          };
          body.fieldFamily = fieldName;
          body.fieldCategorie = fieldFamily;
          updateVisitAsync(selectedVisit._id, body);
        } else if (
          currentForm === "dateVisite" ||
          currentForm === "visitInformations"
        ) {
          const fieldToUpdate = {
            ...values[fieldName],
            [prop]: true,
          };
          const body = {
            field: fieldToUpdate,
            visitNumber: visit,
          };
          body.fieldFamily = fieldName;
          updateVisitAsync(selectedVisit._id, body);
        } else {
          const fieldToUpdate = {
            ...values[fieldName],
            [prop]: true,
          };
          const body = {
            field: fieldToUpdate,
            visitNumber: visit,
          };
          body.fieldFamily = fieldName;
          updateVisitAsync(selectedVisit._id, body);
        }
        break;
      case "value":
        setIsModalVisible(false);
        const val = form.getFieldValue(fieldName);
        console.log("[debug] disappearing field - ", { val, fieldName });
        let field = {};
        console.log("form", form);
        
        console.log("currentForm", currentForm);
        if (
          (fieldFamily === "TraitementsExamination" ||
            fieldFamily === "SignesExamination" ||
            fieldFamily === "AntecedentExamination") &&
          fieldName !== "MC"
        ) {
          let dataToAdd = [];

          if (values[currentForm][fieldName].value !== undefined && values[currentForm][fieldName].value === "Non") {
            let vals = form.getFieldValue(fieldName);
            field = {
              ...values[currentForm][fieldName],
              value: vals,
              readOnly: true,
            };
            const body = {
              field,
              visitNumber: visit,
            };
            body.fieldFamily = fieldName;
            body.fieldCategorie = fieldFamily;
            updateVisitAsync(selectedVisit._id, body);
          } else {
            [fieldName, `${fieldName}dateDebut`, `${fieldName}dateFin`].map(
              async (item) => {
                let vals = form.getFieldValue(item);
                // console.log(values[currentForm][item].value)
                // debugger
                if (values[currentForm][item].value !== undefined) {
                  field = {
                    ...values[currentForm][item],
                    value: vals,
                    readOnly: true,
                  };
                } else {
                  field = {
                    ...emptyValues[currentForm][item],
                    value: vals,
                    sdv: false,
                    query: false,
                    frozen: false,
                    readOnly: true,
                  };
                }
                const body = {
                  field,
                  visitNumber: visit,
                };
                body.fieldFamily = item;
                body.fieldCategorie = currentForm;
                dataToAdd.push(body);
              }
            );
            updateVisitAsync(selectedVisit._id, dataToAdd);
          }
        } else if (fieldFamily === "biologicalExamination") {
          field = {
            ...values[currentForm][fieldName],
            value: val,
            readOnly: true,
          };
          const body = {
            field,
            visitNumber: visit,
          };
          body.fieldFamily = fieldName;
          body.fieldCategorie = currentForm;
          updateVisitAsync(selectedVisit._id, body);
        } else {
          let fieldToUpdate = {};
          console.log(values[fieldName]);
          if (values[fieldName] === null) {
            fieldToUpdate = {
              ...emptyValues[fieldName],
              value: val,
              readOnly: true,
            };
          } else if (values[fieldName]) {
            fieldToUpdate = {
              ...values[fieldName],
              value: val,
              readOnly: true,
            };
          } else {
            fieldToUpdate = {
              ...values[currentForm][fieldName],
              value: val,
              readOnly: true,
            };
          }
          const body = {
            field: fieldToUpdate,
            visitNumber: visit,
          };
          body.fieldFamily = fieldName;
          updateVisitAsync(selectedVisit._id, body);
          // if(fieldName ===  "MC" && val == "Oui"){
          //   debugger
          //   setValues({...values,[currentForm] : {
          //     ...values.antecedents,
          //     ["IDM"] : emptyValues[currentForm]["IDM"],
          //     [`${"IDM"}dateDebut`] : emptyValues[currentForm][`${"IDM"}dateDebut`],
          //     [`${"IDM"}dateFin`] : emptyValues[currentForm][`${"IDM"}dateFin`],
          //   }})
          //   console.log(values);
          //   debugger
          // }
        }

        break;
      case "cancel":
        // NOTE FIRST 2 TESTS ARE FOR TIMEPICKER && datetime picker (They accept moment DateTime as a value)
        switch (fieldName) {
          case "sampleTime":
            const newVals = values.bloodSamples.map((e) => {
              if (e[selectedBloodSample]) {
                e[fieldName].readOnly = true;
              }
              return e;
            });
            setValues({ ...values, [bloodSamples]: newVals });
            break;
          default:
            if (fieldFamily === "visitInformations") {
              if (fieldName === "transport") {
                changeProp(fieldFamily, fieldName, "readOnly", !value);
              } else {
                form.setFieldsValue({
                  [fieldName]: {
                    date: selectedVisit[fieldName].value.date,
                    time: moment(selectedVisit[fieldName].value.time),
                  },
                });
              }
            } else if (
              fieldFamily === "biologicalExamination" ||
              fieldFamily === "TraitementsExamination" ||
              fieldFamily === "SignesExamination" ||
              fieldFamily === "AntecedentExamination"
            ) {
              const newValsToArray = Object.keys(values[currentForm]).map(
                (key) => {
                  return values[currentForm][key];
                }
              );
              const newVals = newValsToArray.map((e) =>
                e.name === fieldName
                  ? { ...e, readOnly: true, value: "Non" }
                  : e
              );

              const newValusTwo = (array, key) => {
                return array.reduce((obj, item) => {
                  obj[item[key]] = item;
                  return obj;
                }, {});
              };
              onUpdateValidation(fieldName, value === false ? "Non" : "Oui");
              setValues({
                ...values,
                [currentForm]: newValusTwo(newVals, "name"),
              });
            } else {
              changeProp(fieldFamily, fieldName, "readOnly", !value);
              // if (fieldFamily === "inclusion") {
              //   console.log(selectedVisit[fieldFamily][fieldName]);
              //   debugger
              //   inclusionForm.setFieldsValue({
              //     [fieldName]: selectedVisit[fieldFamily][fieldName],
              //   });
              // } else if (fieldFamily === "exclusion") {
              //   exclusionForm.setFieldsValue({
              //     [fieldName]: selectedVisit[fieldFamily][fieldName],
              //   });
              // } else {
              //   debugger
              //   form.setFieldsValue({
              //     [fieldName]: selectedVisit[fieldName].value,
              //   });
              // }
            }
        }
        // if (fieldFamily !== "bloodSample") {
        //   changeProp(fieldFamily, fieldName, "readOnly", !value);
        // }
        break;
      case "readOnly":
        if (
          fieldFamily === "biologicalExamination" ||
          fieldFamily === "TraitementsExamination" ||
          fieldFamily === "SignesExamination" ||
          fieldFamily === "AntecedentExamination"
        ) {
          const newValsToArray = Object.keys(values[currentForm]).map((key) => {
            return values[currentForm][key];
          });
          const newVals = newValsToArray.map((e) =>
            e.name === fieldName ? { ...e, readOnly: false } : e
          );

          const newValusTwo = (array, key) => {
            return array.reduce((obj, item) => {
              obj[item[key]] = item;
              return obj;
            }, {});
          };
          setValues({ ...values, [currentForm]: newValusTwo(newVals, "name") });
        } else {
          changeProp(fieldFamily, fieldName, prop, value);
        }
        break;
      case "query":
        let fieldToSelect = selectedVisit[fieldName];
        if (
          fieldFamily === "biologicalExamination" ||
          fieldFamily === "TraitementsExamination" ||
          fieldFamily === "SignesExamination" ||
          fieldFamily === "AntecedentExamination"
        ) {
          fieldToSelect = selectedVisit[currentForm][fieldName];
        } else if (fieldFamily === "inclusion" || fieldFamily === "exclusion") {
          fieldToSelect = selectedVisit[fieldFamily][fieldName];
        }
        selectField({ ...fieldToSelect, fieldFamily });
        setOpenQueryModal(true);
        break;
      default:
        if (
          currentForm === "antecedents" ||
          currentForm === "signes" ||
          currentForm === "traitements"
        ) {
          onUpdateValidation(fieldFamily, fieldName);
          const newValsToArray = Object.keys(values[currentForm]).map((key) => {
            return values[currentForm][key];
          });
          console.log("newValsToArray", newValsToArray);
          const newVals = newValsToArray.map((e) =>
            e.name === fieldFamily ? { ...e, value: fieldName } : e
          );
          console.log("newVals", newVals);
          const newValusTwo = (array, key) => {
            return array.reduce((obj, item) => {
              obj[item[key]] = item;
              return obj;
            }, {});
          };
          console.log(values[currentForm][`${fieldFamily}dateDebut`]);
          setValues({
            ...values,
            [currentForm]: {
              ...newValusTwo(newVals, "name"),
              [`${fieldFamily}dateDebut`]: emptyValues[currentForm][
                `${fieldFamily}dateDebut`
              ],
              [`${fieldFamily}dateFin`]: emptyValues[currentForm][
                `${fieldFamily}dateFin`
              ],
            },
          });
        } else {
          changeProp(fieldFamily, fieldName, prop, value);
          updateVisitAsync(selectedVisit._id, {
            field: {
              ...values[fieldName],
              [prop]: value,
            },
            // bloodSampleKey: values.bloodSamples[0]
            //   ? values.bloodSamples[0].key
            //   : "",
            fieldFamily,
            visitNumber: visit,
          });
        }
    }
  };

  const showStep = () => {
    if (visit === "j0" || visit === "j7") {
      switch (current) {
        case 0:
          return (
            <VisitInformations
              onUpdate={handleUpdateField}
              values={values}
              form={visitInformationsForm}
              visit={visit}
            />
          );
        case 1:
          return (
            <Inclusion
              inclusionForm={inclusionForm}
              exclusionForm={exclusionForm}
              dateVisiteForm={dateVisiteForm}
              values={values}
              onUpdate={handleUpdateField}
            />
          );
        case 2:
          return (
            <Examinations
              values={values}
              form={examinationsForm}
              onUpdate={handleUpdateField}
            />
          );
        case 3:
          return (
            <AntecedentsExams
              values={values}
              form={antecedentsForm}
              onUpdate={handleUpdateField}
              PC={PC}
              MC={MC}
              ICP={ICP}
              AFA={AFA}
              AVC={AVC}
              MVAP={MVAP}
              DIABETE={DIABETE}
              HA={HA}
              CANCER={CANCER}
              BPCO={BPCO}
              MRC={MRC}
              MDF={MDF}
              DEPRESSION={DEPRESSION}
              DT={DT}
              IDM={IDM}
              PCI={PCI}
              CABG={CABG}
              isAdd={true}
            />
          );
        // case 4:
        //   return <AntecedentsExams values={values} form={examinationsForm} />;
        case 4:
          return (
            <SignesExams
              values={values}
              form={signesForm}
              onUpdate={handleUpdateField}
              RP={RP}
              ER={ER}
              EE={EE}
              TN={TN}
              RTE={RTE}
              ANG={ANG}
              JPV={JPV}
              TBC={TBC}
              RS={RS}
              OP={OP}
              DPN={DPN}
              ORT={ORT}
              CXR={CXR}
            />
          );
        case 5:
          return (
            <TraitementExams
              values={values}
              form={traitementsForm}
              onUpdate={handleUpdateField}
              ICD={ICD}
              BB={BB}
              ACE={ACE}
              ARB={ARB}
              ARN={ARN}
              ARM={ARM}
              IV={IV}
              SGL={SGL}
              DA={DA}
              DTH={DTH}
              ST={ST}
              TA={TA}
            />
          );
        // case 6:
        //   return <DrugForm values={values} form={drugForm} />;
        // case 1:
        //   return (
        //     <div style={{ maxWidth: 600 }}>
        //       <Clinical
        //         onUpdate={handleUpdateField}
        //         showHeightWeight={false}
        //         values={values}
        //         form={clinicalForm}
        //       />
        //     </div>
        //   );
        // case 2:
        //   return (
        //     <div style={{ maxWidth: 700 }}>
        //       <BloodSample
        //         onUpdate={handleUpdateField}
        //         values={values}
        //         form={bloodSampleForm}
        //         nameProps={"firstTube"}
        //       />
        //     </div>
        //   );
        // case 3:
        //   return (
        //     <BloodSamples
        //       bloodSamples={bloodSamples}
        //       visit={visit}
        //       setSelectedBloodSample={setSelectedBloodSample}
        //     />
        //   );
        // case 4:
        //   return (
        //     <div style={{ maxWidth: 700 }}>
        //       <BloodSample
        //         onUpdate={handleUpdateField}
        //         values={values}
        //         form={bloodSampleForm}
        //         nameProps={"thirdTube"}
        //       />
        //     </div>
        //   );
        // case 4:
        //   return (
        //     <div>
        //       {/* {user && user.type === 1 ? (
        //         <Button
        //           onClick={() => setShowAdd(true)}
        //           shape="round"
        //           className="m-top-medium m-bottom-large"
        //           type="primary"
        //           size="medium"
        //           icon={<PlusOutlined />}
        //         >
        //           Ajouter un prélèvement
        //         </Button>
        //       ) : null} */}
        //       <BloodSamples
        //         bloodSamples={bloodSamples}
        //         visit={visit}
        //         setSelectedBloodSample={setSelectedBloodSample}
        //       />
        //       <AddBloodSample
        //         open={showAdd}
        //         handleClose={() => setShowAdd(false)}
        //       />
        //     </div>
        //   );
        default:
          return (
            <div>
              {" "}
              Une erreur inconnue est survenue !{" "}Merci de rafraichir la page. Si le problème persiste veuillez contacter le support.
            </div>
          );
      }
    } else if (visit === "j1") {
      switch (current) {
        case 0:
          return (
            <div style={{ maxWidth: 900 }}>
              <Clinical
                onUpdate={handleUpdateField}
                showHeightWeight={false}
                values={values}
                form={clinicalForm}
                visit={"j1"}
              />
            </div>
          );
        case 1:
          return (
            <div style={{ maxWidth: 900 }}>
              <Questionnaire
                onUpdate={handleUpdateField}
                values={values}
                questionnaireForm={questionnaireForm}
                visit={"j1"}
              />
            </div>
          );
        default:
          return <div />;
      }
    } else if (visit === "j2") {
      switch (current) {
        case 0:
          return (
            <div style={{ maxWidth: 900 }}>
              <Clinical
                onUpdate={handleUpdateField}
                showHeightWeight={false}
                values={values}
                form={clinicalForm}
                visit={"j2"}
                isEdit={true}
              />
            </div>
          );
        case 1:
          return (
            <div style={{ maxWidth: 900 }}>
              <Measure
                onUpdate={handleUpdateField}
                values={values}
                measuresForm={measureForm}
                visit={visit}
              />
            </div>
          );
        case 2:
          return (
            <div style={{ maxWidth: 900 }}>
              <Questionnaire
                onUpdate={handleUpdateField}
                values={values}
                questionnaireForm={questionnaireForm}
                visit={visit}
              />
            </div>
          );
        default:
          return <div />;
      }
    } else {
      // switch (current) {
      //   case 0:
      //     return (
      //       <div style={{ maxWidth: 900 }}>
      //         <Clinical
      //           onUpdate={handleUpdateField}
      //           showHeightWeight={false}
      //           values={values}
      //           form={clinicalForm}
      //           visit={"j2"}
      //         />
      //       </div>
      //     );
      //   case 1:
      //     return (
      //       <div style={{ maxWidth: 900 }}>
      //         <Measure
      //           onUpdate={handleUpdateField}
      //           values={values}
      //           measuresForm={measureForm}
      //           visit={visit}
      //         />
      //       </div>
      //     );
      //   case 2:
      //     return (
      //       <div style={{ maxWidth: 900 }}>
      //         <Questionnaire
      //           onUpdate={handleUpdateField}
      //           values={values}
      //           questionnaireForm={questionnaireForm}
      //           visit={visit}
      //         />
      //       </div>
      //     );
      //   default:
      //     return <div />;
      // }
    }
  };

  useEffect(() => {
    if (selectedVisit) {
      if (visit === "j0" || visit === "j7") {
        setValues({
          ...selectedVisit,
        });
        const keys = Object.keys(selectedVisit);
        // eslint-disable-next-line array-callback-return
        keys.map((key) => {
          const field = selectedVisit[key];
          switch (key) {
            case "consentDate":
            case "inclusionDate":
            case "transport":
            case "transportAutre":
            case "initial":
              visitInformationsForm.setFieldsValue({
                [key]: selectedVisit[key].value,
              });
              break;
            // visitInformationsForm.setFieldsValue({
            //   [key]: {
            //     date: selectedVisit[key].value
            //       ? selectedVisit[key].value.date
            //       : null,
            //     time: selectedVisit[key].value
            //       ? moment(selectedVisit[key].value.time)
            //       : null,
            //   },
            // });
            // break;
            case "inclusion":
              Object.keys(field).forEach((key) =>
                inclusionForm.setFieldsValue({ [key]: field[key].value })
              );
              break;
            case "dateVisite":
              dateVisiteForm.setFieldsValue({ [key]: field.value });
              break;
            case "exclusion":
              Object.keys(field).forEach((key) =>
                exclusionForm.setFieldsValue({ [key]: field[key].value })
              );
              break;
            case "biologicalExamination":
              Object.keys(field).forEach((key) => {
                examinationsForm.setFieldsValue({
                  [key]: field[key].value,
                });
              });
              break;
            case "antecedents":
              Object.keys(field).forEach((key) => {
                if (field[key].value === "Oui") {
                  onUpdateValidation(key, field[key].value);
                }
                antecedentsForm.setFieldsValue({ [key]: field[key].value });
              });
              break;
            case "signes":
              Object.keys(field).forEach((key) => {
                if (field[key].value === "Oui") {
                  onUpdateValidation(key, field[key].value);
                }
                signesForm.setFieldsValue({ [key]: field[key].value });
              });

              break;
            case "traitements":
              Object.keys(field).forEach((key) => {
                if (field[key].value === "Oui") {
                  onUpdateValidation(key, field[key].value);
                }
                traitementsForm.setFieldsValue({
                  [key]: field[key].value,
                });
              });
              break;
            // case "bloodSamples":
            //   console.log(
            //     "sampleTime.value",
            //     bloodSampleForm.getFieldValue("sampleTime")
            //   );
            //   bloodSampleForm.setFieldsValue({
            //     sampleTime: sampleTime ? moment(sampleTime.value) : null,
            //     firstTube: firstTube.value,
            //     secondTube: secondTube ? secondTube.value : "",
            //   });
            //   break;
            case "dateNextRDVCardio":
              drugForm.setFieldsValue({ [key]: field.value });
              break;
            case "dateNextRDVCardioHeure":
              drugForm.setFieldsValue({ [key]: field.value });
              break;
            default:
              break;
          }
        });
      } else if (visit === "j2") {
        setValues({ ...selectedVisit });
        const keys = Object.keys(selectedVisit);
        // eslint-disable-next-line array-callback-return
        keys.map((key) => {
          const field = selectedVisit[key];
          switch (key) {
            case "dateVisite":
              clinicalForm.setFieldsValue({
                 [key]: field.value 
              });
              break;
            case "beats":
            case "remis":
            case "finished":
            case "recover":
            case "weight":
            case "height":
            case "systolique":
            case "diastolique":
            case "systoliqueRight":
            case "diastoliqueRight":
            case "rapportLVEF":
            case "finishedDoctor":
            case "recoverDoctor":
            case "transport":
            case "transportAutre":
            case "InsuffisanceCardiaque":
            case "cliniques":
              measureForm.setFieldsValue({ [key]: selectedVisit[key].value });
              break;
            // case "cliniques":
            //   console.log(selectedVisit[key].value)
            //   debugger
            //   const toCheckboxList = selectedVisit[key].value.map(e => ({
            //     "label" : e,
            //     "value": e,
            //   }))

            //   clinicalForm.setFieldsValue({ [key]: toCheckboxList });
            //   break
            default:
              break;
          }
        });
      } else {
        setValues({ ...selectedVisit });
        const keys = Object.keys(selectedVisit);
        // eslint-disable-next-line array-callback-return
        keys.map((key) => {
          switch (key) {
            case "dateInvisigateur":
            case "dateVisiteV1":
              clinicalForm.setFieldsValue({
                [key]: selectedVisit[key].value
              });
              break;
            case "beats":
            case "remis":
            case "finished":
            case "recover":
            case "weight":
            case "height":
            case "nexus":
            case "systolique":
            case "diastolique":
            case "initial":
              // case "systoliqueRight":
              // case "diastoliqueRight":
              clinicalForm.setFieldsValue({ [key]: selectedVisit[key].value });
              break;
            default:
              break;
          }
        });
      }
      // setBloodSamples(selectedVisit.bloodSamples);
    }
  }, [selectedVisit]);

  useEffect(() => {
    if (user && user.type === 1) {
      if (visit === "j1" || visit === "j8") {
        // if (selectedVisit.bloodSamples.length !== 2) {
        //   setShowAddSample(true);
        // } else {
        setShowAddSample(false);
        // }
      } else if (visit === "j2" || visit === "j9") {
        // if (selectedVisit.bloodSamples.length !== 1) {
        //   setShowAddSample(true);
        // } else {
        setShowAddSample(false);
        // }
      }
      // else if (selectedVisit.bloodSamples.length !== 12) {
      //   //setShowAddSample(true);
      // }
      else {
        //setShowAddSample(false);
      }
    } else {
      setShowAddSample(false);
    }
  }, [selectedVisit]);

  if (!selectedVisit) return <div />;

  return  (
    <div className="section-content">
      <HeadBreadCrumb
        firstSectionName="Visites"
        secondSectionName={parseVisitName(selectedVisit.visitNumber)}
        thirdSectionName={
          // user && user.type === 1
          //   ? `${selectedVisit.patient.lastName.value} ${selectedVisit.patient.firstName.value}`
          //   :
          selectedVisit.patient.customId
        }
        handleGoBack={handleGoBack}
      />
      <Spin spinning={contentIsLoading}>
        <Row>
          {/* <Col span={18}> */}
          <Card className="formWidth">
            <Steps
              current={current}
              onChange={setCurrent}
              size="small"
              className="m-bottom-medium"
            >
              {/* <Step title="Nombre de fluides" /> */}
              {visit === "j0" || visit === "j7" ? (
                <>
                  <Step title="Informations" />
                  <Step title="Inlcusion & Exclusion" />
                  <Step title="Résultats biologiques" />
                  <Step title="Antécedents Médicaux" />
                  <Step title="Signes et Symptomes" />
                  <Step title="Traitements" />
                  {/* <Step title="Rendez-vous" /> */}
                </>
              ) : visit === "j1" ? (
                <>
                  <Step title="Informations" />
                  <Step title="Questionnaire" />
                </>
              ) : (
                <>
                  <Step title="Informations" />
                  <Step title="Paramètres de l’échocardiographie" />
                  <Step title="Questionnaire" />
                </>
              )}
              {/* <Step title="Prélèvements" /> */}
            </Steps>
            <div className="m-top-medium" style={{ padding: 24 }}>
              {showStep()}
            </div>
          </Card>
          {/* </Col>
          <Col span={6}>
            {listVisitsByPatient &&
              listVisitsByPatient.map((item, i) => (
                <Timeline.Item key={audit._id}>
                  <Tree treeData={prepareTree(item, i)} />
                </Timeline.Item>
              ))}
          </Col> */}
        </Row>
      </Spin>
      <QueryModal
        call={selectedVisit}
        open={openQueryModal}
        field={selectedField}
        handleClose={() => setOpenQueryModal(false)}
      />
      {/* <>
        {selectedBloodSample && current !== 2 && (
          <Modal
            width={640}
            title="Prélèvements"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <BloodSample
              onUpdate={handleUpdateField}
              values={values}
              form={bloodSampleForm}
              nameProps={
                selectedBloodSample ? selectedBloodSample : "firstTube"
              }
            />
          </Modal>
        )}
      </> */}
    </div>
  );
};

SelectedVisit.propTypes = {
  contentIsLoading: PropTypes.bool.isRequired,
  visit: PropTypes.string.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  selectedVisit: PropTypes.object.isRequired,
  updateVisitAsync: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getAllVisitsByPatientAsync: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  contentIsLoading: state.loading.contentIsLoading,
  selectedVisit: state.visits.selectedVisit,
  user: state.auth.user,
  listVisitsByPatient: state.visits.visitsPatient,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateVisitAsync,
      getAllVisitsByPatientAsync,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(SelectedVisit);
