
import { message } from 'antd';
import * as CallAPI from '../../../api/call';
import { sessionExpired } from '../../auth/actions';
import { setContentIsLoading } from '../../loading/actions';

export const SET_CALLS_LIST = 'SET_CALLS_LIST';
export const ADD_CALL = 'ADD_CALL';
export const SELECT_CALL = 'SELECT_CALL';
export const UPDATE_CALL = 'UPDATE_CALL';
// export const PATIENT_CALL = 'PATIENT_CALL';

export const setCallsList = value => ({
    type: SET_CALLS_LIST,
    payload: value,
});

export const selectCall = value => ({
    type: SELECT_CALL,
    payload: value,
});

export const addCall = value => ({
    type: ADD_CALL,
    payload: value,
});


export const updateCall = value => ({
    type: UPDATE_CALL,
    payload: value,
});

export const getAllCallsAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await CallAPI.getAll(body.visit);
        dispatch(setCallsList([]));
        dispatch(setCallsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const createCallAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await CallAPI.create(body);
        dispatch(addCall(res.data));
        message.success('Call ajoutée avec succès');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

export const selectCallAsync = id => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await CallAPI.getById(id);
        dispatch(selectCall(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const updateCallAsync = (id, body) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await CallAPI.update(id, body);
        dispatch(selectCall(res.data));
        dispatch(updateCall(res.data));
        message.success('Modification effectuée');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};