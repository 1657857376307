import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Card, Button, Table, message,
} from 'antd';
import PropTypes from 'prop-types';
import AddAntecedent from './add';
import './styles.css';
import FieldActions from '../FieldActions';
import { updatePatientAsync } from '../../redux/patients/actions';
import moment from 'moment';


const addInputs = [
    {
        label: 'Pathologie',
        name: 'pathology',
        required: true,
        type: 'input',
    },
    {
        label: 'Date de début de la maladie',
        name: 'date',
        required: true,
        type: 'date',
        placeholder: 'e.g: 01/01/2000',
    },
    {
        label: 'Observation',
        name: 'observation',
        required: false,
        type: 'input',
    },
];


export const medicalAntecedentsColumns = [
    {
        title: <b> Nature </b>,
        dataIndex: 'nature',
        key: 'nature',
    },
    {
        title: <b> Pathologie </b>,
        dataIndex: 'pathology',
        key: 'pathology',
    },
    {
        title: <b> Date </b>,
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: <b> Observation </b>,
        dataIndex: 'observation',
        key: 'observation',
    },

];

const MedicalAntecedents = ({
    values,
    onChange,
    user,
    updatePatientAsync,
    selectedPatient,
    showForm,
    setShowForm,
}) => {
    const onDelete = (row) => {
        const newArr = values.medicalAntecedents.filter(
            item => item.key !== row.key,
        );
        onChange('medicalAntecedents', newArr);
        message.success('Antécédent supprimé avec succès');
    };


    const onAdd = (antecedent) => {
        antecedent.date = moment(antecedent.date._d).format('L');
        antecedent.nature = 'Antécédent médical';
        antecedent.key = Math.random().toString();
        const newAntecedents = [...values.medicalAntecedents, antecedent];
        onChange('medicalAntecedents', newAntecedents);
        message.success('Antécédent ajouté avec succès');
    };

    const onUpdate = (fieldFamily, _, prop, value) => {
        updatePatientAsync(selectedPatient._id, {
            fieldFamily,
            field: {
                ...values[fieldFamily],
                [prop]: value,
                value: '!',
            },
            visit: 1,
        });
    };


    if (user && user.type === 2 && medicalAntecedentsColumns.length === 5) {
        medicalAntecedentsColumns.pop();
    }
    console.log('values.medicalAntecedents', values.medicalAntecedents);
    return (
        <div>
            {user && user.type === 1 ? (
                <Card
                    title="Antécédents"
                    className="add-antecedent-card"
                >
                    <AddAntecedent
                        onAdd={onAdd}
                        buttonLabel="Ajouter l'antécédent"
                        radioSelection={[]}
                        inputs={addInputs}
                        extraRadioInputs={[]}
                        disabled={values.medicalAntecedentsProps.frozen}
                        radioTitle="Antédecents"
                        lengthArrayData={values.medicalAntecedents}
                        showForm={showForm}
                        setShowForm={setShowForm}
                    />
                </Card>
            ) : (
                <div
                    className="flex m-bottom-medium"
                    style={{ justifyContent: 'center' }}
                >
                    <FieldActions
                        field={values.medicalAntecedentsProps}
                        fieldFamily="medicalAntecedentsProps"
                        onUpdate={onUpdate}
                    />
                </div>
            )}

            <div>
                <Table
                    dataSource={values.medicalAntecedents}
                    columns={user && user.type === 1 ? [...medicalAntecedentsColumns, {
                        title: <b> Actions </b>,
                        key: 'action',
                        render: a => (
                            <Button
                                disabled={values.medicalAntecedentsProps.frozen}
                                danger
                                shape="round"
                                onClick={() => {
                                // console.log({ a, b: values.medicalAntecedents });
                                    onDelete(a);
                                }}
                            >
                                Supprimer
                            </Button>
                        ),
                    }] : medicalAntecedentsColumns}
                    locale={{ emptyText: 'Aucun antécédent' }}
                />
            </div>
        </div>
    );
};

MedicalAntecedents.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    user: PropTypes.object,
    updatePatientAsync: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object.isRequired,
};

MedicalAntecedents.defaultProps = {
    user: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    selectedPatient: state.patients.selectedPatient,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updatePatientAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(MedicalAntecedents);
