import React, { useState, useEffect } from "react";
import { Form, Button, Typography, Divider } from "antd";
import PropTypes from "prop-types";
import "./styles.css";
import DynamicField from "../../components/DynamicField/index";

const { Title } = Typography;

const AdverseForm = ({ form, values, onFinish, onUpdate, adverseEvent }) => {
  const [extraIntensity, setExtraIntensity] = useState(false);
  const [isDeath, setIsDeath] = useState(false);

  const handleValuesChange = (val) => {
    if (val.evolution && val.evolution === "décès") {
      setIsDeath(true);
    }else{
      setIsDeath(false);
    }
    if (val.intensity && val.intensity === "Grave") setExtraIntensity(true);
    if (val.intensity && val.intensity === "Non grave")
      setExtraIntensity(false);
  };

  useEffect(() => {
    if (adverseEvent) {
      if(adverseEvent.evolution.value === "décès") setIsDeath(true)
      if (adverseEvent.intensity.value === "Grave") setExtraIntensity(true);
      if (adverseEvent.intensity.value === "Non grave")
        setExtraIntensity(false);
    }
  }, [adverseEvent]);

  return (
    <Form
      style={{ marginTop: 30, display: "flex" }}
      onFinish={onFinish}
      form={form}
      onValuesChange={handleValuesChange}
    >
      <div style={{ padding: "0 34px", marginRight: "10%" }}>
        {[
          "dateVisite",
          "description",
          "startDate",
          "endDate",
          "causality",
          "intensity",
          "intensityLevel",
          "periodicity",
          "evolution",
          "traitement",
          "eligiblePatient",
          "commentaire",
        ].map((k) => {
          return (
            <>
              <DynamicField
                key={k}
                field={values[k]}
                fieldFamily="adverse"
                onUpdate={onUpdate}
              />
              {( isDeath && k==='evolution' ) && <DynamicField
                key={"deathDescription"}
                field={values["deathDescription"]}
                fieldFamily="adverse"
                onUpdate={onUpdate}
              />}
            </>
          );
        })}
        {adverseEvent ? null : (
          <Form.Item>
            <Button type="primary" className="center-button" htmlType="submit">
              Envoyer
            </Button>
          </Form.Item>
        )}
      </div>
    </Form>
  );
};

AdverseForm.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  adverseEvent: PropTypes.object.isRequired,
};

export default AdverseForm;
