import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Divider, Typography } from "antd";
import DynamicField from "../DynamicField";
import "./styles.css";

const { Text } = Typography;
const titles = ["PLAX", "A4C", "A4C +Mmode", "A2C", "A4C+PW", "A4C+PWTD"];
const Measure = ({
  measuresForm,
  values,
  onUpdate,
  visit,
  isAdd,
  listMessage,
}) => {
  return (
    <div className="inclusionContainer">
      <Card title="Tableau de mesures" className="add-antecedent-card">
        <Form
          style={{ marginTop: 30 }}
          form={measuresForm}
          className="inclusion-form"
        >
          <div>
            <div className="ant-col ant-form-item-label m-bottom-small">
              <strong> </strong>
            </div>
            <DynamicField
              field={values.cliniques}
              onUpdate={onUpdate}
              fieldFamily="measures"
            />
          </div>
          <Card title={titles[0]} className="add-antecedent-card"></Card>
          {[
            "IVSd",
            "LVIDd",
            "LVIDs",
            "LVPWd",
            "LVEDV",
            "LVESV",
            "LVEF",
            "LAESV",
            "RVIDd",
            "RAarea",
            "TAPSE",
            "TR",
            "LVEDVA2C",
            "LVESVA2C",
            "LVEFA2C",
            "LAESVA2C",
            "MVEA4C",
            "MVAA4C",
            "dect",
            "lateral",
            "septal",
          ].map((k, index) => (
            <>
              {index === 4 ? (
                <Card title={titles[1]} className="add-antecedent-card"></Card>
              ) : index === 10 ? (
                <Card title={titles[2]} className="add-antecedent-card"></Card>
              ) : index === 12 ? (
                <Card title={titles[3]} className="add-antecedent-card"></Card>
              ) : index === 16 ? (
                <Card title={titles[4]} className="add-antecedent-card"></Card>
              ) : index === 19 ? (
                <Card title={titles[5]} className="add-antecedent-card"></Card>
              ) : null}
              {isAdd ? (
                <DynamicField
                  key={k}
                  field={values[k]}
                  onUpdate={onUpdate}
                  fieldFamily="measures"
                  radio={true}
                />
              ) : (
                <DynamicField
                  key={k}
                  field={values[k]}
                  onUpdate={onUpdate}
                  fieldFamily="measures"
                  //radio={true}
                />
              )}

              <Divider style={{ margin: "12px 0" }} />
            </>
          ))}
          {listMessage &&
            listMessage.length > 0 &&
            listMessage.map((e) => (
              <>
                <strong style={{ color: "red" }}>{e.message}</strong>
                <br />
              </>
            ))}
        </Form>
      </Card>
    </div>
  );
};

Measure.propTypes = {
  measuresForm: PropTypes.object.isRequired,
  visit: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

Measure.defaultProps = {
  onUpdate: () => {},
};

export default Measure;
