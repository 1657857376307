import React, { useState, useEffect } from 'react';
import {
    Avatar, List, Input, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Title } = Typography;

const filter = (query, data) => {
    const newQuery = query
        .replace(/[^a-z0-9]/gi, '')
        .split('')
        .join('.*');
    return data.filter((item) => {
        const regex = new RegExp(newQuery, 'i');
        return (
            regex.test(item.customId)
        );
    });
};

const PatientsList = ({ data, title, onSelect }) => {
    const [currentItems, setCurrentItems] = useState([]);

    const handleSearch = (searchValue) => {
        const filtredData = filter(searchValue, data);
        setCurrentItems(filtredData);
    };


    useEffect(() => {
        if (data.length && !currentItems.length) {
            setCurrentItems(data);
        }
    }, [data]);


    return (
        <div>
            <Search
                placeholder="Chercher par code des volontaires"
                allowClear
                className="m-bottom-medium"
                style={{ maxWidth: '50%' }}
                onChange={e => handleSearch(e.target.value)}
            />
            <Title level={4} className="center-text m-top-medium">
                {title}
            </Title>
            <List
                locale={{ emptyText: 'Aucun volontaire' }}
                itemLayout="horizontal"
                dataSource={currentItems}
                renderItem={item => (
                    <List.Item className="patient-list-item " key={item._id} onClick={() => onSelect(item)}>
                        <List.Item.Meta
                            avatar={<Avatar icon={<UserOutlined />} />}
                            title={`${item.customId}`}
                        />
                    </List.Item>
                )}
            />
        </div>

    );
};


PatientsList.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default PatientsList;
