import axios from 'axios';
import { API_CSV, API_REQ_HEADER } from './config';


const exportCsv = async (body) => {
    const res = await axios.post(API_CSV, body, { ...API_REQ_HEADER, responseType: 'blob' });
    return res.data;
};

export default exportCsv;
