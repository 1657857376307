import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import loading from './loading/reducer';
import auth from './auth/reducer';
import patients from './patients/reducer';
import visits from './visits/reducer';
import queries from './query/reducer/index';
import adverse from './adverse/reducer/index';
import documents from './documents/reducer';
import calls from './calls/reducer';


export default createStore(
    combineReducers({
        loading,
        auth,
        patients,
        visits,
        queries,
        adverse,
        documents,
        calls,
    }),
    composeWithDevTools(
        applyMiddleware(thunk),
    ),
);
