import axios from "axios";
import { API_DOC, API_REQ_HEADER } from "./config";

export const loadImage = (fileName) =>
  `${API_DOC}/load?file=${fileName}`;

export const getAll = async () => {
  const res = await axios.get(API_DOC, API_REQ_HEADER);
  return res.data;
};
