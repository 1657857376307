import React from "react";
import PropTypes from "prop-types";
import { Modal, Tree, Timeline, Table } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectAudit } from "../../redux/patients/actions";
import {
  formatDateTimeFromIso,
  getTimeFromISODate,
} from "../../helpers/functions";
import { medicalAntecedentsColumns } from "../medicalRecord/medicalAntecedents";
import { treatmentsColumns } from "../medicalRecord/treatments";
import moment from "moment";

const showValue = (audit) => {
  switch (audit.fieldFamily) {
    case "medicalAntecedents":
      return (
        <Table
          columns={medicalAntecedentsColumns}
          dataSource={audit.value}
          locale={{ emptyText: "Aucun antécédent" }}
        />
      );
    case "treatments":
      return (
        <Table
          columns={treatmentsColumns}
          dataSource={audit.value}
          locale={{ emptyText: "Aucun traitement" }}
        />
      );
    case "visitInformations":
      if (
        audit.fieldName === "lastDrink" ||
        audit.fieldName === "lastMeal" ||
        audit.fieldName === "admissionDate" ||
        audit.fieldName === "consultationDate"
      )
        return `${audit.value.date} - ${getTimeFromISODate(audit.value.time)}`;
      break;
    default:
      return audit.value === false
        ? "Non"
        : audit.value === true
        ? "Oui"
        : typeof audit.value !== "object"
        ? audit.value
        : `${audit.value.date} - ${getTimeFromISODate(audit.value.time)}`;
  }
  return audit;
};

const prepareTree = (c, i) => {
  const res = [];
  res.push({
    title: (
      <>
        <b>{"Date : "}</b>
        {formatDateTimeFromIso(c.createdAt)}
      </>
    ),
    key: `${i}-${c.date}`,
    children: [
      {
        title: (
          <>
            <b>{i === 0 ? "Valeur initiale : " : "Nouvelle valeur : "}</b>
            {showValue(c)}
            {c.nurse && (
              <>
                <br />
                {`${c.nurse.firstName
                  .charAt(0)
                  .toUpperCase()}${c.nurse.lastName.charAt(0).toUpperCase()}`}
                <br />
                {`${c.nurse.phoneNumber}`}
                <br/>
                {`${moment(c.createdAt).format('DD/MM/YYYY HH:mm')}`}
              </>
            )}
            {c.doctor && (
              <>
                <br />
                {`${c.doctor.firstName
                  .charAt(0)
                  .toUpperCase()}${c.doctor.lastName.charAt(0).toUpperCase()}`}
                <br />
                {`${c.doctor.phoneNumber}`}
                <br/>
                {`${moment(c.createdAt).format('DD/MM/YYYY HH:mm')}`}
              </>
            )}
            {c.cro && (
              <>
                <br />
                {`${c.cro.firstName
                  .charAt(0)
                  .toUpperCase()}${c.cro.lastName.charAt(0).toUpperCase()}`}
                <br />
                {`${c.cro.phoneNumber}`}
                <br/>
                {`${moment(c.createdAt).format('DD/MM/YYYY HH:mm')}`}
              </>
            )}
          </>
        ),
        key: `${i}-${c.value}`,
      },
    ],
  });
  return res;
};

const AuditTrial = ({ selectedAudit, selectAudit }) => (
  <Modal
    width={820}
    visible={selectedAudit}
    title="Historique des modifications"
    footer={null}
    onCancel={() => selectAudit(null)}
  >
    {selectedAudit &&
      selectedAudit.map((audit, i) => (
        <Timeline.Item key={audit._id}>
          <Tree treeData={prepareTree(audit, i)} />
        </Timeline.Item>
      ))}
  </Modal>
);

AuditTrial.propTypes = {
  selectedAudit: PropTypes.array,
  selectAudit: PropTypes.func.isRequired,
};

AuditTrial.defaultProps = {
  selectedAudit: null,
};

const stateToProps = (state) => ({
  selectedAudit: state.patients.selectedAudit,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectAudit,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(AuditTrial);
