import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Typography, Divider } from "antd";
import DynamicField from "../DynamicField";
import patientAdditionEmptyVals from "../../sections/Patients/add/emptyValues";
import emptyValues from "../../sections/Visit/add/emptyValues";

const { Title } = Typography;

const title = [
  "1. Maladie coronarienne",
  "2. Pontage coronarien",
  "3. Intervention coronarienne percutanée",
  "4. Antécédents de fibrillation auriculaire",
  "5. AVC antérieur",
  "6. Maladie vasculaire artérielle périphérique",
  "7. Diabète",
  "8. Hypertension artérielle",
  "9. Cancer",
  "10.  BPCO (Broncho Pneumopathie Chronique Obstructive)",
  "11. Maladie rénale chronique",
  "12. Maladie du foie",
  "13. Dépression",
  "14. Dysfonctionnement thyroïdien",
];

const AntecedentsExams = (props) => {
  return (
    <Form
      style={{ marginTop: 30 }}
      form={props.form}
      className="biological-examination-form"
    >
      <Card title="Antécedents Médicaux" className="add-antecedent-card">
        <Title style={{ marginBottom: 14 }} level={5}>
          1. Maladie coronarienne
        </Title>
        <div>
          {["MC"].map((k) => (
            <>
              <DynamicField
                key={k}
                field={props.values.antecedents[k]}
                onUpdate={props.onUpdate}
                fieldFamily="AntecedentExamination"
                radio={true}
              />
              {props[k] === "Oui" && props["isAdd"] ? (
                <>
                  {["IDM", "PCI", "CABG"].map((j) => {
                    console.log("[debug] --key", k);
                    console.log(props.values.antecedents[j]);
                    console.log(typeof props.values.antecedents[j]);
                    console.log(props.values.antecedents[`${j}dateFin`]);
                    return (
                      <>
                        <DynamicField
                          key={j}
                          field={props.values.antecedents[j] === undefined ? emptyValues.antecedents[j] :props.values.antecedents[j] }
                          onUpdate={props.onUpdate}
                          fieldFamily="AntecedentExamination"
                          radio={true}
                        />
                        {props[j] === "Oui" ? (
                          <>
                            <DynamicField
                              key={props.values.antecedents[j] === undefined ? emptyValues.antecedents[j] : props.values.antecedents[j] }
                              field={props.values.antecedents[`${j}dateDebut`]}
                              onUpdate={props.onUpdate}
                              fieldFamily="AntecedentExamination"
                            />
                            <DynamicField
                              key={props.values.antecedents[j] === undefined ? emptyValues.antecedents[j] : props.values.antecedents[j]}
                              field={props.values.antecedents[`${j}dateFin`]}
                              onUpdate={props.onUpdate}
                              fieldFamily="AntecedentExamination"
                            />
                          </>
                        ) : null}
                      </>
                    );
                  })}
                </>
              ) : null}
              <Divider />
            </>
          ))}
        </div>
        <div>
          {[
            "PC",
            "ICP",
            "AFA",
            "AVC",
            "MVAP",
            "DIABETE",
            "HA",
            "CANCER",
            "BPCO",
            "MRC",
            "MDF",
            "DEPRESSION",
            "DT",
          ].map((k, index) => {
            console.log("[debug] --key", k);
            console.log("[debug] --key -index", index);
            console.log(props.values.antecedents[k].value);
            console.log(typeof props.values.antecedents[k].value);
            return (
              <>
                <Title style={{ marginBottom: 14 }} level={5}>
                  {title[index + 1]}
                </Title>
                <DynamicField
                  key={k}
                  field={props.values.antecedents[k]}
                  onUpdate={props.onUpdate}
                  fieldFamily="AntecedentExamination"
                  radio={true}
                />
                {props[k] === "Oui" ||
                props.values.antecedents[k].value === "Oui" ? (
                  <>
                    <DynamicField
                      key={props.values.antecedents[k].name}
                      field={props.values.antecedents[`${k}dateDebut`]}
                      onUpdate={props.onUpdate}
                      fieldFamily="AntecedentExamination"
                    />
                    <DynamicField
                      key={props.values.antecedents[k].name}
                      field={props.values.antecedents[`${k}dateFin`]}
                      onUpdate={props.onUpdate}
                      fieldFamily="AntecedentExamination"
                    />
                  </>
                ) : null}
                <Divider />
              </>
            );
          })}
        </div>

        {/* <div style={{ marginLeft: "4%" }}>
        <Title style={{ marginBottom: 14 }} level={5}>
          Bilan hématologique
        </Title>
        <div>
          {["hemoglobine", "leucocytes", "Plaquettes"].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>
        <Title style={{ marginBottom: 14 }} level={5}>
          Bilan inflammatoire
        </Title>

        <div>
          {["VS", "CRP"].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>
        <Title style={{ marginBottom: 14 }} level={5}>
          Bilan hépatique
        </Title>
        <div>
          {[
            "ASAT",
            "ALAT",
            "Bilirubine totale",
            "Bilirubine conjuguée",
            "Phosphatase alcaline",
            "Réserves alcalines",
          ].map((k) => (
            <DynamicField
              key={k}
              field={
                values.biologicalExamination[k] ||
                patientAdditionEmptyVals.biologicalExamination[k]
              }
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>
        <Title style={{ marginBottom: 14 }} level={5}>
          Bilan Rénal
        </Title>

        <div>
          {["Urée", "Créatinine"].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>

        <div>
          {["Glycémie", "Protides"].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>
        {values.gender.value === "Femme" ? (
          <DynamicField
            key="pregnancyTest"
            field={values.biologicalExamination.pregnancyTest}
            fieldFamily="biologicalExamination"
          />
        ) : null}
      </div> 
    </Card>
    <Card title="Examen Radiologique" className="add-antecedent-card">
      <div>
        {["thorax", "ECG"].map((k) => (
          <DynamicField
            key={k}
            field={values.radiologicalExamination[k]}
            onUpdate={onUpdate}
            fieldFamily="radiologicalExamination"
          />
        ))}
      </div>*/}
      </Card>
    </Form>
  );
};
AntecedentsExams.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

AntecedentsExams.defaultProps = {
  onUpdate: () => {},
};

export default AntecedentsExams;
