import {
  SET_APP_LOADING,
  SET_CONTENT_LOADING,
  SET_DIALOG_LOADING,
  SET_BUTTON_LOADING,
  SET_CONTENT_DONE
} from "../actions";

const initialState = {
  appIsLoading: false,
  contentIsLoading: false,
  dialogIsLoading: false,
  buttonIsLoading: false,
  isDone: false,
};

const loading = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_LOADING:
      return { ...state, appIsLoading: action.payload };
    case SET_CONTENT_LOADING:
      return { ...state, contentIsLoading: action.payload };
    case SET_DIALOG_LOADING:
      return { ...state, dialogIsLoading: action.payload };
    case SET_BUTTON_LOADING:
      return { ...state, buttonIsLoading: action.payload };
    case SET_CONTENT_DONE:
      return { ...state, isDone: action.payload };
    default:
      return state;
  }
};

export default loading;
