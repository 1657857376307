import {
    SET_PATIENTS_LIST, SELECT_PATIENT, ADD_PATIENT, UPDATE_PATIENT, SELECT_AUDIT,
} from '../actions';

const initialState = {
    patientsList: [],
    selectedPatient: null,
    selectedAudit: null,
};

const patients = (state = initialState, action) => {
    switch (action.type) {
        case SET_PATIENTS_LIST:
            return { ...state, patientsList: action.payload };
        case ADD_PATIENT:
            return { ...state, patientsList: [action.payload, ...state.patientsList] };
        case SELECT_PATIENT:
            return { ...state, selectedPatient: action.payload };
        case SELECT_AUDIT:
            return { ...state, selectedAudit: action.payload };
        case UPDATE_PATIENT:
            const patientsList = [];
            state.patientsList.forEach((patient) => {
                if (patient._id === action.payload._id) {
                    patientsList.push(action.payload);
                } else patientsList.push(patient);
            });
            return { ...state, patientsList };

        default:
            return state;
    }
};

export default patients;
