import React, { useState,useEffect  } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomDicomDisplay from "../../components/Dicom";
import { getAllDocumentsAsync } from "../../redux/documents/actions";
import PropTypes from "prop-types";
import { SettingOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import Title from "antd/lib/typography/Title";
import { loadImage } from "../../api/document";


const DicomViewer = ({ getAllDocumentsAsync, user, documentList }) => {
  const [selectedRecord, setSelectedRecord] = useState();
  const [parsedImage, setParsedImage] = useState();

  useEffect(() => {
    getAllDocumentsAsync("");
  }, [user]);

  useEffect(()=>{
    if(selectedRecord !== undefined){
      setParsedImage({
        original: loadImage(selectedRecord.filePath),
      })
    }
  },[selectedRecord])
  const columns = [
    {
      title: <b>Identifiant</b>,
      key: "patientId.customId",
      render: (_, record) => {
        return <p>{record.patientId.customId}</p>;
      },
    },
    {
      title: <b> Nom de l'image (DICOM)</b>,
      key: "date",
      render: (_, record) => <p>{record.filePath}</p>,
    },
    {
      title: <b> Actions </b>,
      key: "_id",
      render: (_, record) => (
        <div className="flex">
          <Button
            type="primary"
            ghost
            className="m-left-medium"
            onClick={() => {
              setSelectedRecord(record);
            }}
            icon={<SettingOutlined />}
          >
            Consulter
          </Button>
        </div>
      ),
    },
  ];
  console.log("selectedRecord",selectedRecord);
  console.log("parsedImage",parsedImage);
  return (
    <div className="section-content">
      {selectedRecord !== undefined && parsedImage !== undefined ? (
          <CustomDicomDisplay imageUrl={parsedImage.original} />

          // <CustomDicomDisplay imageUrl={"https://raw.githubusercontent.com/muschellij2/Neurohacking_data/master/BRAINIX/DICOM/T1/IM-0001-0001.dcm"} />
      ) : (
        <>
          <Title level={5} style={{ marginBottom: "20px" }}>
            Liste des images Dicom des participants
          </Title>
          <Table
            columns={columns}
            dataSource={documentList}
            locale={{ emptyText: "Aucune image" }}
          />
        </>
      )}
    </div>
  );
};

DicomViewer.propTypes = {
  user: PropTypes.object,
  getAllDocumentsAsync: PropTypes.func.isRequired,
};

DicomViewer.defaultProps = {
  user: null,
  selectedEvent: null,
};

const stateToProps = (state) => ({
  user: state.auth.user,
  documentList: state.documents.docsList,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllDocumentsAsync,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(DicomViewer);
