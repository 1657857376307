import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DynamicField from "../../components/DynamicField";
import { updateVisitAsync } from "../../redux/visits/actions";

const BloodSample = ({
  form,
  values,
  onUpdate,
  selectedVisit,
  updateVisitAsync,
  elemIndex,
  changeProp,
  nameProps,
}) => {
  const [backupForm] = Form.useForm();

  useEffect(() => {
    if (!form) {
      backupForm.setFieldsValue({
        sampleTime: moment(values.sampleTime.value),
        firstTube: values.firstTube.value,
        secondTube: values.secondTube.value,
      });
    }
  }, []);

  const handleUpdate = (fieldFamily, fieldName, prop, value) => {
    let val;
    switch (prop) {
      case "value":
        val = backupForm.getFieldValue(fieldName);
        const field = {
          ...values[fieldName],
          value: val,
          readOnly: true,
        };

        const body = {
          field,
          visitNumber: selectedVisit.visitNumber,
          fieldFamily,
          bloodSampleKey: values.key,
        };

        updateVisitAsync(selectedVisit._id, body);
        break;
      case "cancel":
        if (fieldName === "sampleTime") {
          backupForm.setFieldsValue({
            [fieldName]: moment(values[fieldName].value),
          });
        } else {
          backupForm.setFieldsValue({
            [fieldName]: values[fieldName].value,
          });
        }

        changeProp(fieldName, "readOnly", true, elemIndex);
        break;
      case "readOnly":
        changeProp(fieldName, prop, value, elemIndex);
        break;
      default:
        changeProp(fieldName, prop, value, elemIndex);
        updateVisitAsync(selectedVisit._id, {
          field: {
            ...values[fieldName],
            [prop]: value,
          },
          fieldFamily,
          bloodSampleKey: values.key,
          visitNumber: selectedVisit.visitNumber,
        });
    }
  };

  return (
    <Form style={{ marginTop: 30 }} form={form || backupForm}>
      <div style={{ maxWidth: 600 }}>
        {["sampleTime", nameProps].map((k) => {
          const bloodSampleEntry =
            values.bloodSamples && k === "sampleTime"
              ? values.bloodSamples.filter((bs) =>
                  Object.keys(bs).includes(nameProps)
                )
              : [];

          let neededValue = values[k];
          if (!neededValue) {
            for (const sample of values.bloodSamples) {
              if (Object.keys(sample).includes(k)) neededValue = sample[k];
            }
          }
          console.log("[debug] missing tube name ", {
            bloodSampleEntry,
            values,
            k,
            ["values[k]"]: values[k],
            nameProps,
            neededValue,
          });
          // let index = null;
          // values.bloodSamples.forEach((e, idx) => {
          //   if (e[nameProps]) {
          //     index = idx;
          //   }
          // });
          // console.log(bloodSampleEntry)
          
          return bloodSampleEntry.length > 0 && bloodSampleEntry[0].hasOwnProperty("sampleTime") && bloodSampleEntry[0]["sampleTime"].name === "sampleTime"  ? (
            <DynamicField
              key={k}
              field={
                bloodSampleEntry.length > 0
                  ? bloodSampleEntry[0]["sampleTime"]
                  : neededValue
              }
              onUpdate={form ? onUpdate : handleUpdate}
              fieldFamily="bloodSample"
              nameProps={nameProps}
            />
          ) : (
            <DynamicField
              key={k}
              field={
                bloodSampleEntry.length > 0 ? bloodSampleEntry[k] : neededValue
              }
              onUpdate={form ? onUpdate : handleUpdate}
              fieldFamily="bloodSample"
              nameProps={nameProps}
            />
          );
        })}
      </div>
    </Form>
  );
};

BloodSample.propTypes = {
  form: PropTypes.object,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  selectedVisit: PropTypes.object,
  updateVisitAsync: PropTypes.func.isRequired,
  elemIndex: PropTypes.number,
  changeProp: PropTypes.func,
};

BloodSample.defaultProps = {
  onUpdate: () => {},
  form: null,
  selectedVisit: null,
  elemIndex: 0,
  changeProp: () => {},
};

const stateToProps = (state) => ({
  selectedVisit: state.visits.selectedVisit,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateVisitAsync,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(BloodSample);
